.new-constructions {
    max-height: 100vh !important;
    overflow: hidden !important;
}

.container-filter-enterprise {
    width: 100% !important;
    height: var(--main-f-80-size) !important;
    padding: var(--main-f-17-size) var(--main-f-10-size) !important;
    background-color: #ffffff !important;
    box-shadow: 0px var(--main-f-4-size) var(--main-f-4-size) rgba(0, 0, 0, 0.1) !important;
    position: fixed !important;
    top: 0 !important;
    left: var(--main-f-15-size) !important;
    z-index: 10 !important;

    button {
        width: 100% !important;
        font-size: var(--main-f-17-size) !important;
        padding: var(--main-f-8-size) 0 !important;
    }
}

.app-new-construction {
    min-height: 88vh !important;
    margin-top: var(--main-f-80-size) !important;
    padding-bottom: var(--main-f-55-size) !important;
    background-color: white !important;
    font-family: Raleway, Sans-serif !important;
    flex: 1 !important;
    overflow: overlay !important;

    h1 {
        font-size: var(--main-f-18-size) !important;
        font-weight: 600 !important;
        margin-top: var(--main-f-23-size) !important;
        margin-bottom: var(--main-f-17-size) !important;

        img {
            margin-left: var(--main-f-10-size) !important;
        }
    }

    .container-not-found {
        border-top: var(--main-f-1-size) solid #d4d4d4 !important;
        border-bottom: var(--main-f-1-size) solid #d4d4d4 !important;
        margin: 0 !important;
        padding: var(--main-f-15-size) 0 !important;

        h3 {
            font-size: var(--main-f-15-size) !important;
            font-weight: 600 !important;
            margin-bottom: var(--main-f-6-size) !important;
        }

        p {
            font-size: var(--main-f-15-size) !important;
            margin-bottom: var(--main-f-11-size) !important;
        }

        button {
            font-size: var(--main-f-13-size) !important;
            color: #d0222f !important;
            background-color: transparent !important;
            border: none !important;
            padding: 0 !important;

            img {
                margin-left: var(--main-f-9-size) !important;
            }
        }
    }

    .page-post {
        margin-top: var(--main-f-24-size) !important;

        .none-posts {
            margin-top: var(--main-f-31-size) !important;
            display: flex !important;
            flex-direction: column !important;

            .col {
                justify-content: center !important;
                display: flex !important;

                h4 {
                    width: 100% !important;
                    font-size: var(--main-f-19-size) !important;
                    font-weight: 600 !important;
                    margin-bottom: var(--main-f-20-size) !important;
                    text-align: center !important;
                }

                p {
                    font-size: var(--main-f-15-size) !important;
                    margin-bottom: var(--main-f-63-size) !important;
                    text-align: center !important;
                }
            }
        }
    }

    .container-card-enterprise {
        display: flex !important;
        justify-content: center !important;

        .card-info {
            width: 79% !important;
            height: auto !important;
            border-radius: var(--main-f-9-size) !important;
            overflow: hidden !important;
            margin: 0 !important;
            margin-bottom: var(--main-f-25-size) !important;

            img {
                width: 101% !important;
                height: 101% !important;
                margin: calc(-1 * var(--main-f-1-size));
                object-fit: cover !important;
                filter: brightness(0.55) !important;
            }

            .content {
                width: 100% !important;
                padding: 0 var(--main-f-9-size) !important;
                position: absolute !important;
                display: flex !important;
                flex-direction: column !important;
                justify-content: space-between !important;

                h4 {
                    font-size: var(--main-f-26-size) !important;
                    margin: var(--main-f-50-size) 0 0 0 !important;
                    text-align: initial !important;
                    color: #ffffff !important;
                }

                a {
                    width: 100% !important;
                    margin-bottom: var(--main-f-50-size) !important;
                    display: flex !important;
                    justify-content: center !important;
                    text-decoration: none !important;
                }
            }
        }

        .card-enterprise {
            width: 79% !important;
            height: auto !important;
            border-radius: var(--main-f-9-size) !important;
            overflow: initial !important;

            .container-image {
                width: 100% !important;
                min-height: var(--main-f-190-size) !important;
                background-color: #f6f6f6 !important;
                border-radius: var(--main-f-8-size) var(--main-f-8-size) 0 0 !important;
                padding: 0 !important;
                overflow: hidden !important;
                cursor: pointer !important;

                .img-carousel {
                    max-width: 100% !important;
                    width: 100% !important;
                    height: var(--main-f-190-size) !important;
                    border-radius: 0 !important;
                    margin: 0 !important;
                    object-fit: contain !important;
                    object-position: center;
                    background-color: #f4f4f4 !important;

                    &.img-contains {
                        object-fit: contain !important;
                    }

                    &.img-cover {
                        object-fit: cover !important;
                    }
                }
            }

            .card-body {
                padding: var(--main-f-12-size) var(--main-f-17-size) var(--main-f-20-size) !important;
                display: flex !important;
                flex-direction: column !important;

                .row {
                    margin: 0 !important;
                }

                .container-name-broker {
                    max-width: 90% !important;
                    padding: 0 !important;

                    p {
                        font-size: var(--main-f-11-size) !important;
                        margin: 0 !important;
                    }

                    h5 {
                        max-width: 90% !important;
                        font-size: var(--main-f-15-size) !important;
                        font-weight: 600 !important;
                        overflow: hidden !important;
                        white-space: nowrap !important;
                        text-overflow: ellipsis !important;
                    }
                }

                .container-immobile-location {
                    p {
                        height: var(--main-f-33-size) !important;
                        line-height: var(--main-f-16-size) !important;
                        font-size: var(--main-f-14-size) !important;
                        font-weight: 600 !important;
                        color: #262626 !important;
                        margin: 0 !important;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 2 !important;
                        -webkit-box-orient: vertical !important;
                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                    }
                }

                .row-value {
                    margin-top: var(--main-f-12-size) !important;
                    margin-bottom: 0 !important;
                    display: block !important;

                    span {
                        font-size: var(--main-f-11-size) !important;
                        color: #262626 !important;
                    }

                    .text-value {
                        font-size: var(--main-f-16-size) !important;
                        line-height: var(--main-f-19-size) !important;
                        font-weight: 700 !important;
                        margin: 0 !important;
                    }
                }

                .container-comission {
                    margin-bottom: auto !important;

                    p {
                        font-size: var(--main-f-13-size) !important;
                        font-style: italic !important;
                        margin: 0 !important;
                        color: #262626 !important;
                    }
                }

                .container-icons {
                    margin-top: var(--main-f-10-size) !important;
                    margin-bottom: var(--main-f-19-size) !important;

                    div {
                        display: flex !important;

                        &:nth-child(2) {
                            width: 50% !important;
                        }

                        img {
                            margin-right: var(--main-f-4-size) !important;
                        }

                        p {
                            font-size: var(--main-f-15-size) !important;
                            margin: 0 var(--main-f-27-size) 0 0 !important;
                        }
                    }
                }

                .row-button {
                    button {
                        font-size: var(--main-f-17-size) !important;
                        margin-top: var(--main-f-10-size) !important;
                    }
                }
            }
        }
    }

    .row-loading {
        .col {
            display: flex !important;
            justify-content: center !important;
            margin-top: var(--main-f-30-size) !important;
            margin-bottom: var(--main-f-50-size) !important;

            .spinner-border {
                border-color: #d0222f !important;
                border-right-color: transparent !important;
            }
        }
    }
}
