body {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    width: 100vw;
}

.app,
.app-body,
app-dashboard,
app-root {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.version {
    position: absolute;
    height: auto !important;
    right: 10px;
    opacity: 0.3;
    margin-top: -25px !important;
    z-index: 5000;
}

@font-face {
    font-family: "Raleway-Black";
    src: local("Raleway-Black"), url(../fonts/Raleway-Black.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-BlackItalic";
    src: local("Raleway-BlackItalic"), url(../fonts/Raleway-BlackItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-Bold";
    src: local("Raleway-Bold"), url(../fonts/Raleway-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-BoldItalic";
    src: local("Raleway-BoldItalic"), url(../fonts/Raleway-BoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-ExtraBoldItalic";
    src: local("Raleway-ExtraBoldItalic"), url(../fonts/Raleway-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-ExtraBold";
    src: local("Raleway-ExtraBold"), url(../fonts/Raleway-ExtraBold.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-ExtraLight";
    src: local("Raleway-ExtraLight"), url(../fonts/Raleway-ExtraLight.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-ExtraLightItalic";
    src: local("Raleway-ExtraLightItalic"), url(../fonts/Raleway-ExtraLightItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-Medium";
    src: local("Raleway-Medium"), url(../fonts/Raleway-Medium.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-MediumItalic";
    src: local("Raleway-MediumItalic"), url(../fonts/Raleway-MediumItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway";
    src: local("Raleway"), url(../fonts/Raleway-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-SemiBold";
    src: local("Raleway-SemiBold"), url(../fonts/Raleway-SemiBold.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-SemiBoldItalic";
    src: local("Raleway-SemiBoldItalic"), url(../fonts/Raleway-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-Thin";
    src: local("Raleway-Thin"), url(../fonts/Raleway-Thin.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-ThinItalic";
    src: local("Raleway-ThinItalic"), url(../fonts/Raleway-ThinItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-Italic";
    src: local("Raleway-Italic"), url(../fonts/Raleway-Italic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-LightItalic";
    src: local("Raleway-LightItalic"), url(../fonts/Raleway-LightItalic.ttf) format("truetype");
}

@font-face {
    font-family: "Raleway-Light";
    src: local("Raleway-Light"), url(../fonts/Raleway-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Material-Icons";
    src: local("Material-Icons"), url(../fonts/Material-Icons.ttf) format("truetype");
}

@font-face {
    font-family: "whatever-it-takes";
    src: local("whatever-it-takes"), url(../fonts/whatever-it-takes.ttf) format("truetype");
}

@font-face {
    font-family: "whatever-it-takes-bold";
    src: local("whatever-it-takes-bold"), url(../fonts/whatever-it-takes-bold.ttf) format("truetype");
}

.is-desktop {
    display: none !important;
}

.btn-btn-secondary {
    display: none !important;
}
