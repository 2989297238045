.img-pin-background {
  position: absolute;
  left: 92%;
  top: 30%;
  z-index: 0;
  // bottom: 73.41%;
}

.scroll-top {
  position: fixed;
  bottom: 12px;
  right: var(--main-f-5-size);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: #d0222f;
  color: #fff;
  z-index: 1;
}

.scroll-top .icon {
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease;
}
@keyframes scrollTop {
  from {
    transform: translateY(2px);
  }
  to {
    transform: translateY(-1px);
  }
}

.div-pin {
  position: absolute;
  width: 221.99px;
  height: 0px;
  left: 55.83px;
  top: 766.39px;
  border: 1px solid #262626;
}

.div-size {
  .pin {
    position: relative;
    right: 520px;
    top: -150px;

    .img-pintracado {
      position: absolute;
      // width: 199.96px;
      // height: 283px;
    }

    .vector5-background-seleto {
      position: absolute;
      left: 1275px;
      top: 500px;
    }

    .vector6-background-seleto {
      position: absolute;
      left: 1250px;
      top: 490px;
    }
  }
}

.vector3-background-top {
  position: absolute;
  width: var(--main-f-105-size);
  height: var(--main-f-11-size);
  left: 0.7%;
  top: 300px;
}

.vector4-background-top {
  position: absolute;
  width: var(--main-f-105-size);
  height: var(--main-f-11-size);
  left: 0%;
  top: 291px;
}

.vector5-background-top {
  position: absolute;
  width: var(--main-f-105-size);
  height: var(--main-f-10-size);
  left: 88%;
  top: 368px;
}

.vector6-background-top {
  position: absolute;
  width: var(--main-f-105-size);
  height: var(--main-f-10-size);
  left: 86.5%;
  top: 359px;
}

.body {
  padding-bottom: var(--main-f-2-size) !important;
  font-family: Raleway !important;
  overflow: hidden;

  .card {
    border-radius: var(--main-f-10-size) !important;

    .btn-group {
      button {
        min-width: var(--main-f-110-size) !important;
        height: var(--main-f-45-size) !important;
        color: black !important;
        border-radius: var(--main-f-10-size) !important;
        font-size: var(--main-f-17-size) !important;
        font-family: Raleway-Bold !important;
        background-color: white !important;
        margin: 0px var(--main-f-10-size) !important;
        border: none !important;
      }
    }

    input {
      border-color: #cccccc !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border: none !important;
    }

    .form-group {
      label {
        color: rgb(157, 157, 157) !important;
        font-size: var(--main-f-13-size) !important;
        margin-left: 0 !important;
        font-family: Raleway-Bold !important;
        margin-bottom: 0 !important;
      }
    }

    .select-number {
      border-color: #cccccc !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      font-size: var(--main-f-17-size) !important;
      border: none !important;
      width: var(--main-f-55-size) !important;
      justify-content: space-around !important;
    }

    select {
      border-color: #cccccc !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border: none !important;
      width: var(--main-f-145-size) !important;
      height: var(--main-f-22-size) !important;
      font-size: var(--main-f-17-size) !important;
      padding: 0 !important;
    }

    .btn-card {
      background-color: white !important;
      border-radius: var(--main-f-8-size) !important;
      color: #d0222f !important;
      font-size: var(--main-f-13-size) !important;
      min-width: var(--main-f-155-size) !important;
      height: var(--main-f-30-size) !important;
      font-family: Raleway-Bold !important;
      border-color: #d0222f !important;
      margin-top: 0 !important;
      margin-bottom: var(--main-f-7-size) !important;
    }

    .card-body {
      padding: var(--main-f-22-size) !important;
      height: var(--main-f-310-size) !important;
    }

    .card-subtitle {
      border-bottom: 1px solid !important;
      border-color: rgb(157, 157, 157) !important;
      display: flex !important;
      margin-top: var(--main-f-10-size) !important;
      font-size: var(--main-f-13-size) !important;
    }

    .card-title {
      display: flex !important;
      margin-bottom: var(--main-f-4-size) !important;
      font-family: Raleway-Bold !important;
    }

    .card-text {
      // display: flex !important;
      text-align: initial !important;
      margin-bottom: 0 !important;
      font-size: var(--main-f-10-size) !important;
      margin-top: var(--main-f-4-size) !important;
      display: -webkit-box;
      -webkit-line-clamp: 2; /** número de linhas que você quer exibir */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .form-group {
    button {
      background-color: #d0222f !important;
      border-radius: var(--main-f-10-size) !important;
      color: white !important;
      font-size: var(--main-f-13-size) !important;
      min-width: var(--main-f-180-size) !important;
      height: var(--main-f-34-size) !important;
      border-color: white !important;
      font-family: Raleway-Bold !important;
      text-align: center !important;
    }

    label {
      font-size: var(--main-f-16-size) !important;
      font-family: Raleway-Bold !important;
    }

    input {
      border-radius: var(--main-f-10-size) !important;
      border-color: rgb(202, 202, 202) !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }

    textarea {
      border-radius: var(--main-f-10-size) !important;
      border-color: rgb(202, 202, 202) !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      height: var(--main-f-70-size) !important;
    }

    p {
      font-size: var(--main-f-10-size) !important;
      margin: 0 !important;
    }

    .modal {
      width: 360px !important;
      height: 438px !important;
      padding: var(--main-f-30-size) var(--main-f-20-size) !important;
    }
  }

  .container-banner {
    margin-top: 0 !important;
    margin-bottom: var(--main-f-30-size) !important;
    justify-content: center !important;
    cursor: pointer !important;

    &.banner-seleto {
      margin-top: 0 !important;
    }

    img {
      width: 100% !important;
    }
  }

  h2 {
    font-size: var(--main-f-25-size) !important;
    font-family: Raleway-Bold !important;
    margin-bottom: 0 !important;
  }

  .h2-mini-title {
    font-family: Raleway !important;
    font-size: var(--main-f-17-size) !important;
    margin-bottom: var(--main-f-10-size) !important;
  }

  .h3-title-card {
    margin-bottom: 0 !important;
    margin-top: var(--main-f-43-size) !important;
    font-size: var(--main-f-19-size) !important;
    text-align: center !important;
  }

  .title-seleto-decoration {
    font-size: var(--main-f-19-size) !important;
    text-decoration: underline #d0222f;
    text-decoration-line: 28px !important;
    text-decoration-thickness: 5px;
  }

  .my-properties {
    margin-bottom: var(--main-f-40-size) !important;
    text-align: center !important;
    font-size: var(--main-f-25-size) !important;
  }

  h3 {
    margin-top: var(--main-f-10-size) !important;
    margin-bottom: 0 !important;
    font-family: Raleway !important;
    font-size: var(--main-f-15-size) !important;
  }

  h4 {
    padding-top: var(--main-f-25-size) !important;
    font-family: Raleway-Bold !important;
    font-size: var(--main-f-24-size) !important;
  }

  h5 {
    font-size: var(--main-f-18-size) !important;
  }

  a {
    color: rgb(222, 88, 95) !important;
    text-decoration: underline !important;
  }
}

.card-inputs {
  width: var(--main-f-320-size) !important;
  height: var(--main-f-255-size) !important;

  .row-form-group {
    justify-content: center !important;
  }

  .form-group {
    margin: 0 !important;
    height: var(--main-f-85-size) !important;
    padding: var(--main-f-5-size) !important;
    padding-top: var(--main-f-20-size) !important;
    padding-left: 0 !important;
    min-width: var(--main-f-155-size) !important;

    .input-value {
      border-radius: var(--main-f-10-size) !important;
      border-color: rgb(202, 202, 202) !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      width: var(--main-f-145-size) !important;
      margin-right: var(--main-f-5-size) !important;
      height: var(--main-f-22-size) !important;
      padding-left: 0 !important;
      font-size: var(--main-f-17-size) !important;
    }

    input {
      border-radius: var(--main-f-10-size) !important;
      border-color: rgb(202, 202, 202) !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      width: var(--main-f-288-size) !important;
      margin-right: var(--main-f-5-size) !important;
      font-size: var(--main-f-17-size) !important;
      height: var(--main-f-30-size) !important;
      padding-left: var(--main-f-22-size) !important;
    }

    .autocomplete {
      font-size: var(--main-f-16-size) !important;
      border: 1px solid #cccccc !important;
      border-top: 0 !important;
      width: var(--main-f-320-size) !important;
      justify-content: left !important;
      padding-top: var(--main-f-10-size) !important;
      padding-bottom: var(--main-f-10-size) !important;
      padding-left: var(--main-f-4-size) !important;
    }

    .icons-pl {
      padding-left: var(--main-f-9-size) !important;
    }

    ::-webkit-input-placeholder {
      color: #bbbbbb !important;
      padding-left: var(--main-f-7-size) !important;
    }

    .border-right {
      border-right: 1px solid rgb(202, 202, 202) !important;
    }

    .border-bottom {
      border-bottom: 1px solid rgb(202, 202, 202) !important;
    }

    i {
      color: rgb(157, 157, 157) !important;
    }
  }
}

.btn-salmon {
  background-color: #d0222f !important;
  border-radius: var(--main-f-10-size) !important;
  color: white !important;
  font-size: var(--main-f-17-size) !important;
  height: var(--main-f-50-size) !important;
  border-color: white !important;
  font-family: Raleway-Bold !important;
  margin-bottom: var(--main-f-25-size) !important;
}

.align-center {
  font-family: Raleway !important;
  text-align: center !important;
  h3 {
    margin-bottom: var(--main-f-35-size) !important;
    font-size: 24px !important;
  }
}

.margin-top {
  margin-top: var(--main-f-10-size) !important;
}

.margin-tp-bt {
  margin-top: var(--main-f-20-size) !important;
  margin-bottom: var(--main-f-20-size) !important;
}

.padding-bt {
  padding-bottom: var(--main-f-100-size) !important;
}

.switch {
  width: var(--main-f-265-size) !important;
  padding: var(--main-f-4-size) !important;
  height: var(--main-f-57-size) !important;
  align-items: center !important;
  text-align: center !important;
  margin: 0 !important;

  p {
    font-size: var(--main-f-24-size) !important;
    color: rgb(202, 202, 202) !important;
  }

  button.btn.btn-outline-secondary.m-right {
    margin: 0px var(--main-f-5-size) 0px 0px !important;
    box-shadow: none !important;
    color: #535353 !important;
    font-size: var(--main-f-15-size) !important;
  }

  button.btn.btn-outline-secondary.m-left {
    margin: 0px 0px 0px var(--main-f-5-size) !important;
    box-shadow: none !important;
    color: #535353 !important;
    font-size: var(--main-f-15-size) !important;
  }

  button.is-rent.btn.btn-outline-secondary {
    background-color: rgba(222, 88, 95, 0.2) !important;
    box-shadow: none !important;
    color: #d0222f !important;
    font-size: var(--main-f-15-size) !important;
  }
}

.TagSeleto {
  width: 70px !important;
  left: var(--main-f-30-size) !important;
  top: var(--main-f-19-size) !important;
  position: absolute !important;
  z-index: 20 !important;
}

.arrow-float-left {
  width: var(--main-f-28-size) !important;
  height: var(--main-f-28-size) !important;
  border-radius: var(--main-f-50-size) !important;
  padding: 0px !important;
  color: white !important;
  background-color: #d0222f !important;
  border-color: white !important;
  position: absolute !important;
  left: var(--main-f-70-size) !important;
  bottom: var(--main-f-100-size) !important;
  transition: all 0.25s ease-in 0s !important;
  z-index: 2 !important;
}

.arrow-float-right {
  width: var(--main-f-28-size) !important;
  height: var(--main-f-28-size) !important;
  border-radius: var(--main-f-50-size) !important;
  padding: 0px !important;
  color: white !important;
  background-color: #d0222f !important;
  border-color: white !important;
  position: absolute !important;
  right: var(--main-f-70-size) !important;
  bottom: var(--main-f-100-size) !important;
  transition: all 0.25s ease-in 0s !important;
  z-index: 2 !important;
}

.inline {
  display: flex !important;
  flex-direction: row !important;
  margin-top: var(--main-f-180-size) !important;
}

.inline2 {
  display: flex !important;
  flex-direction: row !important;
}

.carousel-root {
  width: 100vw !important;
  margin-bottom: 12px;
  .carousel-container {
    -webkit-box-pack: center !important;
    -webkit-box-direction: normal !important;
    -webkit-box-orient: horizontal !important;
    // background-color: rgb(255, 255, 255);
    display: flex !important;
    flex-direction: row !important;
    height: var(--main-f-462-size) !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 0px !important;

    &.row {
      height: auto !important;

      .container-card-enterprise {
        display: flex !important;
        justify-content: center !important;

        .card-enterprise {
          width: 79% !important;
          height: auto !important;
          border-radius: var(--main-f-9-size) !important;
          overflow: initial !important;

          .container-image {
            width: 100% !important;
            min-height: var(--main-f-190-size) !important;
            background-color: #f6f6f6 !important;
            border-radius: var(--main-f-8-size) var(--main-f-8-size) 0 0 !important;
            padding: 0 !important;
            overflow: hidden !important;
            cursor: pointer !important;

            .img-carousel {
              max-width: 100% !important;
              width: 100% !important;
              height: var(--main-f-190-size) !important;
              border-radius: 0 !important;
              margin: 0 !important;
              object-fit: contain !important;
              object-position: center;
              background-color: #f4f4f4 !important;

              &.img-contains {
                object-fit: contain !important;
              }

              &.img-cover {
                object-fit: cover !important;
              }
            }
          }

          .card-body {
            height: auto !important;
            padding: var(--main-f-12-size) var(--main-f-17-size) var(--main-f-20-size) !important;
            display: flex !important;
            flex-direction: column !important;
            text-align: initial !important;

            .row {
              margin: 0 !important;
            }

            .container-name-broker {
              max-width: 90% !important;
              padding: 0 !important;

              p {
                font-size: var(--main-f-11-size) !important;
                margin: 0 !important;
              }

              h5 {
                max-width: 90% !important;
                font-size: var(--main-f-15-size) !important;
                font-weight: 600 !important;
                overflow: hidden !important;
                white-space: nowrap !important;
                text-overflow: ellipsis !important;
              }
            }

            .container-immobile-location {
              p {
                height: var(--main-f-33-size) !important;
                line-height: var(--main-f-16-size) !important;
                font-size: var(--main-f-14-size) !important;
                font-weight: 600 !important;
                color: #262626 !important;
                margin: 0 !important;
                display: -webkit-box !important;
                -webkit-line-clamp: 2 !important;
                -webkit-box-orient: vertical !important;
                overflow: hidden !important;
                text-overflow: ellipsis !important;
              }
            }

            .row-value {
              margin-top: var(--main-f-12-size) !important;
              margin-bottom: 0 !important;
              display: block !important;

              span {
                font-size: var(--main-f-11-size) !important;
                color: #262626 !important;
              }

              .text-value {
                font-size: var(--main-f-16-size) !important;
                line-height: var(--main-f-19-size) !important;
                font-weight: 700 !important;
                margin: 0 !important;
              }
            }

            .container-comission {
              margin-bottom: auto !important;

              p {
                font-size: var(--main-f-13-size) !important;
                font-style: italic !important;
                margin: 0 !important;
                color: #262626 !important;
              }
            }

            .container-icons {
              margin-top: var(--main-f-10-size) !important;
              margin-bottom: var(--main-f-19-size) !important;

              &:nth-child(2) {
                width: 50% !important;
              }

              div {
                display: flex !important;

                img {
                  max-width: var(--main-f-12-size) !important;
                  margin-right: var(--main-f-4-size) !important;
                }

                p {
                  font-size: var(--main-f-15-size) !important;
                  margin: 0 var(--main-f-27-size) 0 0 !important;
                }
              }
            }

            .row-button {
              button {
                font-size: var(--main-f-17-size) !important;
                margin-top: var(--main-f-10-size) !important;
              }
            }
          }
        }
      }
    }

    .card {
      width: var(--main-f-310-size) !important;
      height: var(--main-f-462-size) !important;
      margin: 0px var(--main-f-7-size) !important;
      cursor: pointer;

      .card-img-top {
        right: 0 !important;
        position: absolute !important;
        bottom: var(--main-f-265-size) !important;
        border-radius: var(--main-f-7-size) var(--main-f-7-size) 0 0 !important;
        min-height: var(--main-f-195-size) !important;
        max-height: var(--main-f-195-size) !important;
        object-fit: contain !important;
        background-color: #f4f4f4 !important;

        &.img-contains {
          object-fit: contain !important;
        }

        &.img-cover {
          object-fit: cover !important;
        }
      }

      .btn-card {
        background-color: white !important;
        border-radius: var(--main-f-8-size) !important;
        color: #d0222f !important;
        font-size: var(--main-f-18-size) !important;
        width: var(--main-f-271-size) !important;
        height: var(--main-f-40-size) !important;
        font-family: Raleway-Bold !important;
        border-color: #d0222f !important;
        margin-top: 0 !important;
        margin-bottom: var(--main-f-7-size) !important;
      }

      .btn-white-card {
        background-color: white !important;
        border-radius: var(--main-f-8-size) !important;
        color: #d0222f !important;
        font-size: var(--main-f-18-size) !important;
        width: var(--main-f-271-size) !important;
        height: var(--main-f-40-size) !important;
        font-family: Raleway-Bold !important;
        border-color: #d0222f !important;
        margin-top: 0 !important;
        margin-bottom: var(--main-f-7-size) !important;
      }

      .card-body {
        padding: var(--main-f-22-size) !important;
        height: var(--main-f-310-size) !important;
      }

      .card-subtitle {
        border-bottom: 1px solid !important;
        border-color: rgb(157, 157, 157) !important;
        display: flex !important;
        margin-top: var(--main-f-10-size) !important;
        font-size: var(--main-f-13-size) !important;
      }

      .card-title {
        display: flex !important;
        margin-bottom: var(--main-f-4-size) !important;
        font-family: Raleway-Bold !important;
      }

      .card-text {
        // display: flex !important;
        text-align: initial !important;
        margin-bottom: 0 !important;
        font-size: var(--main-f-15-size) !important;
        margin-top: var(--main-f-4-size) !important;
        font-family: Raleway !important;
        display: -webkit-box;
        -webkit-line-clamp: 2; /** número de linhas que você quer exibir */
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icons {
        width: auto !important;
        margin-right: var(--main-f-4-size) !important;
        margin-top: var(--main-f-4-size) !important;
      }

      .margin-right {
        margin-right: var(--main-f-10-size) !important;
      }
    }
  }

  .arrow-next {
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: var(--main-f-2-size) !important;
    top: var(--main-f-95-size) !important;
    transition: all 0.25s ease-in 0s;
    width: 100px;
    z-index: 1;
    img {
      border-radius: 11px;
      z-index: 2;
      width: 100px !important;
      height: 100px !important;
      cursor: pointer;
    }
  }

  .arrow-prev {
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    left: var(--main-f-2-size) !important;
    top: var(--main-f-95-size) !important;
    transition: all 0.25s ease-in 0s;
    width: 100px;
    z-index: 1;
    img {
      border-radius: 11px;
      z-index: 2;
      width: 100px !important;
      height: 100px !important;
      cursor: pointer;
    }
  }

  .arrow-next-steps {
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: all 0.25s ease-in 0s;
    width: 70px;
    z-index: 1;
    img {
      border-radius: 11px;
      z-index: 2;
      width: 70px !important;
      height: 70px !important;
      cursor: pointer;
    }
  }

  .arrow-prev-steps {
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    left: 10px;
    top: 10px;
    transition: all 0.25s ease-in 0s;
    width: 70px;
    z-index: 1;
    img {
      border-radius: 11px;
      z-index: 2;
      width: 70px !important;
      height: 70px !important;
      cursor: pointer;
    }
  }

  .padding10 {
    padding: var(--main-f-10-size) !important;
  }
}

.grey-div {
  background-color: #f6f6f6 !important;
}

.div-size {
  width: 100% !important;
  min-height: 5vh !important;
  background-color: #fff;
  padding: var(--main-f-15-size) !important;
}

.backgroundcolor-white {
  background-color: #ffffff !important;
}

.carousel-root-steps {
  width: auto !important;
}
