.btn_web_primary {
    font-size: var(--main-f-12-size) !important;
    font-weight: 600 !important;
    padding: var(--main-f-6-size) var(--main-f-18-size) !important;
    background-color: #d0222f !important;
    border-color: #d0222f !important;
    border-radius: var(--main-f-8-size) !important;
    color: #ffffff !important;

    &:hover {
        background-color: #d7434e !important;
        border-color: #d7434e !important;
    }

    &:active {
        background-color: #b11d28 !important;
        border-color: #b11d28 !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.btn_web_secondary {
    font-size: var(--main-f-12-size) !important;
    font-weight: 600 !important;
    padding: var(--main-f-6-size) var(--main-f-18-size) !important;
    background-color: #ffffff !important;
    border: var(--main-f-1-size) solid !important;
    border-color: #d0222f !important;
    border-radius: var(--main-f-8-size) !important;
    color: #d0222f !important;

    &:hover {
        background-color: #fdf4f5 !important;
        border-color: #d0222f !important;
    }

    &:active {
        background-color: #f6d3d5 !important;
        border-color: #d0222f !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.btn_web_tertiary {
    font-size: var(--main-f-12-size) !important;
    font-weight: 600 !important;
    padding: var(--main-f-6-size) var(--main-f-18-size) !important;
    background-color: transparent !important;
    border-color: transparent !important;
    border-radius: var(--main-f-8-size) !important;
    color: #d0222f !important;

    &:hover {
        background-color: #fae9ea !important;
        border-color: transparent !important;
    }

    &:active {
        background-color: #f1bdc1 !important;
        border-color: transparent !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.btn_web_quatenary {
    font-size: var(--main-f-12-size) !important;
    font-weight: 600 !important;
    padding: var(--main-f-6-size) var(--main-f-18-size) !important;
    background-color: #fceeef !important;
    border-color: #de585f !important;
    border-radius: var(--main-f-8-size) !important;
    color: #de585f !important;

    &:hover {
        background-color: #f0c6c9 !important;
        border-color: transparent !important;
    }

    &:active {
        background-color: #f0c6c9 !important;
        border-color: transparent !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.btn_web_simple {
    font-size: var(--main-f-12-size) !important;
    font-weight: 600 !important;
    padding: var(--main-f-6-size) var(--main-f-18-size) !important;
    background-color: #ffffff !important;
    border-color: #535353 !important;
    border-radius: var(--main-f-8-size) !important;
    color: #000000 !important;

    &:hover {
        background-color: #797979 !important;
        border-color: transparent !important;
    }

    &:active {
        background-color: #797979 !important;
        border-color: transparent !important;
    }

    &:focus {
        box-shadow: none !important;
    }
}
