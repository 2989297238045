   body {
    //  overflow: hidden !important;
   }
   
    .filter-seleto{
        min-height: 100% !important;
    }

   .app-gallery {
        margin-top: var(--main-f-120-size) !important;
        overflow: overlay !important;

       .filter {
        border-bottom: 1px solid #cacaca !important;
        position: fixed;
        z-index: 2;
        background-color: white;
        width: 100%;
        top: 48px !important;
        
        .container {
            margin-top: 30px !important;
        }

       }

       .mobile {
        margin: 0 !important;
        height: var(--main-f-70-size) !important;     
        width: var(--main-f-180-size) !important;       
        padding-right: 10px !important;      
        position: relative;
        display: flex;
        padding-left: 0px !important;
        flex-direction: column;     
       }

       .form-group-seleto {
        margin: 0 !important;
        height: var(--main-f-60-size) !important;     
        width: var(--main-f-150-size) !important;       
        padding-right: 10px !important;      
        position: relative;
        display: flex;
        padding-left: 0px !important;
        flex-direction: column;           

        .form-group-bedrooms{
            width: var(--main-f-75-size) !important;
        }

        label{
            color: #9d9d9d !important;
            font-size: var(--main-f-9-size) !important;
            margin-left: 0 !important;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
            position: absolute;
            transform: translate(0, 26px) scale(1);
            padding: 0 12px !important;
            padding-left: 20px !important;
            top: -24px;
        }

        select {
            border: 1.00342px solid #8C8C8C;
            box-sizing: border-box;
            border-radius: 10.0342px;
            outline: 0 !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            height: var(--main-f-25-size) !important;
            font-size: var(--main-f-13-size) !important;
            margin-left: 0 !important;
            height: 50px !important;
            padding: 14px 16px 0 15px;
            cursor: pointer;
           
        }

        .input-value{
            border-radius: 0 !important;
            border-color: #cacaca !important;
            outline: 0 !important;
            box-shadow: none !important;
            width: var(--main-f-150-size) !important;
            height: var(--main-f-25-size) !important;
            margin-right: var(--main-f-5-size) !important;
            font-size: var(--main-f-13-size) !important;
            padding-left: var(--main-f-17-size) !important;
            margin-left: 0 !important;
        }
    }

    .mobile {
        height: var(--main-f-70-size) !important;     
        width: var(--main-f-170-size) !important;
        font-size: var(--main-f-16-size) !important;
        select {
            font-size: var(--main-f-14-size) !important;
            font-family: Raleway !important;
        }

        label{
            font-size: var(--main-f-11-size) !important;
        }
        
       }

       .filter-icon{
        margin-left: var(--main-f-20-size) !important;
        margin-right: var(--main-f-10-size) !important;
        margin-top: var(--main-f-5-size) !important;
    }

    .vacancies{
        min-width: var(--main-f-75-size) !important;
    }

    .form-group.form-group-bedrooms{      
            min-width: var(--main-f-75-size) !important;
    }   
    
    .form-group.form-group-vacancies{      
        min-width: var(--main-f-75-size) !important;
    }     
       
    .results {
        width: 100vw !important;
        max-width: 100vw !important;
        overflow-y: overlay  !important;
        padding: var(--main-f-9-size) !important;
        padding-top: 44px !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
        margin-top: var(--main-f-10-size) !important;
        background-color: #ffffff !important;
        // max-height: var(--main-f-358-size) !important;
        // min-height: var(--main-f-358-size) !important;
       //  max-height: 90vh !important;
        font-family: Raleway !important;
  
        .post-container{
            min-height: 750px !important;
        }
  
        .card-title-qtd {
            margin-bottom: var(--main-f-20-size) !important;
            font-family: Raleway-Bold !important;
            font-size: 16px;
            line-height: 19px;
            justify-content: space-between !important;
            margin-left: var(--main-f-10-size) !important;
            position: relative;
            align-items: center
        }

        .label-order {
            font-family: Raleway !important;
        }
  
        .banner {
            width: 100% !important;
            margin-bottom: var(--main-f-20-size) !important;
        }
  
        .img-notFound{
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: var(--main-f-120-size);
          margin-bottom: auto;
      }
     // padding-top: var(--main-f-7-size) !important;
    // padding-bottom: var(--main-f-21-size) !important;
    margin-left: 0 !important;
    // height: var(--main-f-45-size) !important;

       

      

      

        
    }
}