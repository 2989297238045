:root {
    --calc-font-320: 0.9;
    --calc-font-360: 0.95;
    --calc-font-411: 1;
    --calc-font-481: 1.05;
    --calc-font-641: 1.1;
    --calc-font-961: 1.3;

    --h1-size: 28px;
    --h2-size: 24px;
    --h3-size: 18px;
    --f-1-size: 1px;
    --f-2-size: 2px;
    --f-4-size: 4px;
    --f-5-size: 5px;
    --f-6-size: 6px;
    --f-7-size: 7px;
    --f-8-size: 8px;
    --f-9-size: 9px;
    --f-10-size: 10px;
    --f-11-size: 11px;
    --f-12-size: 12px;
    --f-13-size: 13px;
    --f-14-size: 14px;
    --f-15-size: 15px;
    --f-16-size: 16px;
    --f-17-size: 17px;
    --f-18-size: 18px;
    --f-19-size: 19px;
    --f-20-size: 20px;
    --f-21-size: 21px;
    --f-22-size: 22px;
    --f-23-size: 23px;
    --f-24-size: 24px;
    --f-25-size: 25px;
    --f-26-size: 26px;
    --f-27-size: 27px;
    --f-28-size: 28px;
    --f-29-size: 29px;
    --f-30-size: 30px;
    --f-34-size: 34px;
    --f-35-size: 35px;
    --f-37-size: 37px;
    --f-38-size: 38px;
    --f-40-size: 40px;
    --f-43-size: 43px;
    --f-45-size: 45px;
    --f-46-size: 46px;
    --f-48-size: 48px;
    --f-50-size: 50px;
    --f-53-size: 53px;
    --f-55-size: 55px;
    --f-57-size: 57px;
    --f-60-size: 60px;
    --f-62-size: 62px;
    --f-65-size: 65px;
    --f-67-size: 67px;
    --f-70-size: 70px;
    --f-72-size: 72px;
    --f-75-size: 75px;
    --f-80-size: 80px;
    --f-85-size: 85px;
    --f-90-size: 90px;
    --f-95-size: 95px;
    --f-100-size: 100px;
    --f-106-size: 106px;
    --f-110-size: 110px;
    --f-111-size: 111px;
    --f-113-size: 113px;
    --f-115-size: 115px;
    --f-120-size: 120px;
    --f-125-size: 125px;
    --f-128-size: 128px;
    --f-130-size: 130px;
    --f-138-size: 138px;
    --f-140-size: 140px;
    --f-145-size: 145px;
    --f-150-size: 150px;
    --f-155-size: 155px;
    --f-156-size: 156px;
    --f-157-size: 157px;
    --f-158-size: 158px;
    --f-160-size: 160px;
    --f-161-size: 161px;
    --f-162-size: 162px;
    --f-165-size: 165px;
    --f-170-size: 170px;
    --f-180-size: 180px;
    --f-182-size: 182px;
    --f-184-size: 184px;
    --f-185-size: 185px;
    --f-186-size: 186px;
    --f-190-size: 190px;
    --f-195-size: 195px;
    --f-196-size: 196px;
    --f-200-size: 200px;
    --f-207-size: 207px;
    --f-215-size: 215px;
    --f-220-size: 220px;
    --f-230-size: 230px;
    --f-240-size: 240px;
    --f-255-size: 255px;
    --f-260-size: 260px;
    --f-265-size: 265px;
    --f-271-size: 271px;
    --f-277-size: 277px;
    --f-279-size: 279px;
    --f-280-size: 280px;
    --f-285-size: 285px;
    --f-288-size: 288px;
    --f-298-size: 298px;
    --f-310-size: 310px;
    --f-320-size: 320px;
    --f-325-size: 325px;
    --f-335-size: 335px;
    --f-340-size: 340px;
    --f-358-size: 358px;
    --f-370-size: 370px;
    --f-380-size: 380px;
    --f-385-size: 385px;
    --f-390-size: 390px;
    --f-392-size: 392px;
    --f-400-size: 400px;
    --f-426-size: 426px;
    --f-430-size: 430px;
    --f-445-size: 445px;
    --f-450-size: 450px;
    --f-462-size: 462px;
    --f-500-size: 500px;
    --f-520-size: 520px;
    --f-600-size: 600px;
    --f-605-size: 605px;
    --f-606-size: 606px;
    --f-620-size: 620px;
    --f-630-size: 630px;
    --f-640-size: 640px;
    --f-645-size: 645px;
    --f-655-size: 655px;
    --f-861-size: 861px;

    --main-h1-size: var(--h1-size);
    --main-h2-size: var(--h2-size);
    --main-h3-size: var(--h3-size);
    --main-f-1-size: var(--f-1-size);
    --main-f-2-size: var(--f-2-size);
    --main-f-4-size: var(--f-4-size);
    --main-f-5-size: var(--f-5-size);
    --main-f-6-size: var(--f-6-size);
    --main-f-7-size: var(--f-7-size);
    --main-f-8-size: var(--f-8-size);
    --main-f-9-size: var(--f-9-size);
    --main-f-10-size: var(--f-10-size);
    --main-f-11-size: var(--f-11-size);
    --main-f-12-size: var(--f-12-size);
    --main-f-13-size: var(--f-13-size);
    --main-f-14-size: var(--f-14-size);
    --main-f-15-size: var(--f-15-size);
    --main-f-16-size: var(--f-16-size);
    --main-f-17-size: var(--f-17-size);
    --main-f-18-size: var(--f-18-size);
    --main-f-19-size: var(--f-19-size);
    --main-f-20-size: var(--f-20-size);
    --main-f-21-size: var(--f-21-size);
    --main-f-22-size: var(--f-22-size);
    --main-f-23-size: var(--f-23-size);
    --main-f-24-size: var(--f-24-size);
    --main-f-25-size: var(--f-25-size);
    --main-f-26-size: var(--f-26-size);
    --main-f-27-size: var(--f-27-size);
    --main-f-28-size: var(--f-28-size);
    --main-f-29-size: var(--f-29-size);
    --main-f-30-size: var(--f-30-size);
    --main-f-34-size: var(--f-34-size);
    --main-f-35-size: var(--f-35-size);
    --main-f-37-size: var(--f-37-size);
    --main-f-38-size: var(--f-38-size);
    --main-f-40-size: var(--f-40-size);
    --main-f-43-size: var(--f-43-size);
    --main-f-45-size: var(--f-45-size);
    --main-f-46-size: var(--f-46-size);
    --main-f-48-size: var(--f-48-size);
    --main-f-50-size: var(--f-50-size);
    --main-f-53-size: var(--f-53-size);
    --main-f-55-size: var(--f-55-size);
    --main-f-57-size: var(--f-57-size);
    --main-f-60-size: var(--f-60-size);
    --main-f-62-size: var(--f-62-size);
    --main-f-65-size: var(--f-65-size);
    --main-f-67-size: var(--f-67-size);
    --main-f-70-size: var(--f-70-size);
    --main-f-72-size: var(--f-72-size);
    --main-f-75-size: var(--f-75-size);
    --main-f-80-size: var(--f-80-size);
    --main-f-85-size: var(--f-85-size);
    --main-f-90-size: var(--f-90-size);
    --main-f-95-size: var(--f-95-size);
    --main-f-100-size: var(--f-100-size);
    --main-f-106-size: var(--f-106-size);
    --main-f-110-size: var(--f-110-size);
    --main-f-111-size: var(--f-111-size);
    --main-f-113-size: var(--f-113-size);
    --main-f-115-size: var(--f-115-size);
    --main-f-120-size: var(--f-120-size);
    --main-f-125-size: var(--f-125-size);
    --main-f-128-size: var(--f-128-size);
    --main-f-130-size: var(--f-130-size);
    --main-f-138-size: var(--f-138-size);
    --main-f-140-size: var(--f-140-size);
    --main-f-145-size: var(--f-145-size);
    --main-f-150-size: var(--f-150-size);
    --main-f-155-size: var(--f-155-size);
    --main-f-156-size: var(--f-156-size);
    --main-f-157-size: var(--f-157-size);
    --main-f-158-size: var(--f-158-size);
    --main-f-160-size: var(--f-160-size);
    --main-f-161-size: var(--f-161-size);
    --main-f-162-size: var(--f-162-size);
    --main-f-165-size: var(--f-165-size);
    --main-f-170-size: var(--f-170-size);
    --main-f-180-size: var(--f-180-size);
    --main-f-182-size: var(--f-182-size);
    --main-f-184-size: var(--f-184-size);
    --main-f-185-size: var(--f-185-size);
    --main-f-186-size: var(--f-186-size);
    --main-f-190-size: var(--f-190-size);
    --main-f-195-size: var(--f-195-size);
    --main-f-196-size: var(--f-196-size);
    --main-f-200-size: var(--f-200-size);
    --main-f-207-size: var(--f-207-size);
    --main-f-215-size: var(--f-215-size);
    --main-f-220-size: var(--f-220-size);
    --main-f-240-size: var(--f-240-size);
    --main-f-255-size: var(--f-255-size);
    --main-f-260-size: var(--f-260-size);
    --main-f-265-size: var(--f-265-size);
    --main-f-271-size: var(--f-271-size);
    --main-f-277-size: var(--f-277-size);
    --main-f-279-size: var(--f-279-size);
    --main-f-280-size: var(--f-280-size);
    --main-f-285-size: var(--f-285-size);
    --main-f-288-size: var(--f-288-size);
    --main-f-298-size: var(--f-298-size);
    --main-f-310-size: var(--f-310-size);
    --main-f-320-size: var(--f-320-size);
    --main-f-325-size: var(--f-325-size);
    --main-f-335-size: var(--f-335-size);
    --main-f-340-size: var(--f-340-size);
    --main-f-358-size: var(--f-358-size);
    --main-f-370-size: var(--f-370-size);
    --main-f-380-size: var(--f-380-size);
    --main-f-385-size: var(--f-385-size);
    --main-f-390-size: var(--f-390-size);
    --main-f-392-size: var(--f-392-size);
    --main-f-400-size: var(--f-400-size);
    --main-f-426-size: var(--f-426-size);
    --main-f-430-size: var(--f-430-size);
    --main-f-445-size: var(--f-445-size);
    --main-f-462-size: var(--f-462-size);
    --main-f-450-size: var(--f-450-size);
    --main-f-500-size: var(--f-500-size);
    --main-f-520-size: var(--f-520-size);
    --main-f-600-size: var(--f-600-size);
    --main-f-605-size: var(--f-605-size);
    --main-f-606-size: var(--f-606-size);
    --main-f-620-size: var(--f-620-size);
    --main-f-630-size: var(--f-630-size);
    --main-f-640-size: var(--f-640-size);
    --main-f-645-size: var(--f-645-size);
    --main-f-655-size: var(--f-655-size);
    --main-f-861-size: var(--f-861-size);
}
