.app:has(.app-enterprise-details) {
    .header-mobile {
        height: var(--main-f-72-size) !important;

        .navbar-brand {
            position: relative !important;
        }
    }
}

.app-enterprise-details {
    min-height: 93.8vh !important;
    margin-top: var(--main-f-72-size) !important;
    padding-bottom: var(--main-f-100-size) !important;
    background-color: white !important;
    font-family: Raleway, Sans-serif !important;

    button {
        font-size: var(--main-f-17-size) !important;
        border-radius: var(--main-f-12-size) !important;
    }

    .container {
        max-width: inherit !important;
        padding: 0 var(--main-f-8-size) !important;

        .row {
            margin: 0 !important;
        }

        .row-image {
            max-width: 107% !important;
            margin: 0px calc(-1 * var(--main-f-16-size)) !important;
        }

        .container-image {
            width: 100% !important;
            height: var(--main-f-240-size) !important;

            .carousel-root {
                max-width: 100% !important;
                width: 100% !important;
                height: var(--main-f-240-size) !important;

                .carousel-container {
                    width: 100% !important;
                    height: var(--main-f-240-size) !important;
                    -webkit-box-pack: center !important;
                    display: flex !important;
                    flex-direction: row !important;
                    align-items: center !important;
                }

                .slide {
                    text-align: unset !important;

                    .container-image {
                        padding: 0 !important;
                        cursor: pointer !important;

                        .img-carousel {
                            height: var(--main-f-240-size) !important;

                            &.img-cover {
                                object-fit: cover !important;
                            }

                            &.img-contain {
                                object-fit: contain !important;
                            }
                        }
                    }
                }

                .arrow-next-post-detail {
                    position: absolute !important;
                    top: var(--main-f-115-size) !important;
                    right: var(--main-f-22-size) !important;
                    cursor: pointer !important;
                }

                .arrow-prev-post-detail {
                    position: absolute !important;
                    top: var(--main-f-115-size) !important;
                    left: var(--main-f-22-size) !important;
                    cursor: pointer !important;
                    z-index: 1 !important;
                }
            }
        }

        .row-button-postdetail {
            margin-top: var(--main-f-28-size) !important;
            margin-bottom: var(--main-f-40-size) !important;

            button {
                display: flex !important;
                align-items: center !important;
                padding: var(--main-f-9-size) var(--main-f-19-size) var(--main-f-9-size) var(--main-f-12-size) !important;

                img {
                    margin-right: var(--main-f-11-size) !important;
                }
            }
        }

        .enterprise {
            font-size: var(--main-f-18-size) !important;
            font-weight: 600 !important;
            margin-bottom: var(--main-f-6-size) !important;
        }

        .location {
            font-size: var(--main-f-15-size) !important;
            font-weight: 600 !important;
            margin-bottom: var(--main-f-20-size) !important;
        }

        .unity-price {
            font-size: var(--main-f-15-size) !important;
            margin-bottom: var(--main-f-9-size) !important;
        }

        .updated-at {
            font-size: var(--main-f-11-size) !important;
            margin-bottom: var(--main-f-7-size) !important;
        }

        .price {
            font-size: var(--main-f-18-size) !important;
            font-weight: 600 !important;
            margin-bottom: var(--main-f-26-size) !important;
        }

        h3 {
            font-size: var(--main-f-18-size) !important;
            font-weight: 600 !important;
            margin: 0 0 var(--main-f-22-size) !important;
        }

        .container-info {
            display: flex !important;
            margin-right: auto !important;
            margin-bottom: var(--main-f-30-size) !important;
            padding: 0 !important;

            img {
                max-width: var(--main-f-22-size) !important;
                max-height: var(--main-f-22-size) !important;
                margin-right: var(--main-f-18-size) !important;
            }

            p {
                font-size: var(--main-f-15-size) !important;
                margin: 0 !important;
                color: #515151 !important;
            }
        }

        .container-info-immobile {
            padding: 0 !important;
            display: flex !important;
            margin-bottom: var(--main-f-30-size) !important;

            img {
                max-width: var(--main-f-22-size) !important;
                max-height: var(--main-f-22-size) !important;
                margin-right: var(--main-f-18-size) !important;
            }

            p {
                font-size: var(--main-f-15-size) !important;
                margin: 0 !important;
                color: #515151 !important;
            }
        }

        .row-characteristcs {
            margin-bottom: var(--main-f-30-size) !important;
        }

        .location-map {
            height: var(--main-f-195-size) !important;
            margin-bottom: var(--main-f-25-size) !important;
            border-radius: var(--main-f-11-size) !important;
            overflow: hidden !important;
        }

        .row-description {
            p {
                font-size: var(--main-f-15-size) !important;
                color: #262626 !important;
                margin-top: var(--main-f-45-size) !important;
            }
        }

        .row-information {
            margin-bottom: var(--main-f-50-size) !important;
            padding: var(--main-f-25-size) !important;
            background-color: #f4f4f4 !important;
            border-radius: var(--main-f-11-size) !important;

            p {
                font-size: var(--main-f-13-size) !important;
                margin-bottom: 0 !important;
            }
        }

        .floating-button {
            width: 100% !important;
            position: fixed !important;
            bottom: 0 !important;
            left: 0 !important;
            padding: var(--main-f-22-size) var(--main-f-22-size) !important;
            display: flex !important;
            justify-content: center !important;
            box-shadow: 0 calc(-1 * var(--main-f-5-size)) var(--main-f-7-size) rgba(0, 0, 0, 0.1) !important;
            background-color: #ffffff !important;

            .user-information {
                margin: 0 0 var(--main-f-23-size) !important;

                .container-img {
                    margin-right: var(--main-f-5-size) !important;

                    img {
                        width: var(--main-f-50-size) !important;
                        height: var(--main-f-50-size) !important;
                        border-radius: 100% !important;
                    }
                }

                .col {
                    padding: 0 !important;
                    display: flex !important;
                    flex-direction: column !important;
                    align-self: center !important;

                    span {
                        line-height: var(--main-f-13-size) !important;
                        font-size: var(--main-f-13-size) !important;
                        margin-bottom: var(--main-f-2-size) !important;
                    }

                    p {
                        line-height: var(--main-f-17-size) !important;
                        font-size: var(--main-f-15-size) !important;
                        font-weight: 600 !important;
                        margin-bottom: 0 !important;
                    }
                }
            }

            .container-button {
                margin-top: 0 !important;
                display: flex !important;
                align-items: center !important;
                justify-content: space-between !important;

                p {
                    font-size: var(--main-f-17-size) !important;
                    margin: 0 !important;
                }

                button {
                    padding: var(--main-f-10-size) var(--main-f-11-size) !important;
                }
            }
        }
    }
}
