.modal:has(.modal-filter-enterprise) {
    overflow: hidden !important;
    display: flex !important;
    justify-content: flex-end !important;
    flex-direction: column !important;
}

.modal-filter-enterprise {
    width: 100% !important;
    margin: 0 !important;
    font-family: "Raleway" !important;

    .modal-content {
        padding: var(--main-f-25-size) var(--main-f-26-size) var(--main-f-43-size) !important;
        border-color: #7c7c7c !important;

        .row {
            margin: 0 !important;
        }

        .close-modal {
            display: flex !important;
            justify-content: flex-end !important;

            button {
                border: none !important;
                background-color: transparent !important;
            }
        }

        h1 {
            font-size: var(--main-f-26-size) !important;
            font-weight: 700 !important;
            margin-bottom: var(--main-f-20-size) !important;
        }

        form {
            overflow-y: scroll !important;
        }

        input,
        select {
            height: var(--main-f-53-size) !important;
            border-color: #262626 !important;
            border-radius: var(--main-f-11-size) !important;
            margin-bottom: var(--main-f-13-size) !important;
            padding: var(--main-f-23-size) var(--main-f-15-size) var(--main-f-7-size) var(--main-f-10-size) !important;
            -moz-appearance: none !important;
            -webkit-appearance: none !important;
            appearance: none !important;
        }

        select {
            background: url("../icons/arrowSelect.svg") no-repeat !important;
            background-position: calc(100% - var(--main-f-6-size)) center !important;

            &:disabled {
                background-position: calc(100% - var(--main-f-6-size)) center !important;
                background-color: #f3f3f3 !important;
                border-color: #a3a3a3 !important;
                color: #858585 !important;
            }
        }

        .container-autocomplete {
            div {
                display: flex !important;
            }
            
            input {
                width: 100% !important;
                height: var(--main-f-53-size) !important;
                padding: var(--main-f-20-size) var(--main-f-30-size) 0 var(--main-f-12-size) !important;
                border: var(--main-f-1-size) solid #262626 !important;
                border-radius: var(--main-f-10-size) !important;
                background: url("../icons/arrowSelect.svg") no-repeat right white !important;
                background-position: calc(100% - var(--main-f-6-size)) center !important;
                cursor: pointer !important;

                &:focus-visible {
                    outline: none !important;
                }

                &:disabled {
                    background-color: #f3f3f3 !important;
                    color: #a3a3a3 !important;
                }
            }

            .container-properties {
                min-width: 90% !important;
                max-width: 90% !important;
                max-height: var(--main-f-170-size) !important;
                margin-top: var(--main-f-9-size) !important;
                padding-top: var(--main-f-5-size) !important;
                padding-bottom: var(--main-f-5-size) !important;
                border: var(--main-f-1-size) solid rgba(196, 196, 196, 0.15) !important;
                border-radius: var(--main-f-9-size) !important;
                box-shadow: 0 0 var(--main-f-4-size) rgba(0, 0, 0, 0.25) !important;
                background-color: #fff !important;
                overflow-y: auto !important;
                z-index: 99 !important;
                position: absolute !important;
                left: var(--main-f-345-size) !important;
                top: 49.969px !important;
                flex-direction: column !important;

                .autocomplete {
                    width: 100% !important;
                    padding: var(--main-f-5-size) var(--main-f-5-size) var(--main-f-5-size) var(--main-f-9-size) !important;
                    cursor: pointer;
                }
            }

            .container-properties::-webkit-scrollbar {
                width: var(--main-f-6-size) !important;
                background-color: #f5f5f5 !important;
            }

            .container-properties::-webkit-scrollbar-track {
                box-shadow: inset 0 0 var(--main-f-3-size) rgba(196, 196, 196, 1) !important;
                border-radius: var(--main-f-18-size) !important;
                background-color: #f5f5f5 !important;
            }

            .container-properties::-webkit-scrollbar-thumb {
                border-radius: var(--main-f-18-size) !important;
                background-color: #535353 !important;
            }
        }

        label {
            font-size: var(--main-f-13-size) !important;
            color: #535353 !important;
            position: absolute !important;
            top: var(--main-f-7-size) !important;
            left: var(--main-f-25-size) !important;
        }

        .order-button {
            font-size: var(--main-f-15-size) !important;
            padding: var(--main-f-8-size) var(--main-f-22-size) !important;
        }

        .col-button-filter {
            button {
                font-size: var(--main-f-15-size) !important;
            }
        }
    }
}
