@media (min-width: 961px) {
  :root {
    --main-h1-size: calc(var(--calc-font-961) * var(--h1-size)) !important;
    --main-h2-size: calc(var(--calc-font-961) * var(--h2-size)) !important;
    --main-h3-size: calc(var(--calc-font-961) * var(--h3-size)) !important;
    --main-f-1-size: calc(var(--calc-font-961) * var(--f-1-size)) !important;
    --main-f-2-size: calc(var(--calc-font-961) * var(--f-2-size)) !important;
    --main-f-4-size: calc(var(--calc-font-961) * var(--f-4-size)) !important;
    --main-f-5-size: calc(var(--calc-font-961) * var(--f-5-size)) !important;
    --main-f-6-size: calc(var(--calc-font-961) * var(--f-6-size)) !important;
    --main-f-7-size: calc(var(--calc-font-961) * var(--f-7-size)) !important;
    --main-f-8-size: calc(var(--calc-font-961) * var(--f-8-size)) !important;
    --main-f-9-size: calc(var(--calc-font-961) * var(--f-9-size)) !important;
    --main-f-10-size: calc(var(--calc-font-961) * var(--f-10-size)) !important;
    --main-f-11-size: calc(var(--calc-font-961) * var(--f-11-size)) !important;
    --main-f-12-size: calc(var(--calc-font-961) * var(--f-12-size)) !important;
    --main-f-13-size: calc(var(--calc-font-961) * var(--f-13-size)) !important;
    --main-f-14-size: calc(var(--calc-font-961) * var(--f-14-size)) !important;
    --main-f-15-size: calc(var(--calc-font-961) * var(--f-15-size)) !important;
    --main-f-16-size: calc(var(--calc-font-961) * var(--f-16-size)) !important;
    --main-f-17-size: calc(var(--calc-font-961) * var(--f-17-size)) !important;
    --main-f-18-size: calc(var(--calc-font-961) * var(--f-18-size)) !important;
    --main-f-19-size: calc(var(--calc-font-961) * var(--f-19-size)) !important;
    --main-f-20-size: calc(var(--calc-font-961) * var(--f-20-size)) !important;
    --main-f-21-size: calc(var(--calc-font-961) * var(--f-21-size)) !important;
    --main-f-22-size: calc(var(--calc-font-961) * var(--f-22-size)) !important;
    --main-f-23-size: calc(var(--calc-font-961) * var(--f-23-size)) !important;
    --main-f-24-size: calc(var(--calc-font-961) * var(--f-24-size)) !important;
    --main-f-25-size: calc(var(--calc-font-961) * var(--f-25-size)) !important;
    --main-f-26-size: calc(var(--calc-font-961) * var(--f-26-size)) !important;
    --main-f-27-size: calc(var(--calc-font-961) * var(--f-27-size)) !important;
    --main-f-28-size: calc(var(--calc-font-961) * var(--f-28-size)) !important;
    --main-f-29-size: calc(var(--calc-font-961) * var(--f-29-size)) !important;
    --main-f-30-size: calc(var(--calc-font-961) * var(--f-30-size)) !important;
    --main-f-34-size: calc(var(--calc-font-961) * var(--f-34-size)) !important;
    --main-f-35-size: calc(var(--calc-font-961) * var(--f-35-size)) !important;
    --main-f-38-size: calc(var(--calc-font-961) * var(--f-38-size)) !important;
    --main-f-40-size: calc(var(--calc-font-961) * var(--f-40-size)) !important;
    --main-f-43-size: calc(var(--calc-font-961) * var(--f-43-size)) !important;
    --main-f-45-size: calc(var(--calc-font-961) * var(--f-45-size)) !important;
    --main-f-46-size: calc(var(--calc-font-961) * var(--f-46-size)) !important;
    --main-f-48-size: calc(var(--calc-font-961) * var(--f-48-size)) !important;
    --main-f-50-size: calc(var(--calc-font-961) * var(--f-50-size)) !important;
    --main-f-53-size: calc(var(--calc-font-961) * var(--f-53-size)) !important;
    --main-f-55-size: calc(var(--calc-font-961) * var(--f-55-size)) !important;
    --main-f-57-size: calc(var(--calc-font-961) * var(--f-57-size)) !important;
    --main-f-60-size: calc(var(--calc-font-961) * var(--f-60-size)) !important;
    --main-f-62-size: calc(var(--calc-font-961) * var(--f-62-size)) !important;
    --main-f-65-size: calc(var(--calc-font-961) * var(--f-65-size)) !important;
    --main-f-67-size: calc(var(--calc-font-961) * var(--f-67-size)) !important;
    --main-f-70-size: calc(var(--calc-font-961) * var(--f-70-size)) !important;
    --main-f-72-size: calc(var(--calc-font-961) * var(--f-72-size)) !important;
    --main-f-75-size: calc(var(--calc-font-961) * var(--f-75-size)) !important;
    --main-f-80-size: calc(var(--calc-font-961) * var(--f-80-size)) !important;
    --main-f-85-size: calc(var(--calc-font-961) * var(--f-85-size)) !important;
    --main-f-90-size: calc(var(--calc-font-961) * var(--f-90-size)) !important;
    --main-f-95-size: calc(var(--calc-font-961) * var(--f-95-size)) !important;
    --main-f-100-size: calc(var(--calc-font-961) * var(--f-100-size)) !important;
    --main-f-106-size: calc(var(--calc-font-961) * var(--f-106-size)) !important;
    --main-f-110-size: calc(var(--calc-font-961) * var(--f-110-size)) !important;
    --main-f-111-size: calc(var(--calc-font-961) * var(--f-111-size)) !important;
    --main-f-113-size: calc(var(--calc-font-961) * var(--f-113-size)) !important;
    --main-f-115-size: calc(var(--calc-font-961) * var(--f-115-size)) !important;
    --main-f-120-size: calc(var(--calc-font-961) * var(--f-120-size)) !important;
    --main-f-125-size: calc(var(--calc-font-961) * var(--f-125-size)) !important;
    --main-f-128-size: calc(var(--calc-font-961) * var(--f-128-size)) !important;
    --main-f-130-size: calc(var(--calc-font-961) * var(--f-130-size)) !important;
    --main-f-138-size: calc(var(--calc-font-961) * var(--f-138-size)) !important;
    --main-f-140-size: calc(var(--calc-font-961) * var(--f-140-size)) !important;
    --main-f-145-size: calc(var(--calc-font-961) * var(--f-145-size)) !important;
    --main-f-150-size: calc(var(--calc-font-961) * var(--f-150-size)) !important;
    --main-f-155-size: calc(var(--calc-font-961) * var(--f-155-size)) !important;
    --main-f-156-size: calc(var(--calc-font-961) * var(--f-156-size)) !important;
    --main-f-157-size: calc(var(--calc-font-961) * var(--f-157-size)) !important;
    --main-f-158-size: calc(var(--calc-font-961) * var(--f-158-size)) !important;
    --main-f-160-size: calc(var(--calc-font-961) * var(--f-160-size)) !important;
    --main-f-161-size: calc(var(--calc-font-961) * var(--f-161-size)) !important;
    --main-f-162-size: calc(var(--calc-font-961) * var(--f-162-size)) !important;
    --main-f-165-size: calc(var(--calc-font-961) * var(--f-165-size)) !important;
    --main-f-170-size: calc(var(--calc-font-961) * var(--f-170-size)) !important;
    --main-f-180-size: calc(var(--calc-font-961) * var(--f-180-size)) !important;
    --main-f-182-size: calc(var(--calc-font-961) * var(--f-182-size)) !important;
    --main-f-184-size: calc(var(--calc-font-961) * var(--f-184-size)) !important;
    --main-f-185-size: calc(var(--calc-font-961) * var(--f-185-size)) !important;
    --main-f-186-size: calc(var(--calc-font-961) * var(--f-186-size)) !important;
    --main-f-190-size: calc(var(--calc-font-961) * var(--f-190-size)) !important;
    --main-f-195-size: calc(var(--calc-font-961) * var(--f-195-size)) !important;
    --main-f-196-size: calc(var(--calc-font-961) * var(--f-196-size)) !important;
    --main-f-200-size: calc(var(--calc-font-961) * var(--f-200-size)) !important;
    --main-f-207-size: calc(var(--calc-font-961) * var(--f-207-size)) !important;
    --main-f-215-size: calc(var(--calc-font-961) * var(--f-215-size)) !important;
    --main-f-220-size: calc(var(--calc-font-961) * var(--f-220-size)) !important;
    --main-f-230-size: calc(var(--calc-font-961) * var(--f-230-size)) !important;
    --main-f-240-size: calc(var(--calc-font-961) * var(--f-240-size)) !important;
    --main-f-255-size: calc(var(--calc-font-961) * var(--f-255-size)) !important;
    --main-f-260-size: calc(var(--calc-font-961) * var(--f-260-size)) !important;
    --main-f-265-size: calc(var(--calc-font-961) * var(--f-265-size)) !important;
    --main-f-271-size: calc(var(--calc-font-961) * var(--f-271-size)) !important;
    --main-f-277-size: calc(var(--calc-font-961) * var(--f-277-size)) !important;
    --main-f-279-size: calc(var(--calc-font-961) * var(--f-279-size)) !important;
    --main-f-280-size: calc(var(--calc-font-961) * var(--f-280-size)) !important;
    --main-f-285-size: calc(var(--calc-font-961) * var(--f-285-size)) !important;
    --main-f-288-size: calc(var(--calc-font-961) * var(--f-288-size)) !important;
    --main-f-298-size: calc(var(--calc-font-961) * var(--f-298-size)) !important;
    --main-f-310-size: calc(var(--calc-font-961) * var(--f-310-size)) !important;
    --main-f-320-size: calc(var(--calc-font-961) * var(--f-320-size)) !important;
    --main-f-325-size: calc(var(--calc-font-961) * var(--f-325-size)) !important;
    --main-f-335-size: calc(var(--calc-font-961) * var(--f-335-size)) !important;
    --main-f-340-size: calc(var(--calc-font-961) * var(--f-340-size)) !important;
    --main-f-358-size: calc(var(--calc-font-961) * var(--f-358-size)) !important;
    --main-f-370-size: calc(var(--calc-font-961) * var(--f-370-size)) !important;
    --main-f-380-size: calc(var(--calc-font-961) * var(--f-380-size)) !important;
    --main-f-385-size: calc(var(--calc-font-961) * var(--f-385-size)) !important;
    --main-f-390-size: calc(var(--calc-font-961) * var(--f-390-size)) !important;
    --main-f-392-size: calc(var(--calc-font-961) * var(--f-392-size)) !important;
    --main-f-400-size: calc(var(--calc-font-961) * var(--f-400-size)) !important;
    --main-f-426-size: calc(var(--calc-font-961) * var(--f-426-size)) !important;
    --main-f-430-size: calc(var(--calc-font-961) * var(--f-430-size)) !important;
    --main-f-445-size: calc(var(--calc-font-961) * var(--f-445-size)) !important;
    --main-f-450-size: calc(var(--calc-font-961) * var(--f-450-size)) !important;
    --main-f-462-size: calc(var(--calc-font-961) * var(--f-462-size)) !important;
    --main-f-500-size: calc(var(--calc-font-961) * var(--f-500-size)) !important;
    --main-f-520-size: calc(var(--calc-font-961) * var(--f-520-size)) !important;
    --main-f-600-size: calc(var(--calc-font-961) * var(--f-600-size)) !important;
    --main-f-605-size: calc(var(--calc-font-961) * var(--f-605-size)) !important;
    --main-f-606-size: calc(var(--calc-font-961) * var(--f-606-size)) !important;
    --main-f-620-size: calc(var(--calc-font-961) * var(--f-620-size)) !important;
    --main-f-630-size: calc(var(--calc-font-961) * var(--f-630-size)) !important;
    --main-f-640-size: calc(var(--calc-font-961) * var(--f-640-size)) !important;
    --main-f-645-size: calc(var(--calc-font-961) * var(--f-645-size)) !important;
    --main-f-655-size: calc(var(--calc-font-961) * var(--f-655-size)) !important;
    --main-f-861-size: calc(var(--calc-font-961) * var(--f-861-size)) !important;
  }

  .container-404 {
    font-family: Raleway !important;
    margin-top: var(--main-f-100-size) !important;
    margin-left: var(--main-f-100-size) !important;

    .label-erro {
      font-weight: bold !important;
      font-size: var(--main-f-11-size) !important;
      line-height: 16px !important;
      color: #535353 !important;
    }

    .label-ops {
      font-weight: bold !important;
      font-size: var(--main-f-30-size) !important;
      line-height: 47px !important;
      color: #262626 !important;
    }

    .label-notfound {
      font-size: var(--main-f-12-size) !important;
      margin-top: var(--main-f-15-size) !important;
      color: #262626 !important;
    }

    .label-searchtext {
      font-size: var(--main-f-12-size) !important;
      margin-top: var(--main-f-40-size) !important;
      color: #262626 !important;
    }

    .btn-salmon-seleto {
      background-color: #d0222f !important;
      color: #ffffff !important;
      min-width: var(--main-f-125-size) !important;
      // width: auto !important;
      height: var(--main-f-28-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-12-size) !important;
      font-family: Raleway !important;
      border-color: #d0222f !important;
    }
  }

  .post {
    .card {
      max-width: 277px !important;
      max-height: 430px !important;
      border-radius: var(--main-f-10-size) !important;
      margin: 0px var(--main-f-4-size) !important;

      .card-img-top {
        right: 0 !important;
        position: absolute !important;
        top: 0px !important;
        bottom: var(--main-f-185-size) !important;
        border-radius: var(--main-f-10-size) var(--main-f-10-size) 0 0 !important;
        min-height: var(--main-f-138-size) !important;
        max-height: var(--main-f-138-size) !important;
        object-fit: contain !important;
        background-color: #f4f4f4 !important;
      }

      .card-body {
        padding: var(--main-f-7-size) !important;
        border-radius: var(--main-f-7-size) !important;
        padding-bottom: 0 !important;
      }

      .icons {
        width: auto !important;
        margin-right: var(--main-f-4-size) !important;
      }

      .margin-right {
        margin-right: var(--main-f-5-size) !important;
      }

      .card-title {
        display: flex !important;
        margin-bottom: var(--main-f-4-size) !important;
        font-family: Raleway-Bold !important;
        font-size: var(--main-f-13-size) !important;
        padding: 0 !important;
      }

      .card-subtitle {
        border-bottom: 1px solid !important;
        border-color: #9d9d9d !important;
        margin-bottom: 0 !important;
        display: flex !important;
        margin-top: var(--main-f-5-size) !important;
        font-size: var(--main-f-9-size) !important;
      }

      .card-text {
        display: flex !important;
        text-align: initial !important;
        margin-bottom: 0 !important;
        font-size: 92% !important;
        margin-top: var(--main-f-7-size) !important;

        &.location {
          max-height: 38px !important;
          min-height: 36px !important;
          overflow: hidden !important;
        }
      }

      .btn-white-card {
        background-color: #ffffff !important;
        border-color: #d0222f !important;
        color: #d0222f !important;
        width: var(--main-f-190-size) !important;
        height: var(--main-f-30-size) !important;
        border-radius: var(--main-f-8-size) !important;
        font-size: var(--main-f-12-size) !important;
        font-family: Raleway-Bold !important;
        margin-top: 0 !important;
        margin-bottom: var(--main-f-7-size) !important;
      }
    }
  }

  .body {
    padding-bottom: var(--main-f-30-size) !important;
    font-family: Raleway !important;
    overflow: hidden;
    width: 100% !important;
    padding: var(--main-f-15-size) !important;

    .card {
      border-radius: var(--main-f-10-size) !important;
      cursor: pointer !important;
      // width: var(--main-f-430-size) !important;
      // height: var(--main-f-120-size) !important;
      justify-content: center !important;

      .switch {
        width: var(--main-f-207-size) !important;
        padding: var(--main-f-4-size) !important;
        height: var(--main-f-40-size) !important;
        align-items: center !important;
        text-align: center !important;
        margin: 0 !important;
      }

      .btn-group {
        button {
          min-width: var(--main-f-90-size) !important;
          height: var(--main-f-30-size) !important;
          color: black !important;
          border-radius: var(--main-f-8-size) !important;
          font-size: var(--main-f-13-size) !important;
          background-color: white !important;
          font-family: Raleway-Bold !important;
          margin: 0px var(--main-f-4-size) !important;
          border: none !important;
        }
      }

      input {
        border-color: #cccccc !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: none !important;
      }

      .form-group {
        label {
          color: rgb(157, 157, 157) !important;
          font-size: var(--main-f-9-size) !important;
          margin-left: 0 !important;
          margin-bottom: 0 !important;
          margin-top: 0 !important;
        }
      }

      .select-number {
        border-color: #cccccc !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: none !important;
        width: var(--main-f-50-size) !important;
        height: var(--main-f-14-size) !important;
        font-size: var(--main-f-13-size) !important;
      }

      select {
        border-color: #cccccc !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: none !important;
        width: var(--main-f-100-size) !important;
        height: var(--main-f-14-size) !important;
        font-size: var(--main-f-13-size) !important;
      }

      .btn-card {
        background-color: white !important;
        border-radius: var(--main-f-8-size) !important;
        color: #d0222f !important;
        font-size: var(--main-f-13-size) !important;
        min-width: var(--main-f-155-size) !important;
        height: var(--main-f-30-size) !important;
        font-family: Raleway-Bold !important;
        border-color: #d0222f !important;
        margin-top: 0 !important;
        margin-bottom: var(--main-f-7-size) !important;
      }

      .card-subtitle {
        border-bottom: 1px solid !important;
        border-color: rgb(157, 157, 157) !important;
        margin-bottom: 0 !important;
        display: flex !important;
        margin-top: var(--main-f-5-size) !important;
        font-size: var(--main-f-9-size) !important;
      }

      .card-title {
        display: flex !important;
        margin-bottom: var(--main-f-4-size) !important;
        font-family: Raleway-Bold !important;
        font-size: var(--main-f-13-size) !important;
      }

      .card-text {
        display: flex !important;
        text-align: initial !important;
        margin-bottom: 0 !important;
        font-size: var(--main-f-11-size) !important;
        margin-top: var(--main-f-4-size) !important;
      }
    }

    .form-group {
      button {
        background-color: #d0222f !important;
        border-radius: var(--main-f-8-size) !important;
        color: white !important;
        font-size: var(--main-f-13-size) !important;
        font-family: Raleway-Bold !important;
        min-width: var(--main-f-155-size) !important;
        height: var(--main-f-30-size) !important;
        border-color: white !important;
        text-align: center !important;
      }

      label {
        font-size: var(--main-f-13-size) !important;
        font-family: Raleway-Bold !important;
        margin: var(--main-f-9-size) 0 !important;
      }

      input {
        border-radius: var(--main-f-8-size) !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
      }

      textarea {
        border-radius: var(--main-f-10-size) !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        height: var(--main-f-100-size) !important;
      }

      p {
        font-size: var(--main-f-9-size) !important;
        margin: 0 !important;
      }

      .modal {
        width: 360px !important;
        height: 438px !important;
        padding: var(--main-f-30-size) var(--main-f-20-size) !important;
      }
    }

    .container-banner {
      margin-top: var(--main-f-45-size) !important;
      justify-content: center !important;
      cursor: pointer !important;

      &.banner-seleto {
        margin-top: var(--main-f-45-size) !important;
      }

      img {
        width: 100% !important;
      }
    }

    h2 {
      font-size: var(--main-f-19-size) !important;
      margin-bottom: 0 !important;
      font-family: Raleway-Bold !important;
    }

    .h2-mini-title {
      font-size: var(--main-f-14-size) !important;
      text-align: center !important;
      font-family: Raleway !important;
      font-weight: 400 !important;
    }

    .my-properties {
      margin-bottom: var(--main-f-40-size);
    }

    h3 {
      margin-bottom: 0 !important;
      font-family: Raleway !important;
      font-size: var(--main-f-19-size) !important;
      text-align: center !important;
    }

    .h3-title-car {
      margin-bottom: 0 !important;
      font-family: Raleway-Bold !important;
      font-size: var(--main-f-19-size) !important;
      text-align: center !important;
    }

    h4 {
      padding-top: var(--main-f-20-size) !important;
      font-family: Raleway-Bold !important;
      font-size: var(--main-f-19-size) !important;
    }

    h5 {
      font-size: var(--main-f-13-size) !important;
      margin-bottom: 0 !important;
    }

    a {
      color: rgb(222, 88, 95) !important;
      text-decoration: underline !important;
    }
  }

  .card-inputs {
    width: var(--main-f-430-size) !important;
    height: var(--main-f-120-size) !important;
    justify-content: center !important;

    .form-group {
      margin: 0 !important;
      height: var(--main-f-60-size) !important;
      padding: var(--main-f-5-size) !important;
      min-width: var(--main-f-70-size) !important;
      padding-top: var(--main-f-15-size) !important;
      padding-left: 0 !important;

      .input-value {
        border-radius: 0 !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        width: var(--main-f-150-size) !important;
        height: var(--main-f-25-size) !important;
        margin-right: var(--main-f-5-size) !important;
        font-size: var(--main-f-13-size) !important;
        padding-left: var(--main-f-17-size) !important;
        margin-left: 0 !important;
      }

      .icons-pl {
        padding-left: var(--main-f-9-size) !important;
      }

      ::-webkit-input-placeholder {
        color: #bbbbbb !important;
        padding-left: 0 !important;
      }

      .autocomplete {
        font-size: var(--main-f-13-size) !important;
        border: 1px solid #cccccc !important;
        border-top: 0 !important;
        width: var(--main-f-430-size) !important;
        justify-content: left !important;
        padding-top: var(--main-f-10-size) !important;
        padding-bottom: var(--main-f-10-size) !important;
        padding-left: var(--main-f-18-size) !important;
      }
      .select-number {
        border-color: #cccccc !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: none !important;
        width: var(--main-f-50-size) !important;
        height: var(--main-f-14-size) !important;
        font-size: var(--main-f-13-size) !important;
        margin-left: var(--main-f-15-size) !important;
      }

      select {
        border-color: #cccccc !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        border: none !important;
        width: var(--main-f-106-size) !important;
        height: var(--main-f-17-size) !important;
        font-size: var(--main-f-13-size) !important;
        margin-left: var(--main-f-13-size) !important;
      }

      .margin-lf {
        margin-left: var(--main-f-22-size) !important;
      }

      input {
        border-radius: var(--main-f-10-size) !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        height: var(--main-f-25-size) !important;
        width: var(--main-f-400-size) !important;
        padding-left: var(--main-f-19-size) !important;
        font-size: var(--main-f-13-size) !important;
        margin-left: 0 !important;
      }

      .border-right {
        border-right: 1px solid #c8ced3 !important;
      }

      .border-bottom {
        border-bottom: 1px solid #c8ced3 !important;
      }

      i {
        color: rgb(157, 157, 157) !important;
      }
    }
  }

  .btn-salmon {
    background-color: #d0222f !important;
    border-radius: var(--main-f-8-size) !important;
    color: white !important;
    font-family: Raleway-Bold !important;
    height: var(--main-f-40-size) !important;
    border-color: white !important;
    min-width: var(--main-f-220-size) !important;
    margin-bottom: var(--main-f-10-size) !important;
    margin-top: var(--main-f-10-size) !important;
    font-size: var(--main-f-13-size) !important;
  }

  .align-center {
    text-align: center !important;
  }

  .my-properties {
    margin-bottom: var(--main-f-40-size) !important;
    text-align: center !important;
    font-size: var(--main-f-25-size) !important;
  }

  .margin-top {
    margin-top: var(--main-f-100-size) !important;
  }

  .margin-tp-bt {
    margin-top: var(--main-f-15-size) !important;
    margin-bottom: var(--main-f-7-size) !important;
  }

  .padding-bt {
    padding-bottom: var(--main-f-100-size) !important;
  }

  .switch {
    width: var(--main-f-135-size) !important;
    padding: var(--main-f-4-size) !important;
    height: var(--main-f-40-size) !important;
    align-items: center !important;
    text-align: center !important;
    margin: 0 !important;

    p {
      font-size: var(--main-f-18-size) !important;
    }

    button.btn.btn-outline-secondary.m-right {
      margin: 0px var(--main-f-5-size) 0px 0px !important;
      box-shadow: none !important;
      color: #535353 !important;
      font-size: var(--main-f-11-size) !important;
    }

    button.btn.btn-outline-secondary.m-left {
      margin: 0px 0px 0px var(--main-f-5-size) !important;
      box-shadow: none !important;
      color: #535353 !important;
      font-size: var(--main-f-11-size) !important;
    }

    button.is-rent.btn.btn-outline-secondary {
      background-color: rgba(222, 88, 95, 0.2) !important;
      box-shadow: none !important;
      color: #d0222f !important;
      font-size: var(--main-f-11-size) !important;
    }
  }

  .arrow-float-left {
    width: var(--main-f-28-size) !important;
    height: var(--main-f-28-size) !important;
    border-radius: var(--main-f-50-size) !important;
    padding: 0px !important;
    color: white !important;
    background-color: #d0222f !important;
    border-color: white !important;
    position: absolute !important;
    left: var(--main-f-70-size) !important;
    bottom: var(--main-f-100-size) !important;
    transition: all 0.25s ease-in 0s !important;
    z-index: 2 !important;
  }

  .arrow-float-right {
    width: var(--main-f-28-size) !important;
    height: var(--main-f-28-size) !important;
    border-radius: var(--main-f-50-size) !important;
    padding: 0px !important;
    color: white !important;
    background-color: #d0222f !important;
    border-color: white !important;
    position: absolute !important;
    right: var(--main-f-70-size) !important;
    bottom: var(--main-f-100-size) !important;
    transition: all 0.25s ease-in 0s !important;
    z-index: 2 !important;
  }

  .inline {
    display: flex !important;
    flex-direction: row !important;
    margin-top: var(--main-f-138-size) !important;
  }

  .row-form-group {
    justify-content: center !important;
  }

  .TagSeleto {
    width: 70px !important;
    left: var(--main-f-5-size) !important;
    top: var(--main-f-10-size) !important;
    position: absolute !important;
    z-index: 20 !important;
  }

  .carousel-root {
    &.carousel-imgs {
      height: var(--main-f-200-size) !important;
      max-height: var(--main-f-200-size) !important;
      margin-bottom: var(--main-f-60-size) !important;
      .carousel-container {
        height: var(--main-f-200-size) !important;
        justify-content: initial !important;

        div {
          height: 100% !important;
          // max-width: var(--main-f-277-size) !important;
          // padding: 0 !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
      }

      .carousel,
      .slider,
      .slide {
        height: var(--main-f-240-size) !important;
      }

      .room-img {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        height: 100% !important;
        margin-bottom: var(--main-f-70-size) !important;
        // max-width: 346px !important;
        object-fit: contain !important;
        background-color: #f4f4f4f4 !important;

        &.img-contains {
          object-fit: contain !important;
        }

        &.img-cover {
          object-fit: cover !important;
          padding: var(--main-f-1-size);
        }
      }

      .arrow-next {
        background-color: transparent !important;
        cursor: pointer !important;
        position: absolute !important;
        right: 4% !important;
        top: var(--main-f-80-size) !important;
        transition: all 0.25s ease-in 0s !important;
        width: 100px !important;
        height: 100px !important;
        z-index: 1 !important;
        img {
          border-radius: var(--main-f-9-size) !important;
          z-index: 2 !important;
          width: 100px !important;
          height: 100px !important;
          cursor: pointer !important;
        }
      }

      .arrow-prev {
        background-color: transparent !important;
        cursor: pointer !important;
        position: absolute !important;
        left: 0% !important;
        top: var(--main-f-80-size) !important;
        transition: all 0.25s ease-in 0s !important;
        width: 100px !important;
        height: 100px !important;
        z-index: 1 !important;
        img {
          border-radius: var(--main-f-9-size) !important;
          z-index: 2 !important;
          width: 100px !important;
          height: 100px !important;
          cursor: pointer !important;
        }
      }
    }
  }

  .carousel-root {
    width: 100vw !important;
    .carousel-container {
      -webkit-box-pack: center !important;
      -webkit-box-direction: normal !important;
      -webkit-box-orient: horizontal !important;
      display: flex !important;
      flex-direction: row !important;
      height: var(--main-f-330-size) !important;
      align-items: center !important;
      justify-content: center !important;
      width: 100% !important;
      margin: 0px var(--main-f-4-size) !important;

      &.row {
        width: auto !important;

        .container-card-enterprise {
          padding: 0 !important;
          display: flex !important;
          justify-content: center !important;

          .card-enterprise {
            width: 96% !important;
            height: auto !important;
            max-height: none !important;
            border-radius: var(--main-f-9-size) !important;
            margin-bottom: var(--main-f-25-size) !important;
            overflow: initial !important;
            cursor: pointer !important;

            .container-image {
              width: 100% !important;
              height: var(--main-f-130-size) !important;
              min-height: var(--main-f-130-size) !important;
              background-color: #f6f6f6 !important;
              border-radius: var(--main-f-8-size) var(--main-f-8-size) 0 0 !important;
              padding: 0 !important;
              overflow: hidden !important;

              .img-carousel {
                max-width: 100% !important;
                width: 100% !important;
                height: var(--main-f-130-size) !important;
                border-radius: 0 !important;
                margin: 0 !important;
                object-fit: contain !important;
                object-position: center;
                background-color: #f4f4f4 !important;

                &.img-contains {
                  object-fit: contain !important;
                }

                &.img-cover {
                  object-fit: cover !important;
                }
              }
            }

            .card-body {
              height: auto !important;
              padding: var(--main-f-8-size) var(--main-f-13-size) var(--main-f-15-size) !important;
              text-align: initial !important;

              .row {
                margin: 0 !important;
              }

              .container-name-broker {
                padding: 0 !important;

                p {
                  font-size: var(--main-f-8-size) !important;
                  margin: 0 !important;
                }

                h5 {
                  font-size: var(--main-f-11-size) !important;
                  font-weight: 600 !important;
                  overflow: hidden !important;
                  white-space: nowrap !important;
                  text-overflow: ellipsis !important;
                }
              }

              .container-immobile-location {
                p {
                  height: var(--main-f-24-size) !important;
                  line-height: var(--main-f-12-size) !important;
                  font-size: var(--main-f-10-size) !important;
                  font-weight: 600 !important;
                  color: #262626 !important;
                  margin: 0 !important;
                  display: -webkit-box !important;
                  -webkit-line-clamp: 2 !important;
                  -webkit-box-orient: vertical !important;
                  overflow: hidden !important;
                  text-overflow: ellipsis !important;
                }
              }

              .row-value {
                margin-top: var(--main-f-8-size) !important;
                margin-bottom: 0 !important;
                display: block !important;

                span {
                  font-size: var(--main-f-8-size) !important;
                  color: #262626 !important;
                }

                .text-value {
                  font-size: var(--main-f-12-size) !important;
                  line-height: var(--main-f-13-size) !important;
                  font-weight: 700 !important;
                  margin: 0 !important;
                }
              }

              .container-comission {
                margin-bottom: auto !important;

                p {
                  font-size: var(--main-f-9-size) !important;
                  font-style: italic !important;
                  margin: 0 !important;
                  color: #262626 !important;
                }
              }

              .container-icons {
                margin-top: var(--main-f-7-size) !important;
                margin-bottom: var(--main-f-25-size) !important;

                div {
                  display: flex !important;

                  &:nth-child(2) {
                    width: 50% !important;
                  }

                  img {
                    margin-right: var(--main-f-4-size) !important;
                  }

                  p {
                    font-size: var(--main-f-11-size) !important;
                    margin: 0 var(--main-f-18-size) 0 0 !important;
                  }
                }
              }

              .row-button {
                button {
                  font-size: var(--main-f-12-size) !important;
                  margin-top: var(--main-f-10-size) !important;
                }
              }
            }
          }
        }
      }

      .card {
        max-width: 277px !important;
        max-height: 430px !important;
        margin: 0px var(--main-f-4-size) !important;
        border-radius: var(--main-f-11-size) !important;

        .card-img-top {
          top: 0 !important;
          right: 0 !important;
          position: absolute !important;
          bottom: var(--main-f-235-size) !important;
          border-radius: var(--main-f-10-size) var(--main-f-10-size) 0 0 !important;
          min-height: var(--main-f-138-size) !important;
          max-height: var(--main-f-138-size) !important;
          object-fit: contain !important;
          background-color: #f4f4f4 !important;

          &.img-contains {
            object-fit: contain !important;
          }

          &.img-cover {
            object-fit: cover !important;
          }
        }

        .card-body {
          padding: var(--main-f-7-size) !important;
          border-radius: var(--main-f-7-size) !important;
          padding-bottom: 0 !important;
          font-family: Raleway !important;
        }

        .btn-white-card {
          background-color: white !important;
          border-radius: var(--main-f-8-size) !important;
          color: #d0222f !important;
          font-size: var(--main-f-13-size) !important;
          width: var(--main-f-180-size) !important;
          height: var(--main-f-31-size) !important;
          font-family: Raleway !important;
          border-color: #d0222f !important;
          margin-top: 0 !important;
          font-weight: 700 !important;
          margin-bottom: var(--main-f-7-size) !important;
        }

        .icons {
          width: auto !important;
          margin-right: var(--main-f-4-size) !important;
          margin-top: var(--main-f-2-size) !important;
        }

        .margin-right {
          margin-right: var(--main-f-5-size) !important;
        }
        .btn-card {
          background-color: white !important;
          border-radius: var(--main-f-8-size) !important;
          color: #d0222f !important;
          font-size: var(--main-f-13-size) !important;
          width: var(--main-f-145-size) !important;
          height: var(--main-f-30-size) !important;
          font-family: Raleway-Bold !important;
          border-color: #d0222f !important;
          margin-top: 0 !important;
          margin-bottom: var(--main-f-7-size) !important;
        }

        .card-subtitle {
          border-bottom: 1px solid !important;
          border-color: rgb(157, 157, 157) !important;
          margin-bottom: 0 !important;
          display: flex !important;
          margin-top: var(--main-f-9-size) !important;
          font-size: var(--main-f-9-size) !important;
        }

        .inline {
          font-family: Raleway-Bold !important;
          margin-top: var(--main-f-140-size) !important;
        }

        .card-title {
          display: flex !important;
          margin-bottom: var(--main-f-4-size) !important;
          font-size: var(--main-f-12-size) !important;
          font-family: Raleway-Bold !important;
        }

        .card-text {
          // display: flex !important;
          text-align: initial !important;
          margin-bottom: 0 !important;
          font-size: var(--main-f-10-size) !important;
          margin-top: var(--main-f-4-size) !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 2; /** número de linhas que você quer exibir */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .room-img {
          background-position: center !important;
          background-size: cover !important;
          background-repeat: no-repeat !important;
          height: var(--main-f-215-size) !important;
          margin-bottom: var(--main-f-70-size) !important;
          max-width: 421px !important;
        }

        .arrow-next {
          background-color: transparent !important;
          cursor: pointer !important;
          position: absolute !important;
          right: 15% !important;
          top: var(--main-f-145-size) !important;
          transition: all 0.25s ease-in 0s !important;
          width: 100px !important;
          height: 100px !important;
          z-index: 1 !important;
          img {
            border-radius: var(--main-f-9-size) !important;
            z-index: 2 !important;
            width: 100px !important;
            height: 100px !important;
            cursor: pointer !important;
          }
        }

        // .arrow-prev {
        //     background-color: transparent !important;
        //     cursor: pointer !important;
        //     position: absolute !important;
        //     left: 5% !important;
        //     top: var(--main-f-145-size) !important;
        //     transition: all 0.25s ease-in 0s !important;
        //     width: 100px !important;
        //     height: 100px !important;
        //     z-index: 1 !important;
        //     img {
        //         border-radius: var(--main-f-9-size) !important;
        //         z-index: 2 !important;
        //         width: 100px !important;
        //         height: 100px !important;
        //         cursor: pointer !important;
        //     }
        // }
      }
    }

    .arrow-next {
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: 15% !important;
      top: var(--main-f-145-size) !important;
      transition: all 0.25s ease-in 0s;
      width: 100px;
      z-index: 1;
      // background-color: transparent !important;
      // cursor: pointer !important;
      // position: absolute !important;
      // right: 15% !important;
      // top: var(--main-f-145-size) !important;
      // transition: all 0.25s ease-in 0s !important;
      // width: 100px !important;
      // height: 100px !important;
      // z-index: 1 !important;
      img {
        border-radius: var(--main-f-9-size) !important;
        z-index: 2 !important;
        width: 100px !important;
        height: 100px !important;
        cursor: pointer !important;
      }
    }

    .arrow-prev {
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      left: 15% !important;
      top: var(--main-f-145-size) !important;
      transition: all 0.25s ease-in 0s;
      width: 100px;
      z-index: 1;
      // background-color: transparent !important;
      // cursor: pointer !important;
      // position: absolute !important;
      // left: 15% !important;
      // top: var(--main-f-145-size) !important;
      // transition: all 0.25s ease-in 0s !important;
      // width: 100px !important;
      // height: 100px !important;
      // z-index: 1 !important;
      img {
        border-radius: var(--main-f-9-size) !important;
        z-index: 2 !important;
        width: 100px !important;
        height: 100px !important;
        cursor: pointer !important;
      }
    }
  }

  .backgroundcolor-white {
    background-color: #ffffff !important;
  }

  .padding10 {
    padding: var(--main-f-10-size) !important;
  }

  .div-size {
    width: 100% !important;
    min-height: 1vh !important;
  }

  .grey-div {
    background-color: rgb(246, 246, 246) !important;

    .arrow-next {
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: 15% !important;
      top: var(--main-f-140-size) !important;
      transition: all 0.25s ease-in 0s;
      width: 100px;
      z-index: 1;
      img {
        border-radius: 11px;
        z-index: 2;
        width: 100px !important;
        height: 100px !important;
        cursor: pointer;
      }
    }

    .arrow-prev {
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      left: 15% !important;
      top: var(--main-f-140-size) !important;
      transition: all 0.25s ease-in 0s;
      width: 100px;
      z-index: 1;
      img {
        border-radius: 11px;
        z-index: 2;
        width: 100px !important;
        height: 100px !important;
        cursor: pointer;
      }
    }

    .arrow-next-steps {
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      transition: all 0.25s ease-in 0s;
      width: 70px;
      z-index: 1;
      img {
        border-radius: 11px;
        z-index: 2;
        width: 70px !important;
        height: 70px !important;
        cursor: pointer;
      }
    }

    .arrow-prev-steps {
      background-color: transparent;
      cursor: pointer;
      position: absolute;
      left: 10px;
      top: 10px;
      transition: all 0.25s ease-in 0s;
      width: 70px;
      z-index: 1;
      img {
        border-radius: 11px;
        z-index: 2;
        width: 70px !important;
        height: 70px !important;
        cursor: pointer;
      }
    }
  }

  .header {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: var(--main-f-38-size) !important;
    z-index: 9 !important;
    box-shadow: rgb(202 202 202) 0px 0px 6px !important;
    font-family: Raleway !important;
    border: none !important;

    img {
      margin-top: 0px !important;
    }

    .info-broker {
      margin-bottom: 6px !important;
    }
    .app-nav-bar-brand {
      vertical-align: middle !important;
    }

    .new-search {
      margin-right: var(--main-f-50-size) !important;
    }

    .new-search-seleto {
      width: var(--main-f-111-size) !important;
      height: var(--main-f-30-size) !important;
      font-size: var(--main-f-12-size) !important;
      margin-right: var(--main-f-12-size) !important;
      background-color: #d0222f !important;
      border: 1px solid #d0222f !important;
      border-radius: var(--main-f-8-size) !important;
      border-color: #d0222f !important;
      color: #ffffff !important;
    }

    .div-size-header {
      // width: var(--main-f-110-size) !important;
      width: 248px !important;
      margin-right: var(--main-f-25-size) !important;

      p {
        font-size: var(--main-f-8-size) !important;
      }
    }

    button {
      background-color: white !important;
      color: #d0222f !important;
      height: var(--main-f-30-size) !important;
      border: 1px solid #d0222f !important;
      border-radius: var(--main-f-7-size) !important;
      font-size: var(--main-f-13-size) !important;
      font-weight: 700 !important;
      margin-right: 1% !important;
    }

    .avatar {
      border-radius: var(--main-f-27-size) !important;
      width: var(--main-f-30-size) !important;
      height: var(--main-f-30-size) !important;
      overflow: hidden !important;
      vertical-align: middle !important;
    }

    h5 {
      vertical-align: middle !important;
      margin: 0 !important;
      font-size: var(--main-f-13-size) !important;
      margin-left: 0px !important;
    }

    h6 {
      margin-right: 0 !important;
      margin-bottom: 0 !important;
      font-size: var(--main-f-10-size) !important;
      color: rgb(38, 38, 38) !important;
      margin-left: var(--main-f-5-size) !important;
    }

    p {
      margin-bottom: 0 !important;
      font-size: var(--main-f-10-size) !important;
      color: rgb(38, 38, 38) !important;
      margin-left: var(--main-f-5-size) !important;
      font-family: Raleway !important;
    }
  }

  .bold {
    font-weight: 700 !important;
  }

  .post-details {
    font-family: Raleway !important;
    max-width: 100% !important;
    margin-top: 0px !important;

    &.list-gallery {
      max-height: 100vh !important;
      overflow: hidden !important;
    }

    .home-town p span:hover {
      text-decoration: underline !important;
      cursor: pointer !important;
    }

    .button-group {
      margin-top: var(--main-f-5-size) !important;
    }

    .border-tp {
      border-top: 0 solid #cacaca !important;
      margin-top: 0 !important;
      min-height: auto !important;
      margin-bottom: 0 !important;
      max-width: 1200px !important;
    }

    .card-text-brokers {
      margin-top: 25px !important;
      margin-top: var(--main-f-25-size) !important;
      margin-left: -60px;
      font-size: 9px !important;
      font-size: var(--main-f-9-size) !important;
      width: 370px !important;
      width: var(--main-f-370-size) !important;
      line-height: inherit !important;
    }

    .card-label-card {
      position: absolute !important;
      width: var(--main-f-265-size);
      height: var(--main-f-200-size) !important;
      background: #ffffff !important;
      box-shadow: 0px 0px var(--main-f-8-size) rgba(0, 0, 0, 0.25) !important;
      border-radius: var(--main-f-8-size) !important;
      margin-top: calc(-1 * var(--f-70-size)) !important;
      padding: 0px 20px 20px 20px !important;

      .horizontal-line {
        margin-top: var(--main-f-12-size) !important;
        border-top: 1px dashed #cacaca;
        padding-right: 0px !important;
        // margin-left: -2px !important;
        width: auto !important;
      }
      label {
        margin-top: 0px !important;
      }

      input {
        height: var(--main-f-30-size) !important;
      }

      button {
        // margin-top: var(--main-f-7-size) !important;

        img {
          margin-right: var(--main-f-8-size) !important;
        }
      }

      .description-col {
        // padding-left: 0px !important;

        label {
          font-size: var(--main-f-11-size) !important;
        }
      }

      .content-align {
        place-content: end;
      }

      .dropdown-menu {
        background: #ffffff !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        border-radius: 10px !important;
        transform: translate3d(0px, -162px, 0px) !important;
        top: 20px !important;
      }

      .dropdown-toggle:focus::after {
        // transform: rotate(135deg);
      }

      .dropdown-toggle::after {
        height: 10px;
        width: 10px;
        border: 1px solid #d0222f;
        border-width: 2px 2px 0 0;
        transform: rotate(135deg);
        margin-left: 1.255em !important;
        vertical-align: 3px !important;
      }
      .dropdown-item {
        padding: 5px !important;
        padding-left: 0px !important;
        font-family: Raleway !important;
        font-size: var(--main-f-12-size) !important;
        text-align: center !important;
        width: var(--main-f-230-size) !important;
        a {
          margin-left: 0px !important;
        }
      }

      .show > .btn-secondary.dropdown-toggle {
        color: #d0222f !important;
      }

      .show > .btn-secondary.dropdown-toggle::after {
        color: #d0222f !important;
        transform: rotate(-45deg);
        vertical-align: -2px !important;
      }

      .broker {
        justify-content: center !important;
        align-items: center !important;
        padding: 9px 24px !important;
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        outline: none;
        width: var(--main-f-230-size) !important;
        background: #d0222f !important;
        border: 1px solid #d0222f !important;
        box-sizing: border-box !important;
        border-radius: 10px !important;
        left: 0 !important;
        color: #ffffff;
      }

      .title {
        font-family: Raleway;
        font-style: normal;
        font-weight: normal;
        font-size: var(--main-f-11-size) !important;
        line-height: 16px;
      }

      .price {
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: var(--main-f-21-size) !important;
        line-height: 33px;
      }

      .financial {
        font-family: Raleway;
        font-style: normal;
        font-weight: bold;
        font-size: var(--main-11-size) !important;
        line-height: 16px;
        text-align: right;
        text-decoration-line: underline;
        margin-bottom: var(--main-f-18-size);
        color: #d0222f !important;
        margin-bottom: 10px !important;
      }
    }

    .form-home {
      min-height: 56vh !important;
    }

    .post-details-similar {
      max-width: 1200px !important;
    }

    .app {
      // margin-top: var(--main-f-38-size) !important;
    }
    .property-details {
      width: 80px;
      height: 80px;
      text-align: center !important;
      background: #f6f6f6;
      border-radius: 10px;
      padding-top: 20px;
      margin-right: 15px;
    }

    .details {
      max-width: 1200px !important;
    }

    &.encontra {
      .homer-encontra {
        img {
          width: 100%;
        }
      }
    }

    &.location {
      .left-side {
        padding: 0 !important;

        .left-side-content {
          background: #fff;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          padding: 60px 50px;
          color: #262626 !important;

          .section-title {
            font-size: var(--main-f-17-size) !important;
            font-weight: bold !important;
            margin-bottom: 15px !important;
            width: var(--main-f-240-size) !important;
          }

          .section-sutitle {
            font-size: var(--main-f-14-size) !important;
            width: var(--main-f-260-size) !important;
          }
        }
      }

      .explore-container {
        background: #ffffff;
        border: 1px solid #cacaca;
        box-sizing: border-box;
        border-radius: 10px;
        height: var(--main-f-200-size) !important;
        margin-bottom: var(--main-f-30-size) !important ;
      }

      .right-side {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 !important;
        background: #fff;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;

        .right-side-content {
          vertical-align: middle;
          background: #fff;
          padding: 20px 50px;
          border-radius: 10px;
          height: 250px;

          img {
            margin: auto;
            width: 100%;
            height: 100%;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    .TagSeletoCard {
      width: 70px !important;
      left: var(--main-f-10-size) !important;
      top: var(--main-f-15-size) !important;
      position: absolute !important;
      z-index: 20 !important;
    }

    .TagSeleto {
      width: 70px !important;
      margin-left: var(--main-f-7-size) !important;
      margin-bottom: var(--main-f-7-size) !important;
      position: initial !important;
      z-index: 20 !important;
    }

    .homer-location {
      margin-bottom: var(--main-f-30-size) !important;
    }

    .img {
      width: var(--main-f-360-size) !important;
      height: var(--main-f-233-size) !important;
    }

    .margin-bottom-footer {
      align-self: center !important;
    }

    .style-ios-playstore {
      margin-top: 0px;
      height: 83px;
    }

    .similar {
      min-height: 25vw !important;
    }

    .encontra {
      margin-top: var(--main-f-40-size) !important;
    }
    .cards-information {
      margin-top: var(--main-f-70-size) !important;
    }

    .whatsapp-floating-button-container {
      padding-left: 10px;

      button {
        // min-width: 102px;
        width: var(--main-f-102-size) !important;
        background: #25d366 !important;
        border: 1px solid #25d366 !important;
        padding: 9px 24px;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .dropdown-menu {
        min-width: var(--main-f-195-size);
        display: inline-table;
        text-align: left !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
        left: -75px;

        a {
          font-weight: bold;
          width: 100% !important;
          border-bottom: 1px solid #e6e6e6;

          &:hover {
            background: white !important;
            color: black !important;
          }

          &:active {
            background: white !important;
            color: black !important;
          }
        }
      }
    }
    .homer-seleto-mobile {
      width: 100%;
      position: absolute;
      z-index: 2;
      margin-top: 20px !important;
      margin-left: 10px;

      .homer-seleto-tag {
        vertical-align: inherit !important;
        margin-left: 5px !important;
      }
    }

    .homer-encontra-container {
      margin-top: var(--main-f-20-size) !important;
      margin-bottom: var(--main-f-20-size) !important;
    }

    .row-footer {
      justify-content: flex-end !important;
      width: var(--main-f-165-size) !important;
    }

    .react-simple-image-viewer__modal {
      z-index: 79;
      -webkit-transform: translate3d(0, 0, 0);

      .react-simple-image-viewer__close {
        z-index: 80;
        opacity: 1 !important;
      }

      .react-simple-image-viewer__previous {
        opacity: 1 !important;
      }

      .react-simple-image-viewer__next {
        opacity: 1 !important;
      }
    }

    .carousel-root {
      width: 100vw !important;
      .carousel-container {
        -webkit-box-pack: center !important;
        -webkit-box-direction: normal !important;
        -webkit-box-orient: horizontal !important;
        // background-color: rgb(255, 255, 255) !important;
        display: flex !important;
        flex-direction: row !important;
        height: var(--main-f-335-size) !important;
        align-items: center !important;
        justify-content: center !important;
        width: 100% !important;
        margin: 0px var(--main-f-4-size) !important;

        .card {
          max-width: 277px !important;
          max-height: 430px !important;
          margin: 0px var(--main-f-4-size) !important;
          border-radius: var(--main-f-10-size) !important;

          .card-img-top {
            right: 0 !important;
            position: absolute !important;
            bottom: var(--main-f-196-size) !important;
            border-radius: var(--main-f-10-size) var(--main-f-10-size) 0 0 !important;
            min-height: var(--main-f-138-size) !important;
            max-height: var(--main-f-138-size) !important;
            object-fit: contain !important;
            background-color: #f4f4f4 !important;

            &.img-contains {
              object-fit: contain !important;
            }

            &.img-cover {
              object-fit: cover !important;
            }
          }

          .card-body {
            padding: var(--main-f-7-size) !important;
            border-radius: var(--main-f-7-size) !important;
            padding-bottom: 0 !important;
          }

          .icons {
            width: auto !important;
            margin-right: var(--main-f-4-size) !important;
          }

          .margin-right {
            margin-right: var(--main-f-10-size) !important;
          }
        }
      }
    }

    .arrow-next {
      background-color: transparent !important;
      cursor: pointer !important;
      position: absolute !important;
      right: var(--main-f-2-size) !important;
      top: var(--main-f-95-size) !important;
      transition: all 0.25s ease-in 0s !important;
      width: 100px !important;
      height: 100px !important;
      z-index: 1 !important;

      img {
        width: 100px !important;
        height: 100px !important;
      }
    }

    .arrow-prev {
      background-color: transparent !important;
      cursor: pointer !important;
      position: absolute !important;
      left: var(--main-f-2-size) !important;
      top: var(--main-f-95-size) !important;
      transition: all 0.25s ease-in 0s !important;
      width: 100px !important;
      height: 100px !important;
      z-index: 1 !important;

      img {
        width: 100px !important;
        height: 100px !important;
      }
    }

    h1 {
      font-size: var(--main-f-14-size) !important;
      font-weight: 300 !important;
      background: rgba(38, 38, 38, 1) !important;
      color: rgba(255, 255, 255, 1) !important;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
      border-radius: var(--main-f-30-size) !important;
      width: var(--main-f-126-size) !important;
      height: var(--main-f-24-size) !important;
      padding-left: var(--main-f-12-size) !important;
      padding-top: var(--main-f-4-size) !important;
      font-family: Raleway !important;

      strong {
        font: revert;
        margin-left: var(--main-f-4-size) !important;
      }

      .card-img {
        width: var(--main-f-11-size) !important;
      }
    }

    .homer-select-span-mobile {
      min-width: var(--main-f-380-size) !important;
      margin-top: var(--main-f-5-size) !important;
      font-weight: 600 !important;
      font-size: smaller !important;
      margin-left: var(--main-f-20-size) !important;
      right: var(--main-f-20-size) !important;
    }

    .homer-select-span {
      margin-bottom: 0 !important;
    }

    h2 {
      font-size: var(--main-f-17-size) !important;
      margin-top: var(--main-f-12-size) !important;
      font-family: Raleway-Bold !important;
    }

    h3 {
      font-size: var(--main-f-17-size) !important;
      margin-top: 0 !important;
    }

    .p-class {
      margin-top: var(--main-f-2-size) !important;
      margin-bottom: var(--main-f-2-size) !important;
      font-size: 14px !important;
      overflow: hidden !important;
      white-space: pre-wrap !important;
    }

    .label-next {
      font-size: var(--main-f-14-size) !important;
      color: #262626 !important;
      background: #f6f6f6 !important;
      border-radius: var(--main-f-30-size) !important;
      width: var(--main-f-150-size) !important;
      height: var(--main-f-30-size) !important;
      margin-top: var(--main-f-12-size) !important;
      padding-top: var(--main-f-4-size) !important;
      padding-left: var(--main-f-20-size) !important;
    }

    .label-tag {
      font-size: var(--main-f-14-size) !important;
      color: #262626 !important;
      background: #f6f6f6 !important;
      border-radius: var(--main-f-30-size) !important;
      padding: 5px 11px;
      margin-left: var(--main-f-8-size) !important;
      font-size: var(--main-f-11-size) !important;
      margin-top: var(--main-f-8-size) !important;
    }

    .label-sol {
      font-size: var(--main-f-14-size) !important;
      color: #262626 !important;
      background: #f6f6f6 !important;
      border-radius: var(--main-f-30-size) !important;
      width: var(--main-f-150-size) !important;
      height: var(--main-f-30-size) !important;
      margin-top: var(--main-f-12-size) !important;
      padding-top: var(--main-f-4-size) !important;
      padding-left: var(--main-f-30-size) !important;
    }

    .mb-3-text-muted {
      margin-top: var(--main-f-10-size) !important;
      font-size: var(--main-f-13-size) !important;
      line-height: var(--main-f-14-size) !important;
      width: var(--main-f-55-size) !important;
      height: var(--main-f-14-size) !important;
      color: #898989 !important;
      border-bottom: 0px !important;
    }

    .vector {
      width: var(--main-f-14-size) !important;
      height: var(--main-f-17-size) !important;
    }

    .margin-right {
      margin-right: var(--main-f-10-size) !important;
    }

    .mb-3-text-muted::before {
      content: "";
      width: 350px !important;
      height: var(--main-f-1-size) !important;
      position: absolute !important;
      border: 1px solid #cacaca !important;
      margin-top: var(--main-f-15-size) !important;
    }

    label {
      font-size: var(--main-f-16-size) !important;
      color: rgba(0, 0, 0, 1) !important;
    }
    .btn-whatsapp {
      justify-content: center !important;
      align-items: center !important;
      padding: 9px 24px !important;
      font-family: Raleway;
      font-style: normal;
      font-weight: 600;
      outline: none;
      width: var(--main-f-230-size) !important;

      background: #ffffff !important;
      border: 1px solid #d0222f !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
      left: 0 !important;
      margin-bottom: 20px !important;
      color: #d0222f;
    }

    .btn-secondary-2 {
      // position: absolute !important;
      // min-width: var(--main-f-155-size) !important;
      height: var(--main-f-30-size) !important;
      background: #d0222f !important;
      color: white !important;
      font-size: var(--main-f-12-size) !important;
      font-family: Raleway !important;
      border-radius: var(--main-f-8-size) !important;
      align-items: center !important;
      border-color: white !important;
      left: var(--main-f-358-size) !important;
      bottom: var(--main-f-7-size) !important;
      top: auto !important;
      z-index: 2;

      &.btn-share-post {
        background: white !important;
        color: #d0222f !important;
        border: 1px solid #d0222f !important;
        width: var(--main-f-180-size) !important;
        margin-right: var(--main-f-10-size) !important;
        border-radius: 10px !important;

        &:hover {
          background: #fdf4f5 !important;
        }

        &:active {
          background: #fdf4f5 !important;
        }
      }

      &.btn-see-photo {
        background: white !important;
        color: #d0222f !important;
        border: 1px solid #d0222f !important;
        width: var(--main-f-100-size) !important;
        margin-right: var(--main-f-10-size) !important;
        border-radius: 10px !important;

        &:hover {
          background: #fdf4f5 !important;
        }

        &:active {
          background: #fdf4f5 !important;
        }
      }

      &.btn-see-maps {
        background: white !important;
        color: #d0222f !important;
        border: 1px solid #d0222f !important;
        width: var(--main-f-100-size) !important;
        margin-right: var(--main-f-10-size) !important;
        border-radius: 10px !important;

        &:hover {
          background: #fdf4f5 !important;
        }

        &:active {
          background: #fdf4f5 !important;
        }
      }
    }
    .map-container {
      width: 100% !important;
      height: var(--main-f-145-size) !important;
      margin-top: 5px !important;

      .pointers {
        width: 30px;
        height: 30px;
        border-radius: 50% 50% 50% 0;
        background: #d0222f;
        position: absolute;
        transform: rotate(-45deg);
        left: 50%;
        top: 50%;
        margin: -20px 0 0 -20px;
      }
    }

    .seleto-container {
      background: #ffffff;
      border: 1px solid #cacaca;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0px !important;

      .col-left {
        padding-left: 0px !important;
        padding-right: 0px !important;
      }

      .title-seleto {
        line-height: normal !important;
        font-weight: 700 !important;
        margin-top: var(--main-f-2-size) !important;
        width: var(--main-f-200-size) !important;
        font-size: var(--main-f-15-size) !important;
      }

      .description-seleto {
        font-size: var(--main-f-11-size) !important;
      }

      .img-seleto {
        margin-top: var(--main-f-40-size) !important;
      }
    }

    .search-container {
      background: #ffffff !important;
      border: 1px solid #cacaca !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
      height: var(--main-f-75-size) !important;
      align-content: center;
      margin-top: var(--main-f-43-size) !important;

      .title-search {
        font-weight: 700;
        margin-bottom: 0px !important;
      }

      h6 {
        margin-bottom: 0px !important;
        font-size: var(--main-f-11-size) !important;
      }

      img {
        width: 47px;
        height: 47px;
        margin-bottom: 0px !important;
      }

      .btn-forme {
        font-weight: 600;
        font-size: var(--main-f-12-size) !important;
        align-items: center;
        text-align: center;
        background: white !important;
        color: #d0222f;
        border: 1px solid #d0222f !important;
        width: var(--main-f-150-size) !important;
        border-radius: 10px;
        margin-top: var(--main-f-7-size);
      }
    }

    .btn-secondary-explore-location {
      position: relative !important;
      width: var(--main-f-180-size) !important;
      background: #fff !important;
      border-color: #d0222f !important;
      color: #d0222f !important;
      font-size: var(--main-f-12-size) !important;
      font-family: Raleway-Bold !important;
      border-radius: var(--main-f-8-size) !important;
      align-items: center !important;

      &:hover {
        background: #fdf4f5 !important;
      }

      &:active {
        background: #fdf4f5 !important;
      }
    }

    .small-share-modal-container {
      position: absolute !important;
      width: var(--main-f-280-size) !important;
      height: var(--main-f-100-size) !important;
      background: white !important;
      color: #000000 !important;
      font-size: var(--main-f-14-size) !important;
      border-radius: var(--main-f-10-size) !important;
      align-items: left !important;
      left: 155px;
      top: 45px;
      border: 1px solid #cacaca;
      padding: 12px 24px;
      z-index: 2;
      position: relative;

      .share-header {
        margin-bottom: 13px;
        position: relative;

        .share-header-item {
          display: inline-block;

          &.share-close-button {
            position: absolute !important;
            right: 0;
            cursor: pointer;

            .close-button-image {
              width: 13.97px;
            }
          }
        }
      }
      .share-body {
        position: absolute !important;
        bottom: 7px;

        .share-options {
          .share-option {
            display: inline-block;
            padding-right: var(--main-f-20-size) !important;
            cursor: pointer;
            text-align: center;

            .share-option-image {
              width: var(--main-f-28-size) !important;
            }

            .share-option-label {
              margin-top: 5px;
              font-size: var(--main-f-10-size) !important;
            }
          }
        }
      }
    }

    .floating-button-container {
      position: fixed;
      right: 0px;
      top: 0px;
      height: 100vh;
      z-index: 99;
      display: inherit;

      .floating-button-container-content {
        position: absolute;
        bottom: 24px;
        right: 40px;
        transition: margin 220ms ease 0s;
        margin-bottom: 0px;
        cursor: pointer;

        .whatsapp-floating-button-container {
          button {
            min-width: 102px;
            background: #25d366 !important;
            padding: 9px 24px;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }

          .dropdown-menu {
            width: 100%;
            display: block;
            text-align: left !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: none;

            a {
              font-weight: bold;
              width: 100% !important;
              border-bottom: 1px solid #e6e6e6;

              &:hover {
                background: white !important;
                color: black !important;
              }

              &:active {
                background: white !important;
                color: black !important;
              }
            }
          }
        }
      }
    }

    .p-span {
      margin-top: var(--main-f-20-size) !important;
      font-size: var(--main-f-13-size) !important;
    }

    .label-home {
      font-size: var(--main-f-30-size) !important;
      color: white !important;
      margin-top: var(--main-f-20-size) !important;
      margin-bottom: var(--main-f-30-size) !important;
      font-family: Raleway !important;
    }

    .home-select {
      font-size: var(--main-f-25-size) !important;
      color: white !important;
      width: var(--main-f-298-size) !important;
      margin-bottom: var(--main-f-25-size) !important;
    }
    .home-select-p {
      font-size: var(--main-f-17-size) !important;
      color: rgb(202, 202, 202) !important;
    }

    .card-homeseleto {
      position: relative !important;
      background: rgba(38, 38, 38, 1) !important;
      border-radius: 0.7rem !important;
      color: #ffffff !important;
      font-style: normal !important;
      font-weight: var(--main-f-462-size) !important;
      line-height: var(--main-f-16-size) !important;
      max-height: 70% !important;
      overflow: hidden !important;
      height: auto !important;

      .cardBody-homeseleto {
        font-size: var(--main-f-19-size) !important;
        margin-bottom: var(--main-f-26-size) !important;
        padding-left: var(--main-f-30-size) !important;
        padding-right: var(--main-f-43-size) !important;

        p {
          font-size: var(--main-f-15-size) !important;
          width: auto !important;
        }

        .h-picked::after {
          content: " ";
          width: var(--main-f-70-size) !important;
          height: 1px !important;
          position: absolute !important;
          border: 1px solid #fb756d !important;
          left: var(--main-f-4-size) !important;
          margin-top: calc(-1 * var(--f-8-size)) !important;
          margin-left: var(--main-f-10-size) !important;
        }
      }
    }

    .label-similar {
      font-size: var(--main-f-19-size) !important;
      font-weight: bold !important;
      line-height: var(--main-f-35-size) !important;
      color: #262626 !important;
      margin-bottom: 0px !important;
      margin-top: var(--main-f-20-size) !important;
    }

    .text-features {
      font-size: var(--main-f-13-size) !important;
      color: #262626 !important;
      margin-bottom: var(--main-f-20-size) !important;
      line-height: var(--main-f-19-size) !important;
      justify-content: start !important;
    }

    .btn-btn-secondary1 {
      color: #d0222f !important;
      font-size: 16px !important;
      width: 200px !important;
      height: 50px !important;
      background: #ffffff !important;
      font-size: 16px !important;
      border: 1px solid #d0222f !important;
      box-sizing: border-box !important;
      border-radius: 30px !important;
    }

    .imagecard {
      width: var(--main-f-125-size) !important;

      .h-assets-imgs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        align-self: center !important;
        text-align: -webkit-center !important;
        vertical-align: middle !important;
        .h-imgs {
          width: auto !important;
          height: auto !important;
          margin-top: var(--main-f-20-size) !important;
          margin-left: var(--main-f-17-size) !important;
        }
      }
      .o-assets-imgs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        left: var(--main-f-2-size) !important;
        .o-imgs {
          width: var(--main-f-24-size) !important;
          margin-block-start: var(--main-f-4-size) !important;
        }
      }
      .m-assets-imgs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        right: var(--main-f-4-size) !important;
        .m-imgs {
          width: var(--main-f-34-size) !important;
          margin-block-start: var(--main-f-8-size) !important;
        }
      }
      .e-assets-imgs {
        right: var(--main-f-2-size) !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .e-imgs {
          width: var(--main-f-22-size) !important;
          margin-block-start: var(--main-f-8-size) !important;
        }
      }

      .r-assets-imgs {
        padding-left: 0 !important;
        padding-right: 0 !important;
        right: var(--main-f-8-size) !important;
        .r-imgs {
          width: var(--main-f-12-size) !important;
          height: var(--main-f-22-size) !important;
          margin-block-start: var(--main-f-8-size) !important;
        }
      }
    }
    .a-href {
      width: var(--main-f-200-size) !important;
      margin-left: calc(-1 * var(--f-16-size)) !important;
      color: #262626 !important;
      padding-bottom: var(--main-f-5-size) !important;
      padding-top: var(--main-f-5-size) !important;
    }

    .margin-bt-auto {
      margin-bottom: var(--main-f-50-size) !important;
    }

    .margin-row-image {
      margin-bottom: var(--main-f-100-size) !important;
    }

    .text {
      font-weight: 600 !important;
      color: #535353 !important;
    }

    .card-text-download-app {
      margin-top: var(--main-f-12-size) !important;
      font-size: var(--main-f-15-size) !important;
      font-weight: 600 !important;
      color: #535353 !important;
    }

    .ios {
      margin-left: 0 !important;
      width: var(--main-f-100-size) !important;
    }

    .imgs-playstore {
      margin-right: var(--main-f-70-size) !important;
      left: 0 !important;
      margin-top: 0 !important;
      padding-left: 0 !important;
    }

    .row-tittle-footer {
      margin-bottom: var(--main-f-50-size) !important;
      // border-top: 1px solid #cacaca !important;
    }

    .imgs-ios {
      width: var(--main-f-110-size) !important;
    }

    .playstore {
      margin-right: 0 !important;
      margin-left: 0 !important;
      width: var(--main-f-110-size) !important;
    }

    .solutions {
      width: 50% !important;
    }

    .creci-section {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 12px;
      line-height: 14px;
    }

    .homer-networks {
      height: var(--main-f-120-size) !important;
      .form-icons-homer {
        width: var(--main-f-300-size) !important;
        height: var(--main-f-40-size) !important;
        max-width: initial !important;

        .icons-linkedin {
          background: #ffffff !important;
          box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
          border-radius: var(--main-f-6-size) !important;
          padding-bottom: var(--main-f-5-size) !important;
          max-width: var(--main-f-24-size) !important;
          margin-left: var(--main-f-3-size) !important;
          margin-right: var(--main-f-7-size) !important;

          .icons-linkedin-imgs {
            width: var(--main-f-24-size) !important;
            margin-left: calc(-1 * var(--f-12-size)) !important;
            margin-top: var(--main-f-7-size) !important;
          }
        }
        .icons-instagram {
          background: #ffffff !important;
          box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
          border-radius: var(--main-f-6-size) !important;
          max-width: var(--main-f-24-size) !important;
          margin-right: var(--main-f-7-size) !important;

          .icons-instagram-imgs {
            width: var(--main-f-24-size) !important;
            margin-left: calc(-1 * var(--f-12-size)) !important;
            margin-top: var(--main-f-6-size) !important;
          }
        }
        .icons-facebook {
          background: #ffffff !important;
          box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
          border-radius: var(--main-f-6-size) !important;
          max-width: var(--main-f-24-size) !important;
          margin-right: var(--main-f-7-size) !important;

          .icons-facebook-imgs {
            width: var(--main-f-24-size) !important;
            margin-left: calc(-1 * var(--f-12-size)) !important;
            margin-top: var(--main-f-5-size) !important;
          }
        }
        .icons-youtube {
          background: #ffffff !important;
          box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
          border-radius: var(--main-f-6-size) !important;
          max-width: var(--main-f-24-size) !important;
          margin-right: var(--main-f-7-size) !important;

          .icons-youtube-imgs {
            width: var(--main-f-24-size) !important;
            margin-left: calc(-1 * var(--f-12-size)) !important;
            margin-top: var(--main-f-6-size) !important;
          }
        }
      }
    }
    .Col-post-details {
      h1 {
        width: var(--main-f-100-size) !important;
        font-size: var(--main-f-11-size) !important;
        font-weight: 300 !important;
        background: rgba(38, 38, 38, 1) !important;
        color: rgba(255, 255, 255, 1) !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
        border-radius: var(--main-f-30-size) !important;
        height: var(--main-f-24-size) !important;
        padding-left: var(--main-f-10-size) !important;
        padding-right: var(--main-f-16-size) !important;
        padding-top: var(--main-f-5-size) !important;
        padding-bottom: 0 !important;
        text-align: center !important;
        font-family: Raleway !important;

        strong {
          font: revert;
          margin-left: var(--main-f-4-size) !important;
        }

        .card-img {
          width: var(--main-f-9-size) !important;
          height: var(--main-f-12-size) !important;
        }
      }
      h2,
      h3 {
        font-size: var(--main-f-18-size) !important;
        line-height: 0.6 !important;
        margin-top: var(--main-f-15-size) !important;
      }

      h4 {
        font-size: var(--main-f-18-size) !important;
        line-height: 0.6 !important;
        margin-top: var(--main-f-20-size) !important;
        margin-bottom: var(--main-f-10-size) !important;
      }

      .p-class {
        margin-top: var(--main-f-4-size) !important;
        margin-bottom: var(--main-f-2-size) !important;
        font-size: var(--main-f-12-size) !important;
        padding-top: 0 !important;
        overflow: hidden !important;
        white-space: pre-wrap !important;
        width: 100% !important;
        & .max {
          height: auto !important;
        }
      }

      .a-text-decoration {
        text-decoration: underline !important;
      }

      .label-next {
        font-size: var(--main-f-10-size) !important;
        font-weight: 400 !important;
        color: #262626 !important;
        background: #f6f6f6 !important;
        border-radius: var(--main-f-30-size) !important;
        width: var(--main-f-130-size) !important;
        height: var(--main-f-23-size) !important;
        margin-top: var(--main-f-12-size) !important;
        padding-top: var(--main-f-4-size) !important;
        text-align: center !important;
        padding-left: var(--main-f-25-size) !important;
      }

      .label-sol {
        font-size: var(--main-f-10-size) !important;
        font-weight: 400 !important;
        color: #262626 !important;
        background: #f6f6f6 !important;
        border-radius: var(--main-f-30-size) !important;
        width: var(--main-f-130-size) !important;
        height: var(--main-f-23-size) !important;
        margin-top: var(--main-f-12-size) !important;
        padding-top: var(--main-f-4-size) !important;
        padding-left: var(--main-f-35-size) !important;
        text-align: center !important;
      }

      .homer-seleto-tag {
        vertical-align: inherit !important;
        margin-left: 5px !important;
      }

      .homer-select-span {
        font-weight: 600 !important;
        font-size: var(--main-f-11-size) !important;
        line-height: var(--main-f-16-size) !important;
        margin-left: 10px !important;
        position: absolute !important;
        bottom: 0 !important;
        margin-bottom: var(--main-f-11-size) !important;
        left: var(--main-f-70-size) !important;
      }

      .btn-btn-secondary {
        display: inherit !important;
        color: #d0222f !important;
        background: #ffffff !important;
        border: 1px solid #d0222f !important;
        border-radius: var(--main-f-30-size) !important;
        top: -0.3px !important;
      }

      .mb-3-text-muted {
        margin-top: var(--main-f-5-size) !important;
        font-size: var(--main-f-9-size) !important;
        line-height: var(--main-f-14-size) !important;
        width: var(--main-f-55-size) !important;
        height: var(--main-f-14-size) !important;
        color: #898989 !important;
        border-bottom: 0px solid !important;
      }

      .mb-3-text-muted::before {
        content: "";
        width: var(--main-f-500-size) !important;
        height: var(--main-f-1-size) !important;
        position: absolute !important;
        border: 1px solid #cacaca !important;
        margin-top: var(--main-f-15-size) !important;
      }
    }
  }

  .homer-seleto {
    font-family: Raleway !important;

    .switch {
      width: var(--main-f-135-size) !important;
      padding: var(--main-f-4-size) !important;
      height: var(--main-f-40-size) !important;
      align-items: center !important;
      text-align: center !important;
      margin: 0 !important;

      p {
        font-size: var(--main-f-18-size) !important;
      }

      button.btn.btn-outline-secondary.m-right {
        margin: 0px var(--main-f-5-size) 0px 0px !important;
        box-shadow: none !important;
        color: #535353 !important;
        font-size: var(--main-f-11-size) !important;
      }

      button.btn.btn-outline-secondary.m-left {
        margin: 0px 0px 0px var(--main-f-5-size) !important;
        box-shadow: none !important;
        color: #535353 !important;
        font-size: var(--main-f-11-size) !important;
      }

      button.is-rent.btn.btn-outline-secondary {
        background-color: rgba(222, 88, 95, 0.2) !important;
        box-shadow: none !important;
        color: #d0222f !important;
        font-size: var(--main-f-11-size) !important;
      }
    }

    .row-form-group {
      justify-content: space-evenly !important;
    }

    .card-inputs {
      width: var(--main-f-430-size) !important;
      height: var(--main-f-120-size) !important;
      justify-content: center !important;

      .form-group {
        margin: 0 !important;
        height: var(--main-f-60-size) !important;
        padding: var(--main-f-5-size) !important;
        min-width: var(--main-f-70-size) !important;
        padding-top: var(--main-f-15-size) !important;
        padding-left: 0 !important;

        .input-value {
          border-radius: 0 !important;
          border-color: rgb(202, 202, 202) !important;
          outline: 0 !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          width: var(--main-f-150-size) !important;
          height: var(--main-f-25-size) !important;
          margin-right: var(--main-f-5-size) !important;
          font-size: var(--main-f-13-size) !important;
          padding-left: var(--main-f-17-size) !important;
          margin-left: 0 !important;
        }

        .icons-pl {
          padding-left: var(--main-f-9-size) !important;
        }

        ::-webkit-input-placeholder {
          color: #bbbbbb !important;
          padding-left: 0 !important;
        }

        .autocomplete {
          font-size: var(--main-f-13-size) !important;
          border: 1px solid #cccccc !important;
          border-top: 0 !important;
          width: var(--main-f-430-size) !important;
          justify-content: left !important;
          padding-top: var(--main-f-10-size) !important;
          padding-bottom: var(--main-f-10-size) !important;
          padding-left: var(--main-f-18-size) !important;
        }
        .select-number {
          border-color: #cccccc !important;
          outline: 0 !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          border: none !important;
          width: var(--main-f-50-size) !important;
          height: var(--main-f-14-size) !important;
          font-size: var(--main-f-13-size) !important;
          margin-left: var(--main-f-15-size) !important;
        }

        select {
          border-color: #cccccc !important;
          outline: 0 !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          border: none !important;
          width: var(--main-f-106-size) !important;
          height: var(--main-f-17-size) !important;
          font-size: var(--main-f-13-size) !important;
          margin-left: var(--main-f-13-size) !important;
        }

        .margin-lf {
          margin-left: var(--main-f-22-size) !important;
        }

        input {
          border-radius: var(--main-f-10-size) !important;
          border-color: rgb(202, 202, 202) !important;
          outline: 0 !important;
          -webkit-box-shadow: none !important;
          box-shadow: none !important;
          height: var(--main-f-25-size) !important;
          width: var(--main-f-400-size) !important;
          padding-left: var(--main-f-19-size) !important;
          font-size: var(--main-f-13-size) !important;
          margin-left: 0 !important;
        }

        .border-right {
          border-right: 1px solid #c8ced3 !important;
        }

        .border-bottom {
          border-bottom: 1px solid #c8ced3 !important;
        }

        i {
          color: rgb(157, 157, 157) !important;
        }
      }
    }

    .text-stamp {
      margin-top: var(--main-f-95-size) !important;
      font-size: var(--main-f-17-size) !important;
      font-family: whatever-it-takes !important;
      margin-left: 0 !important;
      width: var(--main-f-150-size) !important;
    }

    .text-stamp2 {
      font-size: var(--main-f-14-size) !important;
      font-family: whatever-it-takes !important;
      margin-left: 0 !important;
      width: var(--main-f-150-size) !important;
    }
    .a-blue {
      color: #007aff !important;
      font-size: var(--main-f-12-size) !important;
      text-decoration: underline #007aff !important;
    }

    .p-subtitle {
      margin-bottom: var(--main-f-48-size) !important;
      font-size: var(--main-f-13-size) !important;
      text-align: center !important;
    }

    .demonstration {
      border-radius: var(--main-f-10-size) !important;
      width: var(--main-f-230-size) !important;
      height: var(--main-f-298-size) !important;
      margin-top: var(--main-f-75-size) !important;
      box-shadow: 0 0 1em rgb(157, 157, 157) !important;
      margin-right: 0 !important;
      margin-left: var(--main-f-30-size) !important;

      .text-stamp2 {
        font-size: var(--main-f-14-size) !important;
      }

      h6 {
        border-bottom: 1px solid !important;
        font-size: var(--main-f-9-size) !important;
        border-color: #9d9d9d !important;
        color: #909aa4 !important;
      }

      h5 {
        font-family: Raleway-Bold !important;
        font-size: var(--main-f-13-size) !important;
        margin-bottom: 0 !important;
      }

      .card-text {
        font-size: var(--main-f-11-size) !important;
      }

      .for-sale {
        font-family: Raleway-Bold !important;
        margin-bottom: 0 !important;
        color: rgb(39, 176, 98) !important;
      }

      .card-img-top {
        position: absolute !important;
        border-radius: var(--main-f-10-size) 0 0 !important;
      }

      .card-body {
        margin-top: var(--main-f-100-size) !important;
        border-radius: var(--main-f-7-size) !important;
        padding-bottom: 0 !important;
      }

      .arrow {
        z-index: 5 !important;
        position: absolute !important;
        right: var(--main-f-220-size) !important;
        transform: rotate(0deg) !important;
        width: var(--main-f-120-size) !important;
        bottom: var(--main-f-277-size) !important;
      }

      .margin-bt {
        margin-bottom: var(--main-f-15-size) !important;
      }
    }

    .box-seleto {
      background-color: #262626 !important;
      width: var(--main-f-170-size) !important;
      height: var(--main-f-46-size) !important;
      justify-content: center !important;
      align-content: center !important;
      margin-top: var(--main-f-125-size) !important;
      margin-left: 0 !important;

      h4 {
        font-size: var(--main-f-23-size) !important;
        line-height: 0.6 !important;
        margin-top: var(--main-f-30-size) !important;
        margin-bottom: var(--main-f-10-size) !important;
      }

      .bold {
        font-family: Raleway-Bold !important;
      }
    }

    .text-seleto {
      margin-left: 0 !important;
      margin-top: var(--main-f-17-size) !important;
      font-size: var(--main-f-19-size) !important;
      font-family: Raleway-Bold !important;
    }

    .text-seleto2 {
      margin-left: 0 !important;
      font-size: var(--main-f-13-size) !important;
    }

    .arrow {
      z-index: 5 !important;
      position: absolute !important;
      right: var(--main-f-220-size) !important;
      transform: rotate(0deg) !important;
      width: var(--main-f-130-size) !important;
      bottom: var(--main-f-271-size) !important;
    }

    .float {
      float: left !important;
      z-index: 4 !important;
      width: var(--main-f-95-size) !important;
      margin-left: var(--main-f-10-size) !important;
      margin-top: var(--main-f-12-size) !important;
      border-radius: var(--main-f-22-size) !important;
      color: white !important;
      text-align: center !important;

      label {
        vertical-align: middle !important;
        margin: 0 !important;
      }
    }

    .TagSeleto {
      width: 70px !important;
      left: var(--main-f-10-size) !important;
      top: var(--main-f-10-size) !important;
      position: absolute !important;
      z-index: 20 !important;
    }

    .introduce-seleto {
      justify-content: center !important;
      padding-bottom: var(--main-f-62-size) !important;
    }

    .profile {
      width: var(--main-f-100-size) !important;
      height: var(--main-f-100-size) !important;
      border-radius: var(--main-f-50-size) !important;
      margin-top: var(--main-f-62-size) !important;
    }

    .text-doubt {
      display: -webkit-inline-box !important;

      h4 {
        font-size: var(--main-f-13-size) !important;
        font-weight: 600 !important;
        font-family: Raleway !important;
        width: 72% !important;
      }

      .h4-underline {
        text-decoration: underline #d0222f var(--main-f-2-size) !important;
        width: var(--main-f-100-size) !important;
        margin-left: 0 !important;
      }
    }

    .input-group {
      label {
        font-size: var(--main-f-13-size) !important;
        font-family: Raleway !important;
        margin-top: var(--main-f-7-size) !important;
        margin-bottom: var(--main-f-5-size) !important;
        font-weight: 400 !important;
        color: #000000 !important;
      }

      input {
        border-radius: var(--main-f-4-size) !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        width: var(--main-f-358-size) !important;
        height: var(--main-f-30-size) !important;
      }

      ::-webkit-input-placeholder {
        font-family: Raleway-MediumItalic !important;
      }

      textarea {
        border-radius: var(--main-f-4-size) !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        height: var(--main-f-62-size) !important;
        width: var(--main-f-358-size) !important;
      }

      button {
        width: var(--main-f-155-size) !important;
        height: var(--main-f-30-size) !important;
        background: #ffffff !important;
        border: 1px solid #d0222f !important;
        box-sizing: border-box !important;
        border-radius: var(--main-f-8-size) !important;
        color: #d0222f !important;
        font-size: var(--main-f-13-size) !important;
        font-family: Raleway-Bold !important;
        margin-left: 0 !important;
      }

      button:disabled {
        background: #f3f3f3 !important;
        border-color: #f3f3f3 !important;
        color: #d0222f !important;

        .loading-red {
          margin-top: 4px !important;
          margin-bottom: 8px !important;
          margin-right: 10px !important;
        }

        .sendEmail {
          color: #a2a2a2 !important;
          vertical-align: top !important;
          margin-top: 0px !important;
        }
      }

      p {
        margin-top: var(--main-f-21-size) !important;
        font-size: var(--main-f-9-size) !important;
        font-weight: 400 !important;
      }
    }

    .step-by-step {
      h3 {
        font-size: var(--main-f-19-size) !important;
        font-family: Raleway !important;
        margin-top: var(--main-f-62-size) !important;
        text-align: center !important;
        margin-bottom: var(--main-f-50-size) !important;
      }

      .card {
        width: var(--main-f-40-size) !important;
        height: var(--main-f-40-size) !important;
        vertical-align: middle !important;
        border-radius: var(--main-f-50-size) !important;
        text-align: center !important;
        justify-content: center !important;
        border-color: #d0222f !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      h6 {
        vertical-align: middle !important;
        margin: 0 !important;
        font-size: var(--main-f-15-size) !important;
        color: #d0222f !important;
        font-weight: 700 !important;
      }

      h2 {
        font-size: var(--main-f-16-size) !important;
        font-weight: 600 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: start !important;
      }

      h4 {
        width: auto !important;
        font-size: var(--main-f-11-size) !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        text-align: start !important;
      }
    }

    .salmon-button {
      background-color: #d0222f !important;
      border-color: #d0222f !important;
      color: #ffffff !important;
      width: var(--main-f-155-size) !important;
      height: var(--main-f-30-size) !important;
      border-radius: var(--main-f-24-size) !important;
      font-size: var(--main-f-12-size) !important;
      font-weight: 700 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .white-button {
      background-color: #ffffff !important;
      border-color: #d0222f !important;
      color: #d0222f !important;
      width: var(--main-f-155-size) !important;
      height: var(--main-f-30-size) !important;
      vertical-align: middle !important;
      border-radius: var(--main-f-50-size) !important;
      text-align: center !important;
      justify-content: center !important;
      border-color: #d0222f !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .btn-white-card {
      background-color: #ffffff !important;
      border-color: #d0222f !important;
      color: #d0222f !important;
      width: var(--main-f-155-size) !important;
      height: var(--main-f-30-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-12-size) !important;
      margin-top: var(--main-f-7-size) !important;
    }

    h2 {
      font-size: var(--main-f-16-size) !important;
      font-weight: 600 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      text-align: start !important;
    }

    .salmon-button {
      background-color: #d0222f !important;
      border-color: #d0222f !important;
      color: #ffffff !important;
      width: var(--main-f-155-size) !important;
      height: var(--main-f-30-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-12-size) !important;
      font-family: Raleway-Bold !important;
      margin-top: var(--main-f-40-size) !important;
      margin-bottom: var(--main-f-62-size) !important;
      padding-right: 24px !important;
      padding-left: 24px !important;
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }

    .btn-salmon-seleto {
      background-color: #d0222f !important;
      border-color: #d0222f !important;
      color: #ffffff !important;
      min-width: var(--main-f-220-size) !important;
      width: auto !important;
      height: var(--main-f-40-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-13-size) !important;
      font-family: Raleway-Bold !important;
      margin-bottom: var(--main-f-24-size) !important;
      padding-right: 24px !important;
      padding-left: 24px !important;
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }

    .white-button {
      background-color: #ffffff !important;
      border-color: #d0222f !important;
      color: #d0222f !important;
      min-width: var(--main-f-155-size) !important;
      width: auto !important;
      height: var(--main-f-30-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-12-size) !important;
      margin-top: var(--main-f-40-size) !important;
      margin-bottom: var(--main-f-62-size) !important;
      padding-right: 24px !important;
      padding-left: 24px !important;
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }

    .btn-white-card {
      background-color: #ffffff !important;
      border-color: #d0222f !important;
      color: #d0222f !important;
      width: var(--main-f-155-size) !important;
      height: var(--main-f-30-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-12-size) !important;
      margin-top: var(--main-f-7-size) !important;
      margin-bottom: var(--main-f-10-size) !important;
      padding-right: 24px !important;
      padding-left: 24px !important;
      padding-top: 9px !important;
      padding-bottom: 9px !important;
    }

    .h2-title {
      font-size: 24px !important;
      text-align: center !important;
    }

    .h2-mini-title {
      font-size: var(--main-f-14-size) !important;
      text-align: center !important;
      font-family: Raleway !important;
      font-weight: 400 !important;
    }

    .col-bottom-80 {
      margin-bottom: var(--main-f-30-size) !important;
    }

    .blue-text {
      color: #007aff !important;
      text-decoration: underline !important;
    }

    .align-left {
      text-align: left !important;
    }

    .align-right {
      text-align: right !important;
    }
    .align-center {
      text-align: center !important;
    }
  }

  .appfoot {
    border-top: 1px solid #cacaca !important;
    padding-top: var(--main-f-10-size) !important;

    .row-footer {
      justify-content: space-around !important;
      width: var(--main-f-165-size) !important;
    }
  }

  .is-desktop {
    display: inherit !important;
  }

  .modal-body {
    padding: auto !important;
    margin: auto !important;
    text-align: center !important;
    margin-top: var(--main-f-20-size) !important;
    .container-close {
      text-align: end !important;
      margin-bottom: var(--main-f-9-size) !important;

      .buttonClose {
        background-color: #ffffff !important;
        border-color: #ffffff !important;
      }
    }

    .content-modal-success {
      text-align: start !important;
      margin-top: var(--main-f-50-size);
      margin-left: var(--main-f-20-size);
      margin-bottom: var(--main-f-120-size);

      .label-success {
        font-family: Raleway !important;
        font-style: normal !important;
        font-weight: bold !important;
        font-size: 24px !important;
        line-height: 28px !important;
      }

      .label-description {
        font-family: Raleway !important;
        font-style: normal !important;
        font-size: 16px !important;
        margin-right: var(--main-f-30-size) !important;
      }

      img {
        margin-left: 0 !important;
      }
      .button-back {
        width: 280px !important;
        height: 50px !important;
        background: #d0222f;
        border-radius: 10px !important;
        color: #ffffff !important;
        font-family: Raleway !important;
        font-weight: 700 !important;
        margin-left: 0 !important;
        border: none !important;
        font-size: var(--main-f-12-size) !important;
      }
    }

    .modal-content {
      width: var(--main-f-310-size) !important;
      border-radius: var(--main-f-7-size) !important;
    }

    form {
      padding: var(--main-f-7-size) !important;
      justify-content: center !important;

      label {
        font-size: var(--main-f-12-size) !important;
        font-family: Raleway-Bold !important;
        margin-top: var(--main-f-7-size) !important;
        margin-bottom: var(--main-f-7-size) !important;
        display: flex !important;
        margin-left: 0 !important;
      }

      input {
        width: var(--main-f-277-size) !important;
        height: var(--main-f-30-size) !important;
        border-radius: var(--main-f-7-size) !important;
        border-color: rgb(202, 202, 202) !important;
      }

      textarea {
        width: var(--main-f-277-size) !important;
        height: var(--main-f-62-size) !important;
        border-radius: var(--main-f-7-size) !important;
        border-color: rgb(202, 202, 202) !important;
        resize: none !important;
      }

      button {
        width: var(--main-f-277-size) !important;
        height: var(--main-f-30-size) !important;
        border-radius: var(--main-f-7-size) !important;
        font-size: var(--main-f-12-size) !important;
        font-family: Raleway-Bold !important;
        border-color: #d0222f !important;
        color: #ffffff !important;
        background-color: #d0222f !important;
        margin-top: var(--main-f-10-size) !important;
        margin-bottom: var(--main-f-7-size) !important;

        label {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
          margin-left: var(--main-f-10-size) !important;
        }
      }
      button:disabled {
        background: #f3f3f3 !important;
        border-color: #f3f3f3 !important;
        color: #d0222f !important;

        .loading-red {
          margin-top: 4px !important;
        }

        .sendEmail {
          color: #a2a2a2 !important;
        }
      }

      .row-modal {
        display: flex !important;
        text-align: initial !important;
        padding: 0 !important;

        p {
          width: var(--main-f-271-size) !important;
          font-size: var(--main-f-9-size) !important;
        }

        a {
          color: #d0222f !important;
          text-decoration: underline !important;
          font-size: var(--main-f-9-size) !important;
        }
      }

      .padding-modal {
        padding: var(--main-f-7-size) !important;
      }
    }

    .exit {
      width: var(--main-f-23-size) !important;
      height: var(--main-f-23-size) !important;
      padding: 0 !important;
      border-radius: var(--main-f-20-size) !important;
      background-color: #d0222f !important;
      border-color: #d0222f !important;
      color: #ffffff !important;
      font-size: var(--main-f-15-size) !important;
      bottom: var(--main-f-390-size) !important;
      left: var(--main-f-285-size) !important;
      position: absolute !important;
    }
  }

  .results {
    width: 100vw !important;
    max-width: 100vw !important;
    min-height: auto !important;
    overflow: overlay !important;
    padding: var(--main-f-9-size) !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    margin-top: var(--main-f-38-size) !important;
    background-color: #ffffff !important;
    min-height: auto !important;
    font-family: Raleway !important;

    .row-bread {
      justify-content: flex-end !important;
    }

    .card-center {
      text-align: -webkit-center !important;
    }

    .fake-post {
      padding: var(--main-f-30-size) var(--main-f-20-size) var(--main-f-27-size) !important;
      cursor: auto !important;

      .row {
        margin: 0 !important;

        .col {
          padding: 0 !important;

          .fake-image {
            margin-bottom: var(--main-f-12-size) !important;
          }

          .title {
            width: 95% !important;
            font-size: var(--main-f-14-size) !important;
            font-weight: 700 !important;
            margin: 0 !important;
            margin-bottom: var(--main-f-7-size) !important;
            text-align: initial !important;
            line-height: var(--main-f-17-size) !important;
          }

          .text-fake {
            font-size: var(--main-f-11-size) !important;
            font-weight: 400 !important;
            margin-bottom: var(--main-f-15-size) !important;
            text-align: initial !important;
          }

          .button-fake {
            width: 100% !important;
            font-size: var(--main-f-12-size) !important;
            font-weight: 600 !important;
            color: #d0222f !important;
            background-color: #ffffff !important;
            border: 1px solid #d0222f !important;
            border-radius: var(--main-f-9-size) !important;
          }
        }
      }
    }

    .card {
      max-width: 277px !important;
      max-height: 430px !important;
      margin: 0px !important;
      border-radius: var(--main-f-10-size) !important;

      .card-img-top {
        right: 0 !important;
        position: absolute !important;
        bottom: var(--main-f-185-size) !important;
        border-radius: var(--main-f-10-size) var(--main-f-10-size) 0 0 !important;
        min-height: var(--main-f-138-size) !important;
        max-height: var(--main-f-138-size) !important;
        object-fit: contain !important;
        background-color: #f4f4f4 !important;

        &.img-contains {
          object-fit: contain !important;
        }

        &.img-cover {
          object-fit: cover !important;
        }
      }

      .card-body {
        padding: var(--main-f-7-size) !important;
        border-radius: var(--main-f-7-size) !important;
        padding-bottom: 0 !important;

        .inline {
          margin-top: var(--main-f-138-size) !important;
        }
      }

      .btn-white-card {
        width: var(--main-f-190-size) !important;
        font-family: Raleway-Bold !important;
      }

      .icons {
        width: auto !important;
        margin-right: var(--main-f-4-size) !important;
      }

      .margin-right {
        margin-right: var(--main-f-5-size) !important;
      }
    }

    .inline {
      display: flex !important;
      flex-direction: row !important;
      margin-top: var(--main-f-138-size) !important;
    }

    .TagSeleto {
      width: 70px !important;
      left: var(--main-f-10-size) !important;
      top: var(--main-f-10-size) !important;
      position: absolute !important;
      z-index: 1 !important;
    }

    .container-margin-top {
      margin-top: 0 !important;
      width: 0 !important;
      height: 0 !important;
      position: absolute !important;
    }

    .posts-search {
      overflow: hidden;
      float: left;
      width: 615px !important;
      max-width: 615px !important;
      min-width: 615px !important;
      padding-right: 20px !important;
      padding-left: 0px !important;
      height: calc(100vh - var(--main-f-120-size)) !important;

      .center-cards-search {
        justify-content: space-between !important;
        margin: auto !important;
        padding-bottom: var(--main-f-57-size);
        overflow-y: auto;
        width: 615px !important;
        max-width: 615px !important;
        min-width: 615px !important;
        max-height: calc(100vh - var(--main-f-120-size)) !important;
        padding-right: 15px !important;
      }

      .post {
        margin-top: 0 !important;
        margin-bottom: 5px !important;
        padding: 5px !important;
        max-width: 48.666667% !important;
        flex: 0 0 36.666667% !important;
      }
    }

    .posts {
      overflow: hidden;
      float: left;
      width: 615px !important;
      max-width: 615px !important;
      min-width: 615px !important;
      padding-right: 20px !important;
      padding-left: 0px !important;
      height: calc(100vh - var(--main-f-60-size)) !important;

      .center-cards {
        justify-content: space-between !important;
        margin: auto !important;
        padding-bottom: var(--main-f-57-size);
        overflow-y: auto;
        width: 615px !important;
        max-width: 615px !important;
        min-width: 615px !important;
        max-height: calc(100vh - var(--main-f-60-size)) !important;
        padding-right: 15px !important;
      }

      .post {
        margin-top: 0 !important;
        margin-bottom: 5px !important;
        padding: 5px !important;
        max-width: 48.666667% !important;
        flex: 0 0 36.666667% !important;
      }
    }

    .no-limit {
      width: 100vw !important;
      max-width: 100vw !important;
      overflow: hidden !important;

      .no-margin {
        margin-right: -50px;
        margin-left: -15px;
      }
    }

    .col-maps-no-search {
      padding-left: 0 !important;
      padding-right: 0 !important;
      float: left;
      width: calc(100% - 650px) !important;
      height: calc(100vh - var(--main-f-60-size)) !important;
    }

    .col-map {
      padding-left: 0 !important;
      padding-right: 0 !important;
      float: left;
      width: calc(100% - 650px) !important;
      height: calc(100vh - var(--main-f-120-size)) !important;

      .maps {
        position: relative !important;
        overflow: hidden !important;
        width: 100% !important;
        right: 0 !important;
        height: 100% !important;

        .pointers {
          padding: 0 !important;
          color: #ffffff !important;
          background: #d0222f !important;
          border-radius: 50% !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          cursor: pointer !important;
          font-family: Raleway-Bold !important;
          font-size: 16px !important;
          border-color: #d0222f !important;
        }
      }
    }

    .maps {
      position: relative !important;
      overflow: hidden !important;
      width: 100% !important;
      right: 0 !important;
      min-height: 0 !important;
      height: 100% !important;

      .pointers {
        padding: 0 !important;
        color: #ffffff !important;
        background: #d0222f !important;
        border-radius: 50% !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        cursor: pointer !important;
        font-family: Raleway-Bold !important;
        font-size: 16px !important;
        border-color: #d0222f !important;
      }
    }

    .back {
      padding-top: var(--main-f-14-size) !important;
      padding-bottom: var(--main-f-20-size) !important;
      border-bottom: 1px solid #cacaca !important;

      .arrow {
        margin-left: var(--main-f-20-size) !important;
      }

      .row-flex-initial {
        justify-content: initial !important;
        padding-right: var(--main-f-45-size) !important;
      }

      h4 {
        font-size: var(--main-f-12-size) !important;
        margin-left: var(--main-f-4-size) !important;
        margin-bottom: 0 !important;
        cursor: pointer !important;
        font-family: Raleway-Bold !important;
      }

      h4:hover {
        text-decoration: underline !important;
      }

      .position-initial {
        align-self: center !important;
      }

      .location {
        width: var(--main-f-298-size) !important;
        height: var(--main-f-30-size) !important;
        border: 1px solid #cacaca !important;
        border-radius: var(--main-f-8-size) !important;
        margin-right: var(--main-f-12-size) !important;
        font-size: var(--main-f-12-size) !important;
        padding-left: var(--main-f-10-size) !important;
        margin-left: var(--main-f-23-size) !important;
        padding-right: var(--main-f-30-size) !important;
        margin-top: 6px !important;
      }

      .autocomplete {
        font-size: var(--main-f-12-size) !important;
        border: 1px solid #cccccc !important;
        border-top: 0 !important;
        width: var(--main-f-288-size) !important;
        justify-content: left !important;
        padding-top: var(--main-f-10-size) !important;
        padding-bottom: var(--main-f-10-size) !important;
        padding-left: var(--main-f-10-size) !important;
      }

      ::-webkit-input-placeholder {
        color: #262626 !important;
      }

      .type {
        width: var(--main-f-138-size) !important;
        height: var(--main-f-30-size) !important;
        border: 1px solid #cacaca !important;
        border-radius: var(--main-f-8-size) !important;
        margin-right: var(--main-f-12-size) !important;
        background: url(../../icons/arrowSelect.svg) no-repeat right white !important;
        -webkit-appearance: none !important;
        background-position-x: var(--main-f-113-size) !important;
        color: #535353 !important;
        font-weight: 700 !important;
        font-size: var(--main-f-12-size) !important;
      }

      .bedrooms {
        width: var(--main-f-95-size) !important;
        height: var(--main-f-30-size) !important;
        border: 1px solid #cacaca !important;
        border-radius: var(--main-f-8-size) !important;
        margin-right: var(--main-f-12-size) !important;
        background: url(../../icons/arrowSelect.svg) no-repeat right white !important;
        -webkit-appearance: none !important;
        background-position-x: var(--main-f-75-size) !important;
        color: #535353 !important;
        font-weight: 700 !important;
        font-size: var(--main-f-12-size) !important;
      }

      button {
        width: var(--main-f-110-size) !important;
        height: var(--main-f-30-size) !important;
        font-size: var(--main-f-12-size) !important;
        border-radius: var(--main-f-8-size) !important;
        color: #ffffff !important;
        background-color: #d0222f !important;
        border-color: #d0222f !important;
        margin-right: 0 !important;
        font-weight: 700 !important;
      }

      button:disabled {
        background-color: #c4c4c4 !important;
        border-color: #c4c4c4 !important;
      }

      .button-img {
        float: right !important;
        margin-top: var(--main-f-4-size) !important;
      }

      .icon {
        width: var(--main-f-30-size) !important;
        background-color: transparent !important;
        border: none !important;
        position: absolute !important;
        margin-left: var(--main-f-288-size) !important;
        padding-bottom: var(--main-f-7-size) !important;
      }
    }

    p {
      font-size: var(--main-f-11-size) !important;
      font-weight: 400 !important;
      margin-bottom: 0 !important;
      font-family: Raleway !important;

      span:last-child {
        color: black;
        font-weight: 700 !important;
      }
    }

    .card-title {
      padding: var(--main-f-10-size) !important;
      padding-bottom: 0 !important;

      .offset-md-12 {
        p {
          font-size: var(--main-f-10-size) !important;
          font-family: Raleway !important;
          font-weight: 600 !important;
          margin-bottom: 0 !important;
          margin-top: var(--main-f-13-size) !important;
          margin-bottom: var(--main-f-6-size) !important;
          float: left;
        }
      }

      .offset-md-5 {
        margin-left: 41.1% !important;
      }

      h3 {
        font-size: var(--main-f-12-size) !important;
        font-family: Raleway-Bold !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        margin-right: 0 !important;
      }

      .float-revealing {
        width: var(--main-f-138-size) !important;
        height: var(--main-f-30-size) !important;
        position: unset !important;
        margin-top: 0 !important;
        background-color: #ffffff !important;
        margin-right: 10px !important;

        select {
          width: var(--main-f-138-size) !important;
          height: var(--main-f-30-size) !important;
          border: 1px solid #cacaca !important;
          border-radius: var(--main-f-8-size) !important;
          margin-left: 0 !important;
          font-size: var(--main-f-12-size) !important;
          margin-top: 0 !important;
          background: url(../../icons/arrowSelect.svg) no-repeat right white !important;
          -webkit-appearance: none !important;
          background-position-x: var(--main-f-113-size) !important;
          color: #535353 !important;
          font-family: Raleway-Bold !important;
          margin-bottom: 0 !important;
        }
      }
    }

    .align-self {
      align-self: center !important;
      .row {
        justify-content: space-between !important;
        align-items: center !important;
        height: var(--main-f-40-size) !important;
        width: var(--main-f-450-size) !important;
        margin: auto !important;
      }
    }

    .load-more {
      margin-top: var(--main-f-12-size) !important;

      button {
        width: var(--main-f-185-size) !important;
        height: var(--main-f-30-size) !important;
        font-size: var(--main-f-12-size) !important;
        border-radius: var(--main-f-23-size) !important;
        color: #ffffff !important;
        background-color: #d0222f !important;
        border-color: #d0222f !important;
        position: unset !important;
      }

      img {
        float: right !important;
        margin-top: var(--main-f-4-size) !important;
      }
    }

    .border-bottom {
      width: 500vw !important;
      margin-left: -4000px !important;
      border-bottom: 1px solid #cacaca !important;
    }
  }

  .app:has(.app-enterprise-details) {
    .header {
      height: var(--main-f-55-size) !important;
    }
  }

  .enterprise-details {
    .header {
      height: var(--main-f-70-size) !important;

      h5 {
        font-size: var(--main-f-12-size) !important;
      }

      .avatar {
        width: var(--main-f-38-size) !important;
        height: var(--main-f-38-size) !important;
      }

      h6 {
        font-size: var(--main-f-11-size) !important;
        margin-left: var(--main-f-10-size) !important;
      }

      p {
        font-size: var(--main-f-9-size) !important;
        margin-left: var(--main-f-10-size) !important;
      }
    }
  }

  .app-enterprise-details {
    min-height: 93.8vh !important;
    margin-top: var(--main-f-55-size) !important;
    padding-bottom: var(--main-f-80-size) !important;
    background-color: white !important;
    font-family: Raleway, Sans-serif !important;

    button {
      font-size: var(--main-f-12-size) !important;
      border-radius: var(--main-f-8-size) !important;
    }

    .container {
      max-width: inherit !important;
      padding: 0 var(--main-f-8-size) !important;

      .row {
        margin: 0 !important;
      }

      .container-texts {
        padding-left: var(--main-f-55-size) !important;
      }

      .row-image {
        max-width: 107% !important;
        margin: 0px calc(-1 * var(--main-f-16-size)) !important;
      }

      .container-image {
        width: 100% !important;
        height: var(--main-f-215-size) !important;

        .carousel-root {
          max-width: 100% !important;
          width: 100% !important;
          height: var(--main-f-215-size) !important;

          .carousel-container {
            width: 100% !important;
            height: var(--main-f-215-size) !important;
            -webkit-box-pack: center !important;
            display: flex !important;
            flex-direction: row !important;
            align-items: center !important;
          }

          .slide {
            text-align: unset !important;

            .container-image {
              padding: 0 !important;
              cursor: pointer !important;

              .img-carousel {
                height: var(--main-f-215-size) !important;

                &.img-cover {
                  object-fit: cover !important;
                }

                &.img-contain {
                  object-fit: contain !important;
                }
              }
            }
          }

          .arrow-next-post-detail {
            position: absolute !important;
            top: var(--main-f-100-size) !important;
            right: var(--main-f-40-size) !important;
            cursor: pointer !important;
          }

          .arrow-prev-post-detail {
            position: absolute !important;
            top: var(--main-f-100-size) !important;
            left: var(--main-f-40-size) !important;
            cursor: pointer !important;
            z-index: 1 !important;
          }
        }
      }

      .row-button-postdetail {
        margin-top: var(--main-f-21-size) !important;
        margin-bottom: var(--main-f-55-size) !important;

        button {
          display: flex !important;
          align-items: center !important;
          padding: var(--main-f-7-size) var(--main-f-10-size) !important;

          img {
            margin-right: var(--main-f-9-size) !important;
          }
        }
      }

      .enterprise {
        line-height: var(--main-f-20-size) !important;
        font-size: var(--main-f-18-size) !important;
        font-weight: 600 !important;
        margin-bottom: var(--main-f-6-size) !important;
      }

      .location {
        font-size: var(--main-f-15-size) !important;
        font-weight: 400 !important;
        margin-bottom: var(--main-f-10-size) !important;
      }

      .updated-at {
        font-size: var(--main-f-9-size) !important;
        margin-bottom: var(--main-f-25-size) !important;
      }

      h3 {
        font-size: var(--main-f-18-size) !important;
        font-weight: 600 !important;
        margin: 0 0 var(--main-f-23-size) !important;
      }

      .container-info {
        display: flex !important;
        margin-right: auto !important;
        margin-bottom: var(--main-f-23-size) !important;
        padding: 0 !important;

        img {
          max-width: var(--main-f-17-size) !important;
          max-height: var(--main-f-17-size) !important;
          margin-right: var(--main-f-13-size) !important;
        }

        p {
          font-size: var(--main-f-11-size) !important;
          margin: 0 !important;
          color: #515151 !important;
        }
      }

      .container-info-immobile {
        padding: 0 !important;
        display: flex !important;
        margin-bottom: var(--main-f-23-size) !important;

        img {
          max-width: var(--main-f-17-size) !important;
          max-height: var(--main-f-17-size) !important;
          margin-right: var(--main-f-13-size) !important;
        }

        p {
          font-size: var(--main-f-11-size) !important;
          margin: 0 !important;
          color: #515151 !important;
        }
      }

      .row-characteristcs {
        margin-bottom: var(--main-f-23-size) !important;
      }

      .location-map {
        height: var(--main-f-145-size) !important;
        margin-bottom: var(--main-f-23-size) !important;
        border-radius: var(--main-f-8-size) !important;
        overflow: hidden !important;
      }

      .row-description {
        p {
          font-size: var(--main-f-11-size) !important;
          color: #262626 !important;
          margin-top: 0 !important;
        }
      }

      .row-information {
        margin-bottom: var(--main-f-23-size) !important;
        padding: var(--main-f-18-size) !important;
        background-color: #f4f4f4 !important;
        border-radius: var(--main-f-8-size) !important;

        p {
          font-size: var(--main-f-9-size) !important;
          margin-bottom: 0 !important;
        }
      }

      .col-card {
        margin-top: calc(-1 * var(--main-f-25-size)) !important;
        padding-right: var(--main-f-35-size) !important;

        .card {
          padding: var(--main-f-18-size) !important;
          border-radius: var(--main-f-8-size) !important;
          border-color: #d4d4d4 !important;

          label {
            font-size: var(--main-f-11-size) !important;
            margin-bottom: var(--main-f-7-size) !important;
          }

          h5 {
            font-size: var(--main-f-16-size) !important;
            font-weight: 600 !important;
            margin-bottom: var(--main-f-12-size) !important;
            color: #262626 !important;
          }

          .dashed-line {
            border-bottom: var(--main-f-1-size) dashed #cacaca !important;
          }

          .user-information {
            margin: var(--main-f-12-size) 0 var(--main-f-18-size) !important;

            .container-img {
              margin-right: var(--main-f-7-size) !important;

              img {
                width: var(--main-f-30-size) !important;
                height: var(--main-f-30-size) !important;
                border-radius: 100% !important;
              }
            }

            .col {
              padding: 0 !important;
              display: flex !important;
              flex-direction: column !important;
              align-self: center !important;

              span {
                line-height: var(--main-f-11-size) !important;
                font-size: var(--main-f-9-size) !important;
                margin-bottom: var(--main-f-2-size) !important;
              }

              p {
                line-height: var(--main-f-13-size) !important;
                font-size: var(--main-f-11-size) !important;
                font-weight: 600 !important;
                margin-bottom: 0 !important;
              }
            }
          }

          .container-button {
            margin-top: var(--main-f-12-size) !important;
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;

            p {
              font-size: var(--main-f-12-size) !important;
              margin: 0 !important;
            }

            button {
              padding: var(--main-f-6-size) var(--main-f-8-size) !important;
            }
          }
        }
      }
    }
  }

  .modal:has(.modal-forms) {
    display: block !important;
  }

  .modal-forms {
    display: flex !important;
    justify-content: center !important;
    margin: 1.75rem auto !important;

    .modal-content {
      width: 81% !important;
      padding: var(--main-f-22-size) var(--main-f-34-size) var(--main-f-50-size) !important;
      border-radius: var(--main-f-14-size) !important;

      .row-close {
        margin-bottom: var(--main-f-6-size) !important;

        .col {
          display: flex !important;
          justify-content: flex-end !important;

          .button-close {
            padding: 0 !important;
            opacity: 0.3 !important;
            background-color: transparent !important;
            border-color: transparent !important;
          }
        }
      }

      h2 {
        font-size: var(--main-f-19-size) !important;
        font-weight: 600 !important;
        color: #262626 !important;
        margin-bottom: var(--main-f-14-size) !important;
      }

      label {
        font-size: var(--main-f-9-size) !important;
        margin: 0 !important;
        color: #535353 !important;
        position: absolute !important;
        top: var(--main-f-5-size) !important;
        left: var(--main-f-20-size) !important;
      }

      input {
        width: 100% !important;
        height: var(--main-f-40-size) !important;
        border: var(--main-f-1-size) solid #262626 !important;
        margin-bottom: var(--main-f-14-size) !important;
        padding: var(--main-f-15-size) var(--main-f-19-size) var(--main-f-4-size) var(--main-f-9-size) !important;
        border-radius: var(--main-f-8-size) !important;
      }

      button {
        font-size: var(--main-f-12-size) !important;
      }
    }
  }

  .react-simple-image-viewer__modal {
    &.react-carousel-image-viewer {
      .react-simple-image-viewer__close {
        z-index: 1 !important;
      }

      .carousel-root {
        max-width: 100% !important;
        width: 100% !important;
        height: 100% !important;
        display: flex !important;

        .carousel-slider {
          display: flex !important;
          align-items: center !important;
        }

        .slider-wrapper {
          display: flex !important;
          height: 100% !important;

          .row {
            width: 100% !important;

            .col {
              padding: 0 !important;
            }
          }

          .container-image {
            margin: var(--main-f-3-size) !important;
          }
        }
      }

      .arrow-next-image-viewer {
        position: absolute !important;
        right: var(--main-f-5-size) !important;
        cursor: pointer !important;

        svg {
          height: var(--main-f-90-size) !important;
          width: var(--main-f-45-size) !important;
          color: #ffffff !important;
        }
      }

      .arrow-prev-image-viewer {
        position: absolute !important;
        left: var(--main-f-5-size) !important;
        cursor: pointer !important;
        z-index: 1 !important;

        svg {
          height: var(--main-f-90-size) !important;
          width: var(--main-f-45-size) !important;
          color: #ffffff !important;
        }
      }
    }
  }

  .container-card-enterprise {
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;

    .card-enterprise {
      width: 96% !important;
      height: auto !important;
      border-radius: var(--main-f-9-size) !important;
      margin-bottom: var(--main-f-25-size) !important;
      overflow: initial !important;
      cursor: pointer !important;

      .container-image {
        width: 100% !important;
        height: var(--main-f-130-size) !important;
        min-height: var(--main-f-130-size) !important;
        background-color: #f6f6f6 !important;
        border-radius: var(--main-f-8-size) var(--main-f-8-size) 0 0 !important;
        padding: 0 !important;
        overflow: hidden !important;

        .img-carousel {
          max-width: 100% !important;
          width: 100% !important;
          height: var(--main-f-130-size) !important;
          border-radius: 0 !important;
          margin: 0 !important;
          object-fit: contain !important;
          object-position: center;
          background-color: #f4f4f4 !important;

          &.img-contains {
            object-fit: contain !important;
          }

          &.img-cover {
            object-fit: cover !important;
          }
        }
      }

      .card-body {
        padding: var(--main-f-8-size) var(--main-f-13-size) var(--main-f-15-size) !important;

        .row {
          margin: 0 !important;
        }

        .container-name-broker {
          padding: 0 !important;

          p {
            font-size: var(--main-f-8-size) !important;
            margin: 0 !important;
          }

          h5 {
            font-size: var(--main-f-11-size) !important;
            font-weight: 600 !important;
            overflow: hidden !important;
            white-space: nowrap !important;
            text-overflow: ellipsis !important;
          }
        }

        .container-immobile-location {
          p {
            height: var(--main-f-24-size) !important;
            line-height: var(--main-f-12-size) !important;
            font-size: var(--main-f-10-size) !important;
            font-weight: 600 !important;
            color: #262626 !important;
            margin: 0 !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
        }

        .row-value {
          margin-top: var(--main-f-8-size) !important;
          margin-bottom: 0 !important;
          display: block !important;

          span {
            font-size: var(--main-f-8-size) !important;
            color: #262626 !important;
          }

          .text-value {
            font-size: var(--main-f-12-size) !important;
            line-height: var(--main-f-13-size) !important;
            font-weight: 700 !important;
            margin: 0 !important;
          }
        }

        .container-comission {
          margin-bottom: auto !important;

          p {
            font-size: var(--main-f-9-size) !important;
            font-style: italic !important;
            margin: 0 !important;
            color: #262626 !important;
          }
        }

        .container-icons {
          margin-top: var(--main-f-7-size) !important;
          margin-bottom: var(--main-f-25-size) !important;

          div {
            display: flex !important;

            &:nth-child(2) {
              width: 50% !important;
            }

            img {
              margin-right: var(--main-f-4-size) !important;
            }

            p {
              font-size: var(--main-f-11-size) !important;
              margin: 0 var(--main-f-18-size) 0 0 !important;
            }
          }
        }

        .row-button {
          button {
            font-size: var(--main-f-12-size) !important;
            margin-top: var(--main-f-10-size) !important;
          }
        }
      }
    }
  }

  .new-constructions {
    max-height: 100vh !important;
    overflow: hidden !important;

    .header {
      height: var(--main-f-70-size) !important;

      h5 {
        font-size: var(--main-f-12-size) !important;
      }

      .avatar {
        width: var(--main-f-38-size) !important;
        height: var(--main-f-38-size) !important;
      }

      h6 {
        font-size: var(--main-f-11-size) !important;
        margin-left: var(--main-f-10-size) !important;
      }

      p {
        font-size: var(--main-f-9-size) !important;
        margin-left: var(--main-f-10-size) !important;
      }
    }
  }

  .container-filter-enterprise {
    width: 100% !important;
    height: var(--main-f-55-size) !important;
    padding: 0 !important;
    background-color: #ffffff !important;
    box-shadow: 0px var(--main-f-4-size) var(--main-f-4-size) rgba(0, 0, 0, 0.1) !important;
    position: fixed !important;
    top: 0 !important;
    left: var(--main-f-15-size) !important;
    z-index: 10 !important;

    button {
      width: 100% !important;
      font-size: var(--main-f-17-size) !important;
      padding: var(--main-f-8-size) var(--main-f-22-size) !important;
    }
  }

  .app-new-construction {
    min-height: 100% !important;
    margin-top: var(--main-f-70-size) !important;
    background-color: white !important;
    font-family: Raleway, Sans-serif !important;
    flex: 1 !important;
    overflow: overlay !important;

    .container-page {
      margin: 0 !important;

      .side-filter {
        height: -webkit-fill-available !important;
        padding: var(--main-f-35-size) var(--main-f-14-size) !important;
        box-shadow: 0px var(--main-f-4-size) var(--main-f-4-size) 0px #00000040 !important;
        position: fixed !important;
        overflow-y: auto !important;

        .col {
          > div {
            width: 100% !important;
            display: flex !important;
            justify-content: center !important;
          }
        }

        h2 {
          font-size: var(--main-f-15-size) !important;
          font-weight: 600 !important;
          margin-bottom: var(--main-f-23-size) !important;
        }

        .row-input-filter {
          margin-bottom: var(--main-f-14-size) !important;

          .loading-input-filter {
            span {
                width: 87% !important;
                display: flex !important;
                justify-content: center !important;
            }
          }

          .col {
            display: flex !important;
            justify-content: center !important;
          }

          select {
            appearance: none !important;
            background: url(../../icons/arrowSelectBold.svg) no-repeat right white !important;
            background-position-x: 90% !important;
          }

          input,
          select {
            width: 75% !important;
            height: var(--main-f-40-size) !important;
            padding: var(--main-f-20-size) var(--main-f-30-size) 0 var(--main-f-12-size) !important;
            border: var(--main-f-1-size) solid #262626 !important;
            border-radius: var(--main-f-10-size) !important;
            cursor: pointer !important;

            &.input-autocomplete {
              background: url(../../icons/arrowSelectBold.svg) no-repeat right white !important;
              background-position-x: 90% !important;
              appearance: none !important;
            }

            &:focus-visible {
              outline: none !important;
            }

            &:disabled {
              background-color: #f3f3f3 !important;
              color: #a3a3a3 !important;
            }
          }

          .container-properties {
            min-width: 68.5% !important;
            max-width: 68.5% !important;
            max-height: var(--main-f-170-size) !important;
            margin-top: var(--main-f-9-size) !important;
            padding-top: var(--main-f-5-size) !important;
            padding-bottom: var(--main-f-5-size) !important;
            border: var(--main-f-1-size) solid rgba(196, 196, 196, 0.15) !important;
            border-radius: var(--main-f-9-size) !important;
            box-shadow: 0 0 var(--main-f-4-size) rgba(0, 0, 0, 0.25) !important;
            background-color: #fff !important;
            overflow-y: auto !important;
            z-index: 99 !important;
            position: absolute !important;
            left: var(--main-f-345-size) !important;
            top: 49.969px !important;

            .autocomplete {
              width: 100% !important;
              padding: var(--main-f-5-size) var(--main-f-5-size) var(--main-f-5-size) var(--main-f-9-size) !important;
              cursor: pointer;
            }
          }

          .container-properties::-webkit-scrollbar {
            width: var(--main-f-6-size) !important;
            background-color: #f5f5f5 !important;
          }

          .container-properties::-webkit-scrollbar-track {
            box-shadow: inset 0 0 var(--main-f-3-size) rgba(196, 196, 196, 1) !important;
            border-radius: var(--main-f-18-size) !important;
            background-color: #f5f5f5 !important;
          }

          .container-properties::-webkit-scrollbar-thumb {
            border-radius: var(--main-f-18-size) !important;
            background-color: #535353 !important;
          }

          input,
          select {
            width: 75% !important;
            height: var(--main-f-40-size) !important;
            font-size: var(--main-f-11-size) !important;
            color: #000000 !important;
            border: var(--main-f-1-size) solid #262626 !important;
            border-radius: var(--main-f-9-size) !important;
            padding-top: var(--main-f-18-size) !important;
            padding-left: var(--main-f-8-size) !important;
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
            cursor: pointer !important;

            &.disabled {
              background-color: #f3f3f3 !important;
              color: #a3a3a3 !important;
              opacity: 1 !important;
            }
          }
        }

        .row-button-apply {
          .col {
            display: flex !important;
            justify-content: center !important;

            button {
                width: 75% !important;
                margin-top: 5% !important;
                padding: var(--main-f-8-size) 0 !important;
            }
          }
        }
      }

      .container-real-estates {
        margin-left: 16.67% !important;
      }
    }

    .container-title-breadcrumbs {
      margin-top: var(--main-f-10-size) !important;
      padding: 0 !important;

      .last-title {
        font-size: var(--main-f-12-size) !important;
        font-weight: 600 !important;
        color: #939393 !important;
        cursor: pointer;

        &:hover {
          text-decoration: underline !important;
        }

        &:active {
          box-shadow: none !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }

      .space {
        font-size: var(--main-f-12-size) !important;
        font-weight: 600 !important;
        color: #939393 !important;
        margin: 0 !important;
      }

      .first-title {
        font-size: var(--main-f-12-size) !important;
        font-weight: 600 !important;
        color: #000000 !important;
      }
    }

    .total-enterprises {
      font-size: var(--main-f-12-size) !important;
      font-weight: 600 !important;
      margin-top: var(--main-f-20-size) !important;
      margin-bottom: var(--main-f-17-size) !important;

      img {
        margin-left: var(--main-f-10-size) !important;
      }
    }

    .row-ordination {
      margin-top: var(--main-f-18-size) !important;

      .col {
        display: flex !important;
        align-self: center !important;

        &:nth-child(2) {
          justify-content: flex-end !important;

          label {
            left: 70.5% !important;
          }
        }
      }

      .col-autocomplete {
        margin-bottom: 0 !important;

        div {
          width: 31% !important;

          input {
            width: 100% !important;
            height: var(--main-f-40-size) !important;
            padding: var(--main-f-20-size) var(--main-f-30-size) 0 var(--main-f-12-size) !important;
            border: var(--main-f-1-size) solid #262626 !important;
            border-radius: var(--main-f-10-size) !important;
            background: url(../../icons/arrowSelectBold.svg) no-repeat right white !important;
            background-position-x: 90% !important;
            cursor: pointer !important;

            &:focus-visible {
              outline: none !important;
            }
          }

          .container-properties {
            min-width: 29.5% !important;
            max-width: 29.5% !important;
            max-height: var(--main-f-175-size) !important;
            margin-top: var(--main-f-9-size) !important;
            padding-top: var(--main-f-5-size) !important;
            padding-bottom: var(--main-f-5-size) !important;
            border: var(--main-f-1-size) solid rgba(196, 196, 196, 0.15) !important;
            border-radius: var(--main-f-9-size) !important;
            box-shadow: 0 0 var(--main-f-4-size) rgba(0, 0, 0, 0.25) !important;
            background-color: #fff !important;
            overflow-y: auto !important;
            z-index: 99 !important;
            position: absolute !important;
            left: var(--main-f-345-size) !important;
            top: 49.969px !important;

            .autocomplete {
              width: 100% !important;
              padding: var(--main-f-5-size) var(--main-f-5-size) var(--main-f-5-size) var(--main-f-9-size) !important;
              cursor: pointer;
            }
          }

          .container-properties::-webkit-scrollbar {
            width: var(--main-f-6-size) !important;
            background-color: #f5f5f5 !important;
          }

          .container-properties::-webkit-scrollbar-track {
            box-shadow: inset 0 0 var(--main-f-3-size) rgba(196, 196, 196, 1) !important;
            border-radius: var(--main-f-18-size) !important;
            background-color: #f5f5f5 !important;
          }

          .container-properties::-webkit-scrollbar-thumb {
            border-radius: var(--main-f-18-size) !important;
            background-color: #535353 !important;
          }
        }
      }
    }

    h1 {
      font-size: var(--main-f-12-size) !important;
      font-weight: 600 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    .page-post {
      margin-top: var(--main-f-24-size) !important;

      .none-posts {
        margin-top: var(--main-f-26-size) !important;
        display: flex !important;
        flex-direction: column !important;

        .col {
          justify-content: center !important;
          display: flex !important;

          h4 {
            width: 60% !important;
            font-size: var(--main-f-18-size) !important;
            font-weight: 600 !important;
            margin-bottom: var(--main-f-13-size) !important;
            text-align: center !important;
          }

          p {
            font-size: var(--main-f-12-size) !important;
            margin-bottom: var(--main-f-53-size) !important;
            text-align: center !important;
          }
        }
      }
    }

    .container-realestate-filters {
      padding-top: var(--main-f-16-size) !important;
    }

    .container-button {
      padding-right: 0 !important;
      padding-left: var(--main-f-11-size) !important;
      display: flex !important;
      align-items: center !important;

      button {
        padding: var(--main-f-8-size) var(--main-f-13-size) var(--main-f-8-size) var(--main-f-6-size) !important;
      }
    }

    .placeholder-input {
      font-size: var(--main-f-9-size) !important;
      color: #535353 !important;
      position: absolute !important;
      top: var(--main-f-7-size) !important;
      left: var(--main-f-46-size) !important;
    }

    .container-card-enterprise {
      padding: 0 !important;
      display: flex !important;
      justify-content: center !important;

      .card-info {
        width: 96% !important;
        height: var(--main-f-390-size) !important;
        border-radius: var(--main-f-9-size) !important;
        overflow: hidden !important;
        margin-bottom: var(--main-f-25-size) !important;
        display: flex !important;

        img {
          width: 101% !important;
          height: 101% !important;
          object-fit: cover !important;
          filter: brightness(0.55) !important;
        }

        .content {
          width: 100% !important;
          padding: 0 var(--main-f-9-size) !important;
          position: absolute !important;
          display: flex !important;
          flex-direction: column !important;
          justify-content: space-between !important;

          h4 {
            font-size: var(--main-f-19-size) !important;
            margin: var(--main-f-40-size) 0 0 0 !important;
            text-align: initial !important;
            color: #ffffff !important;
          }

          a {
            width: 100% !important;
            margin-bottom: var(--main-f-40-size) !important;
            padding: var(--main-f-5-size) 0 !important;
            display: flex !important;
            justify-content: center !important;
            text-decoration: none !important;
          }
        }
      }

      .card-enterprise {
        width: 96% !important;
        height: auto !important;
        border-radius: var(--main-f-9-size) !important;
        margin-bottom: var(--main-f-25-size) !important;
        overflow: initial !important;
        cursor: pointer !important;

        .container-image {
          width: 100% !important;
          height: var(--main-f-130-size) !important;
          min-height: var(--main-f-130-size) !important;
          background-color: #f6f6f6 !important;
          border-radius: var(--main-f-8-size) var(--main-f-8-size) 0 0 !important;
          padding: 0 !important;
          overflow: hidden !important;

          .img-carousel {
            max-width: 100% !important;
            width: 100% !important;
            height: var(--main-f-130-size) !important;
            border-radius: 0 !important;
            margin: 0 !important;
            object-fit: contain !important;
            object-position: center;
            background-color: #f4f4f4 !important;

            &.img-contains {
              object-fit: contain !important;
            }

            &.img-cover {
              object-fit: cover !important;
            }
          }
        }

        .card-body {
          padding: var(--main-f-8-size) var(--main-f-13-size) var(--main-f-15-size) !important;

          .row {
            margin: 0 !important;
          }

          .container-name-broker {
            padding: 0 !important;

            p {
              font-size: var(--main-f-8-size) !important;
              margin: 0 !important;
            }

            h5 {
              font-size: var(--main-f-11-size) !important;
              font-weight: 600 !important;
              overflow: hidden !important;
              white-space: nowrap !important;
              text-overflow: ellipsis !important;
            }
          }

          .container-immobile-location {
            p {
              height: var(--main-f-24-size) !important;
              line-height: var(--main-f-12-size) !important;
              font-size: var(--main-f-10-size) !important;
              font-weight: 600 !important;
              color: #262626 !important;
              margin: 0 !important;
              display: -webkit-box !important;
              -webkit-line-clamp: 2 !important;
              -webkit-box-orient: vertical !important;
              overflow: hidden !important;
              text-overflow: ellipsis !important;
            }
          }

          .row-value {
            margin-top: var(--main-f-8-size) !important;
            margin-bottom: 0 !important;
            display: block !important;

            span {
              font-size: var(--main-f-8-size) !important;
              color: #262626 !important;
            }

            .text-value {
              font-size: var(--main-f-12-size) !important;
              line-height: var(--main-f-13-size) !important;
              font-weight: 700 !important;
              margin: 0 !important;
            }
          }

          .container-comission {
            margin-bottom: auto !important;

            p {
              font-size: var(--main-f-9-size) !important;
              font-style: italic !important;
              margin: 0 !important;
              color: #262626 !important;
            }
          }

          .container-icons {
            margin-top: var(--main-f-7-size) !important;
            margin-bottom: var(--main-f-25-size) !important;

            div {
              display: flex !important;

              &:nth-child(2) {
                width: 50% !important;
              }

              img {
                margin-right: var(--main-f-4-size) !important;
              }

              p {
                font-size: var(--main-f-11-size) !important;
                margin: 0 var(--main-f-18-size) 0 0 !important;
              }
            }
          }

          .row-button {
            button {
              font-size: var(--main-f-12-size) !important;
              margin-top: var(--main-f-10-size) !important;
            }
          }
        }
      }
    }

    .banner {
      margin-bottom: 0 !important;
    }

    .banner-img {
      display: flex !important;
      justify-content: center !important;
    }

    .banner-section {
      width: 73% !important;
      cursor: pointer !important;
      padding: 0 !important;
      border: none !important;
      background: #fff !important;
      padding-top: 0 !important;
      margin-bottom: var(--main-f-26-size) !important;
      text-align: initial !important;

      .card {
        margin: 0 !important;
        padding: var(--main-f-20-size) var(--main-f-28-size) !important;
        background-color: #fee0e0 !important;
        border: var(--main-f-1-size) solid #fee0e0 !important;
        border-radius: var(--main-f-14-size) !important;

        h4 {
          font-size: var(--main-f-19-size) !important;
          font-weight: 400 !important;
          margin: 0 !important;
          color: #000000 !important;
        }

        .btn_web_primary {
          width: fit-content !important;
          font-size: var(--main-f-12-size) !important;
          margin-top: var(--main-f-12-size) !important;
          padding: var(--main-f-8-size) var(--main-f-22-size) !important;
        }

        .logo {
          width: 85% !important;
        }
      }
    }
  }
}

.card-text-brokers-seleto {
  margin-top: var(--main-f-5-size) !important;
  font-size: var(--main-f-13-size) !important;
  line-height: 14px !important;
}

.title-description-seleto {
  margin-top: var(--main-f-60-size) !important;
  margin-bottom: var(--main-f-60-size) !important;
  font-family: Raleway !important;
}

.description-seleto {
  margin-top: var(--main-f-32-size) !important;
}

.title-seleto {
  font-size: 24px !important;
  line-height: 28px !important;
}

.title-seleto-decoration {
  font-size: 24px !important;
  text-decoration: underline #d0222f;
  text-decoration-line: 28px !important;
  text-decoration-thickness: 5px;
  font-family: Raleway-Bold !important;
}

.col-bottom-80 {
  margin-bottom: 38px !important;
}

.a-blue {
  color: #007aff !important;
  font-size: var(--main-f-12-size) !important;
  text-decoration: underline #007aff !important;
}

.div-seleto {
  margin-bottom: 50px !important;
}

.imgs-playstore {
  margin-left: -50px !important;
}

.appfoot {
  border-top: 1px solid #cacaca !important;
  padding: var(--main-f-10-size) !important;

  .h-assets-imgs {
    padding-left: 0 !important;
    padding-right: 0 !important;
    align-self: left !important;
    text-align: -webkit-left !important;
    vertical-align: middle !important;
    .h-imgs {
      width: auto !important;
      height: auto !important;
      margin-top: var(--main-f-20-size) !important;
      margin-left: var(--main-f-17-size) !important;
    }
  }

  .card-text-download-app {
    margin-top: var(--main-f-12-size) !important;
    font-size: var(--main-f-11-size) !important;
    font-weight: 600 !important;
    color: #535353 !important;
  }

  .ios {
    margin-left: 0 !important;
    width: auto !important;
  }

  .imgs-playstore {
    margin-right: var(--main-f-70-size) !important;
    left: 0 !important;
    margin-top: 0 !important;
    width: 0px !important;
    margin-left: var(--main-f-20-size) !important;
  }

  .row-tittle-footer {
    margin-bottom: 0 !important;
  }

  .imgs-ios {
    width: var(--main-f-110-size) !important;
    flex: 0 0 0 !important;
  }

  .playstore {
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: auto !important;
    // margin-left: var(--main-f-50-size) !important;
  }

  .solutions {
    width: 50% !important;
  }

  .row-footer {
    justify-content: space-around !important;
    width: var(--main-f-165-size) !important;
  }
}

.app-gallery {
  margin-top: 157px !important;

  .form-group-seleto-mobile {
    margin: 0 !important;
    height: var(--main-f-60-size) !important;
    width: var(--main-f-180-size) !important;
    padding-right: 10px !important;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .filter-mobile {
    text-align-last: left !important;
    font-size: 16px !important;
  }

  .btn-filter {
    border: 1.00342px solid #d0222f;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #d0222f;
    color: #fff;
    height: 50px !important;
  }

  .icon-filter {
    text-align-last: left !important;
    margin-right: 10px !important;
    margin-bottom: -2px !important;
    margin-left: 14px !important;
  }
}

.arrow-icon {
  margin-top: var(--main-f-25-size);
}

.incorp h4 {
  font-size: 14px;
}

.incorp h2 {
  font-size: 12px;
}
