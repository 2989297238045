.homer-seleto {
  font-family: Raleway !important;

  .title-col {
    min-width: var(--main-f-400-size) !important;
  }

  .switch {
    width: var(--main-f-135-size) !important;
    padding: var(--main-f-4-size) !important;
    height: var(--main-f-57-size) !important;
    align-items: center !important;
    text-align: center !important;
    margin: 0 !important;

    p {
      font-size: var(--main-f-30-size) !important;
    }

    button.btn.btn-outline-secondary.m-right {
      margin: 0px var(--main-f-5-size) 0px 0px !important;
      box-shadow: none !important;
      color: #535353 !important;
      font-size: var(--main-f-15-size) !important;
    }

    button.btn.btn-outline-secondary.m-left {
      margin: 0px 0px 0px var(--main-f-5-size) !important;
      box-shadow: none !important;
      color: #535353 !important;
      font-size: var(--main-f-15-size) !important;
    }

    button.is-rent.btn.btn-outline-secondary {
      background-color: rgba(222, 88, 95, 0.2) !important;
      box-shadow: none !important;
      color: #D0222F !important;
      font-size: var(--main-f-15-size) !important;
    }
  }

  .card-inputs {
    width: var(--main-f-320-size) !important;
    height: var(--main-f-255-size) !important;

    .form-group {
      margin: 0 !important;
      height: var(--main-f-85-size) !important;
      padding: var(--main-f-5-size) !important;
      padding-top: var(--main-f-20-size) !important;
      padding-left: 0 !important;
      min-width: var(--main-f-155-size) !important;

      .input-value {
        border-radius: 0 !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        width: var(--main-f-150-size) !important;
        margin-right: 0px !important;
        height: var(--main-f-30-size) !important;
        padding-left: var(--main-f-18-size) !important;
        margin-left: 0 !important;
        font-size: var(--main-f-17-size) !important;
      }

      .select-number {
        border-color: #cccccc !important;
        outline: 0 !important;
        box-shadow: none !important;
        font-size: var(--main-f-17-size) !important;
        border: none !important;
        width: var(--main-f-55-size) !important;
        margin-left: var(--main-f-18-size) !important;
        justify-content: space-around !important;
      }

      .margin-lf {
        margin-left: var(--main-f-27-size) !important;
      }

      select {
        border-color: #cccccc !important;
        outline: 0 !important;
        box-shadow: none !important;
        border: none !important;
        width: var(--main-f-138-size) !important;
        height: var(--main-f-30-size) !important;
        margin-left: var(--main-f-10-size) !important;
        font-size: var(--main-f-17-size) !important;
        padding: 0 !important;
      }

      input {
        border-radius: 0 !important;
        border-color: rgb(202, 202, 202) !important;
        outline: 0 !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        width: var(--main-f-288-size) !important;
        margin-right: var(--main-f-5-size) !important;
        font-size: var(--main-f-17-size) !important;
        height: var(--main-f-30-size) !important;
        padding-left: var(--main-f-22-size) !important;
      }

      .icons-pl {
        padding-left: var(--main-f-9-size) !important;
      }

      ::-webkit-input-placeholder {
        color: #bbbbbb !important;
        padding-left: 0 !important;
      }

      .autocomplete {
        font-size: var(--main-f-16-size) !important;
        border: 1px solid #cccccc !important;
        border-top: 0 !important;
        width: var(--main-f-320-size) !important;
        justify-content: left !important;
        padding-top: var(--main-f-10-size) !important;
        padding-bottom: var(--main-f-10-size) !important;
        padding-left: var(--main-f-20-size) !important;
      }

      .border-right {
        border-right: 1px solid rgb(202, 202, 202) !important;
      }

      .border-bottom {
        border-bottom: 1px solid rgb(202, 202, 202) !important;
      }

      i {
        color: rgb(157, 157, 157) !important;
      }
    }
  }

  .a-blue {
    color: #007aff !important;
    font-size: var(--main-f-15-size) !important;
    text-decoration: underline #007aff !important;
  }

  .h3-title-card {
    margin-bottom: 0 !important;
    margin-top: var(--main-f-43-size) !important;
    font-size: var(--main-f-19-size) !important;
    text-align: center !important;
  }

  .title-seleto-decoration {
    font-size: 24px !important;
    text-decoration: underline #D0222F;
    text-decoration-line: 28px !important;
    font-family: Raleway-Bold !important;
    text-decoration-thickness: 5px;
  }

  .p-subtitle {
    margin-bottom: var(--main-f-25-size) !important;
    font-size: var(--main-f-13-size) !important;
    text-align: center !important;
  }

  .text-stamp {
    margin-top: var(--main-f-60-size) !important;
    margin-right: auto !important;
    margin-left: auto !important;
    font-size: var(--main-f-22-size) !important;
    width: var(--main-f-220-size) !important;
    font-family: whatever-it-takes !important;
  }

  .text-stamp2 {
    margin-right: auto !important;
    margin-left: auto !important;
    font-size: var(--main-f-19-size) !important;
    width: var(--main-f-220-size) !important;
    font-family: whatever-it-takes !important;
  }

  .demonstration {
    border-radius: var(--main-f-10-size) !important;
    width: var(--main-f-298-size) !important;
    height: var(--main-f-400-size) !important;
    margin-top: var(--main-f-75-size) !important;
    box-shadow: 0 0 1em rgb(157, 157, 157) !important;
    margin-right: auto !important;
    margin-left: auto !important;

    h6 {
      border-bottom: 1px solid !important;
      border-color: rgb(157, 157, 157) !important;
    }

    h5 {
      font-family: Raleway-Bold !important;
      margin-bottom: 0 !important;
    }

    .for-sale {
      font-family: Raleway-Bold !important;
      margin-bottom: 0 !important;
      color: rgb(39, 176, 98) !important;
    }

    .card-img-top {
      position: absolute !important;
      border-radius: var(--main-f-10-size) 0 0 !important;
    }

    .card-body {
      margin-top: var(--main-f-130-size) !important;
    }

    .arrow {
      transform: rotate(10deg) !important;
      z-index: 5 !important;
      position: absolute !important;
      width: var(--main-f-55-size) !important;
      bottom: var(--main-f-380-size) !important;
      margin-left: -24px !important;
    }

    .margin-bt {
      margin-bottom: var(--main-f-15-size) !important;
    }
  }

  .box-seleto {
    background-color: #262626 !important;
    width: var(--main-f-170-size) !important;
    height: var(--main-f-46-size) !important;
    justify-content: center !important;
    align-content: center !important;
    text-align: center !important;
    margin-top: var(--main-f-125-size) !important;
    margin-left: var(--main-f-30-size) !important;

    h4 {
      font-size: var(--main-f-22-size) !important;
      color: white !important;
      margin-bottom: 0 !important;
      width: auto !important;
    }

    .bold {
      font-family: Raleway-Bold !important;
    }
  }

  .text-seleto {
    margin-left: var(--main-f-30-size) !important;
    margin-right: var(--main-f-20-size) !important;
    margin-top: var(--main-f-17-size) !important;
    font-size: var(--main-f-22-size) !important;
    font-family: Raleway-Bold !important;
  }

  .text-seleto2 {
    margin-right: var(--main-f-20-size) !important;
    margin-left: var(--main-f-30-size) !important;
    font-size: var(--main-f-17-size) !important;
  }

  .btn-salmon {
    min-width: var(--main-f-207-size) !important;
  }

  .float {
    float: left !important;
    z-index: 4 !important;
    width: var(--main-f-130-size) !important;
    margin-left: var(--main-f-10-size) !important;
    margin-top: var(--main-f-12-size) !important;
    border-radius: var(--main-f-22-size) !important;
    color: white !important;
    text-align: center !important;

    label {
      vertical-align: middle !important;
      margin: 0 !important;
    }
  }

  .TagSeleto {
    width: 70px !important;
    left: var(--main-f-10-size) !important;
    top: var(--main-f-15-size) !important;
    position: absolute !important;
    z-index: 20 !important;
  }

  .introduce-seleto {
    justify-content: center !important;
    padding-bottom: var(--main-f-40-size) !important;
  }

  .profile {
    width: var(--main-f-100-size) !important;
    height: var(--main-f-100-size) !important;
    border-radius: var(--main-f-50-size) !important;
    margin-top: var(--main-f-40-size) !important;
  }

  .text-doubt {
    display: -webkit-inline-box !important;

    h4 {
      font-size: var(--main-f-17-size) !important;
      font-weight: 600 !important;
      font-family: Raleway !important;
    }

    .h4-underline {
      text-decoration: underline #D0222F !important;
      margin-left: var(--main-f-5-size) !important;
    }
  }

  .input-group {
    label {
      font-size: var(--main-f-17-size) !important;
      font-family: Raleway !important;
      margin-top: var(--main-f-7-size) !important;
      margin-bottom: var(--main-f-5-size) !important;
      font-weight: 400 !important;
      color: #000000 !important;
    }

    input {
      border-radius: var(--main-f-4-size) !important;
      border-color: rgb(202, 202, 202) !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      width: var(--main-f-358-size) !important;
      height: var(--main-f-45-size) !important;
    }

    ::-webkit-input-placeholder {
      font-family: Raleway-MediumItalic !important;
    }

    textarea {
      border-radius: var(--main-f-4-size) !important;
      border-color: rgb(202, 202, 202) !important;
      outline: 0 !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      height: var(--main-f-90-size) !important;
      width: var(--main-f-358-size) !important;
    }

    button {
      width: var(--main-f-298-size) !important;
      height: var(--main-f-55-size) !important;
      background: #ffffff !important;
      border: 1px solid #D0222F !important;
      box-sizing: border-box !important;
      border-radius: var(--main-f-8-size) !important;
      color: #D0222F !important;
      font-size: var(--main-f-17-size) !important;
      font-family: Raleway-Bold !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    p {
      margin-top: var(--main-f-15-size) !important;
      font-size: var(--main-f-13-size) !important;
      font-weight: 400 !important;
    }
  }

  .step-by-step {
    h3 {
      font-size: var(--main-f-19-size) !important;
      font-family: Raleway-Bold !important;
      text-align: center !important;
      margin-bottom: var(--main-f-50-size) !important;
    }

    .card {
      width: var(--main-f-55-size) !important;
      height: var(--main-f-55-size) !important;
      vertical-align: middle !important;
      border-radius: var(--main-f-50-size) !important;
      text-align: center !important;
      justify-content: center !important;
      border-color: #D0222F !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }

    h6 {
      vertical-align: middle !important;
      margin: 0 !important;
      font-size: var(--main-f-21-size) !important;
      color: #D0222F !important;
      font-weight: 700 !important;
    }

    h2 {
      font-size: var(--main-f-16-size) !important;
      font-weight: 600 !important;
      margin-left: auto !important;
      margin-right: auto !important;
      text-align: center !important;
    }

    h4 {
      max-width: var(--main-f-285-size) !important;
      font-size: var(--main-f-15-size) !important;
      margin-left: auto !important;
      margin-right: auto !important;
      text-align: center !important;
    }
  }

  .salmon-button {
    background-color: #D0222F !important;
    border-color: #D0222F !important;
    color: #ffffff !important;
    width: var(--main-f-298-size) !important;
    height: var(--main-f-50-size) !important;
    border-radius: var(--main-f-8-size) !important;
    font-size: var(--main-f-17-size) !important;
    font-family: Raleway-Bold !important;
    margin-top: var(--main-f-7-size) !important;
    margin-bottom: var(--main-f-24-size) !important;
  }

  .btn-salmon-seleto {
    background-color: #D0222F !important;
    border-color: #D0222F !important;
    color: #ffffff !important;
    width: var(--main-f-298-size) !important;
    height: var(--main-f-50-size) !important;
    border-radius: var(--main-f-8-size) !important;
    font-size: var(--main-f-17-size) !important;
    font-family: Raleway-Bold !important;
    margin-bottom: var(--main-f-24-size) !important;
  }

  .white-button {
    background-color: #ffffff !important;
    border-color: #D0222F !important;
    color: #D0222F !important;
    width: var(--main-f-298-size) !important;
    height: var(--main-f-50-size) !important;
    border-radius: var(--main-f-8-size) !important;
    font-size: var(--main-f-17-size) !important;
    font-family: Raleway-Bold !important;
    margin-top: var(--main-f-7-size) !important;
    margin-bottom: var(--main-f-7-size) !important;
    padding: 0 !important;
  }

  .row-buttons {
    margin-top: var(--main-f-15-size) !important;
    justify-content: space-evenly !important;
  }

  .btn-white-card {
    background-color: #ffffff !important;
    border-color: #D0222F !important;
    color: #D0222F !important;
    width: var(--main-f-271-size) !important;
    height: var(--main-f-40-size) !important;
    border-radius: var(--main-f-8-size) !important;
    font-size: var(--main-f-17-size) !important;
    font-family: Raleway !important;
    margin-top: 0 !important;
    font-weight: bold !important;
    margin-bottom: var(--main-f-15-size) !important;
  }

  .margin-bottom {
    margin-bottom: 0 !important;
  }

  .padding-top {
    padding-top: 0 !important;
  }

  .h2-title {
    font-size: var(--main-f-25-size) !important;
  }

  .h2-mini-title {
    font-size: var(--main-f-17-size) !important;
    text-align: center !important;
    font-family: Raleway !important;
    font-weight: 400 !important;
  }

  .blue-text {
    color: #007aff !important;
    text-decoration: underline;
  }

  .grey-div {
    background-color: rgb(246, 246, 246) !important;
  }

  .div-size {
    width: 100% !important;
    min-height: 1vh !important;
    background-color: white;
    padding: var(--main-f-15-size);
  }

  .backgroundcolor-white {
    background-color: #ffffff !important;
  }

  .align-center {
    text-align: center !important;
  }

  .row-tittle-footer {
    margin-bottom: var(--main-f-50-size) !important;
    // border-top: 1px solid #cacaca !important;
    margin-top: var(--main-f-22-size) !important;
  }

  .title-description-seleto {
    margin-top: var(--main-f-60-size) !important;
    margin-bottom: var(--main-f-95-size) !important;
  }

  .description-seleto {
    margin-top: var(--main-f-32-size) !important;
  }

  .title-seleto {
    font-size: 24px !important;
    line-height: 28px !important;
  }

  .title-seleto-decoration {
    font-size: 24px !important;
    text-decoration: underline #D0222F;
    text-decoration-line: 28px !important;
    text-decoration-thickness: 5px;
  }
}
