.results {
  padding: var(--main-f-9-size) !important;
  margin-top: var(--main-f-60-size) !important;
  background-color: #ffffff !important;
  min-height: 164vw !important;
  font-family: Raleway !important;

  .card-center {
    text-align: -webkit-center !important;
  }

  .fake-post {
    padding: var(--main-f-45-size) var(--main-f-30-size) var(--main-f-30-size) !important;
    cursor: auto !important;

    .row {
      margin: 0 !important;

      .col-title-fake {
        text-align: initial !important;
        .title {
          width: 85% !important;
          font-size: var(--main-f-19-size) !important;
          font-weight: 700 !important;
          margin: 0 !important;
          margin-bottom: var(--main-f-10-size) !important;
          text-align: initial !important;
          line-height: var(--main-f-17-size) !important;
        }
      }

      .col {
        padding: 0 !important;

        .fake-image {
          margin-bottom: var(--main-f-25-size) !important;
        }

        .text-fake {
          font-size: var(--main-f-15-size) !important;
          font-weight: 400 !important;
          margin-bottom: var(--main-f-25-size) !important;
          text-align: initial !important;
        }

        .button-fake {
          width: 100% !important;
          font-size: var(--main-f-17-size) !important;
          font-weight: 600 !important;
          color: #d0222f !important;
          background-color: #ffffff !important;
          border: 1px solid #d0222f !important;
          border-radius: var(--main-f-11-size) !important;
        }
      }
    }
  }

  .maps {
    width: 100vw !important;
    height: calc(100vh - var(--main-f-70-size)) !important;
    position: inherit !important;
    margin-left: -10px !important;

    .pointers {
      padding: 0 !important;
      color: #ffffff !important;
      background: #D0222F !important;
      border-radius: 50% !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      cursor: pointer !important;
      font-family: Raleway-Bold !important;
      font-size: 16px !important;
      border-color: #D0222F !important;
    }
  }

  .TagSeleto {
    width: 70px !important;
    left: var(--main-f-10-size) !important;
    top: var(--main-f-15-size) !important;
    position: absolute !important;
    z-index: 1 !important;
  }

  .back {
    padding-top: var(--main-f-19-size) !important;
    padding-bottom: var(--main-f-25-size) !important;

    .arrow {
      margin-left: var(--main-f-15-size) !important;
    }

    .row-flex-end {
      justify-content: flex-end !important;
    }

    h4 {
      font-size: var(--main-f-12-size) !important;
      margin-left: var(--main-f-4-size) !important;
      margin-bottom: 0 !important;
      cursor: pointer !important;
    }

    h4:hover {
      text-decoration: underline !important;
    }

    .position-initial {
      align-self: center !important;
    }

    .location {
      width: var(--main-f-220-size) !important;
      height: var(--main-f-30-size) !important;
      border: 1px solid #cacaca !important;
      border-radius: var(--main-f-23-size) !important;
      margin-right: var(--main-f-12-size) !important;
    }

    .type {
      width: var(--main-f-138-size) !important;
      height: var(--main-f-30-size) !important;
      border: 1px solid #cacaca !important;
      border-radius: var(--main-f-23-size) !important;
      margin-right: var(--main-f-12-size) !important;
    }

    .bedrooms {
      width: var(--main-f-90-size) !important;
      height: var(--main-f-30-size) !important;
      border: 1px solid #cacaca !important;
      border-radius: var(--main-f-23-size) !important;
      margin-right: var(--main-f-12-size) !important;
    }

    button {
      width: var(--main-f-110-size) !important;
      height: var(--main-f-30-size) !important;
      font-size: var(--main-f-12-size) !important;
      border-radius: var(--main-f-23-size) !important;
      color: #ffffff !important;
      background-color: #D0222F !important;
      border-color: #D0222F !important;
      margin-right: 0 !important;
    }

    .button-img {
      float: right !important;
      margin-top: var(--main-f-4-size) !important;
    }

    .icon {
      width: var(--main-f-30-size) !important;
      background-color: transparent !important;
      border: none !important;
      position: absolute !important;
      margin-right: var(--main-f-380-size) !important;
      padding-bottom: var(--main-f-7-size) !important;
    }
  }

  .card-title {
    padding-top: var(--main-f-22-size) !important;
    padding-bottom: var(--main-f-22-size) !important;
    margin-bottom: 0 !important;

    .home-town p span:hover {
      text-decoration: underline !important;
      cursor: pointer !important;
    }

    h3 {
      font-size: var(--main-f-12-size) !important;
      font-family: Raleway-Bold !important;
      margin-bottom: 0 !important;
      margin-top: var(--main-f-7-size) !important;
      margin-right: var(--main-f-11-size) !important;
    }

    .float-revealing {
      width: 100vw !important;
      height: var(--main-f-85-size) !important;
      position: fixed !important;
      margin-top: 5px !important;
      text-align: -webkit-center !important;
      background-color: #ffffff !important;
      z-index: 2;

      select {
        width: var(--main-f-190-size) !important;
        height: var(--main-f-45-size) !important;
        border: 1px solid #cacaca !important;
        border-radius: var(--main-f-8-size) !important;
        font-size: var(--main-f-17-size) !important;
        background: url(../icons/arrowSelect.svg) no-repeat right white !important;
        -webkit-appearance: none !important;
        background-position-x: var(--main-f-155-size) !important;
        margin-top: var(--main-f-22-size) !important;
        margin-bottom: var(--main-f-22-size) !important;
      }
    }
  }

  .more {
    justify-content: center !important;
    display: block !important;
  }

  .load-more {
    margin-top: var(--main-f-12-size) !important;

    button {
      width: var(--main-f-120-size) !important;
      height: var(--main-f-35-size) !important;
      font-size: var(--main-f-15-size) !important;
      border-radius: var(--main-f-23-size) !important;
      background-color: #ffffff !important;
      border-color: #ffffff !important;
      font-family: Raleway-Bold !important;
      position: fixed !important;
      left: var(--main-f-130-size) !important;
      bottom: var(--main-f-34-size) !important;
      z-index: 2 !important;
    }

    img {
      float: right !important;
      margin-right: var(--main-f-10-size) !important;
      margin-top: var(--main-f-4-size) !important;
    }
  }

  .border-bottom {
    width: 500vw !important;
    margin-left: -4000px !important;
    border-bottom: 1px solid #cacaca !important;
  }

  .container-margin-top {
    margin-top: var(--main-f-106-size) !important;
    width: var(--main-f-100-size) !important;
  }

  .post {
    margin-bottom: var(--main-f-20-size) !important;
  }

  .card {
    width: var(--main-f-310-size) !important;
    height: var(--main-f-462-size) !important;
    margin: 0 !important;
    cursor: pointer;
    border-radius: var(--main-f-7-size) !important;

    .card-img-top {
      right: 0 !important;
      position: absolute !important;
      bottom: var(--main-f-280-size) !important;
      border-radius: var(--main-f-7-size) var(--main-f-7-size) 0 0 !important;
      min-height: var(--main-f-180-size) !important;
      max-height: var(--main-f-180-size) !important;
      object-fit: contain !important;
      background-color: #F4F4F4 !important;
      
      &.img-contains {
        object-fit: contain !important;
      }

      &.img-cover {
        object-fit: cover !important;
      }
    }

    .icons {
      width: auto !important;
      margin-right: var(--main-f-4-size) !important;
    }

    .margin-right {
      margin-right: var(--main-f-10-size) !important;
    }

    // .btn-card {
    //     font-weight: 700 !important;
    //     font-size: var(--main-f-18-size) !important;
    //     width: var(--main-f-271-size) !important;
    //     height: var(--main-f-40-size) !important;
    // }

    .btn-card {
      background-color: #ffffff !important;
      border-color: #D0222F !important;
      color: #D0222F !important;
      width: var(--main-f-271-size) !important;
      height: var(--main-f-40-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-18-size) !important;
      font-family: Raleway !important;
      margin-top: 0 !important;
      font-weight: bold !important;
      margin-bottom: var(--main-f-15-size) !important;
    }
  }

  .inline {
    display: flex !important;
    flex-direction: row !important;
    margin-top: var(--main-f-170-size) !important;
  }

  .card-body {
    padding: var(--main-f-22-size) !important;
    height: var(--main-f-310-size) !important;
  }

  .card-subtitle {
    border-bottom: 1px solid !important;
    border-color: rgb(157, 157, 157) !important;
    display: flex !important;
    margin-top: var(--main-f-10-size) !important;
    font-size: var(--main-f-13-size) !important;
  }

  .card-title {
    display: flex !important;
    margin-bottom: var(--main-f-4-size) !important;
    font-family: Raleway-Bold !important;
    padding: 0 !important;
  }

  .btn-white-card {
    background-color: #ffffff !important;
    border-color: #D0222F !important;
    color: #D0222F !important;
    width: var(--main-f-271-size) !important;
    height: var(--main-f-40-size) !important;
    border-radius: var(--main-f-8-size) !important;
    font-size: var(--main-f-17-size) !important;
    font-family: Raleway-Bold !important;
    margin-top: 0 !important;
    margin-bottom: var(--main-f-15-size) !important;
  }

  .card-text {
    // display: flex !important;
    text-align: initial !important;
    margin-bottom: 0 !important;
    font-size: var(--main-f-15-size) !important;
    margin-top: var(--main-f-4-size) !important;
    display: -webkit-box;
    -webkit-line-clamp: 2; /** número de linhas que você quer exibir */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
