.header {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: var(--main-f-50-size) !important;
    z-index: 9 !important;
    font-family: Raleway-Bold !important;
    box-shadow: #cacaca 0px 0px 6px !important;
    justify-content: center !important;
    margin-bottom: 0 !important;

    ul {
        display: flex !important;
        list-style: none !important;
        margin-bottom: 8px !important;

        li {
            margin: 0 var(--main-f-23-size) !important;

            button {
                width: 100% !important;
                height: auto !important;
                font-size: var(--main-f-12-size) !important;
                font-weight: 600 !important;
                font-family: Raleway !important;
                color: #000000 !important;
                border: none !important;
                background-color: #ffffff !important;
                padding: 0 !important;

                &:focus {
                    box-shadow: none !important;
                }
            }

            a {
                font-size: var(--main-f-12-size) !important;
                font-weight: 600 !important;
                font-family: Raleway !important;
                color: #000000 !important;
                text-decoration: none !important;
            }
        }
    }

    .navbar-brand {
        vertical-align: middle !important;
        inset-inline-start: auto !important;
        margin-left: -40px;
    }

    button {
        background-color: white !important;
        color: #d0222f !important;
        height: var(--main-f-30-size) !important;
        border: 1px solid rgb(202, 202, 202) !important;
        border-radius: var(--main-f-7-size) !important;
        font-size: var(--main-f-10-size) !important;
        margin-right: var(--main-f-150-size) !important;
        padding-left: var(--main-f-15-size) !important;
        padding-right: var(--main-f-15-size) !important;
    }

    .avatar {
        border-radius: var(--main-f-27-size) !important;
        width: var(--main-f-43-size) !important;
        height: var(--main-f-43-size) !important;
        overflow: hidden !important;
        vertical-align: middle !important;
    }

    .location {
        width: 320px !important;
        height: var(--main-f-30-size) !important;
        border: 1px solid #cacaca !important;
        border-radius: var(--main-f-8-size) !important;
        margin-right: var(--main-f-12-size) !important;
        font-size: var(--main-f-12-size) !important;
        padding-left: var(--main-f-10-size) !important;
        margin-left: var(--main-f-23-size) !important;
        padding-right: var(--main-f-30-size) !important;
        outline: none !important;
        font-family: Raleway !important;
        margin-bottom: 6px !important;
    }

    .autocomplete {
        font-size: var(--main-f-12-size) !important;
        border: 1px solid #cccccc !important;
        border-top: 0 !important;
        width: var(--main-f-288-size) !important;
        justify-content: left !important;
        padding-top: var(--main-f-10-size) !important;
        padding-bottom: var(--main-f-10-size) !important;
        padding-left: var(--main-f-10-size) !important;
    }

    .icon {
        width: var(--main-f-30-size) !important;
        background-color: transparent !important;
        border: none !important;
        position: absolute !important;
        padding-bottom: var(--main-f-7-size) !important;
        margin-left: 300px !important;
    }

    h5 {
        vertical-align: middle !important;
        margin: 0 !important;
        font-size: var(--main-f-17-size) !important;
        margin-left: var(--main-f-80-size) !important;
    }

    h6 {
        margin-right: var(--main-f-165-size) !important;
        margin-bottom: 0 !important;
        font-size: var(--main-f-9-size) !important;
        color: rgb(38, 38, 38) !important;
        margin-left: var(--main-f-5-size) !important;
    }

    p {
        margin-bottom: 0 !important;
        font-size: var(--main-f-9-size) !important;
        color: rgb(38, 38, 38) !important;
        margin-left: var(--main-f-5-size) !important;
        font-family: Raleway !important;
    }

    .back-header {
        margin-left: var(--main-f-22-size) !important;
    }

    .description-site {
        font-family: Raleway !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 19px !important;
    }

    .broker-name {
        font-family: Raleway !important;
        font-style: normal !important;
        font-size: 14px !important;
        line-height: 19px !important;
    }
}

.bold {
    font-weight: 700 !important;
}

.home-town p {
    margin-bottom: 0 !important;
    color: #888888;

    .property {
        color: #000000;
    }
}

.header-mobile {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: var(--main-f-50-size) !important;
    z-index: 9 !important;
    font-family: Raleway-Bold !important;
    box-shadow: rgb(202 202 202) 0px 0px 6px !important;
    justify-content: space-between !important;

    .navbar-brand {
        vertical-align: middle !important;
        inset-inline-start: auto !important;
        margin-left: -40px;
    }

    .location {
        width: var(--main-f-325-size) !important;
        height: var(--main-f-38-size) !important;
        border: 1px solid #cacaca !important;
        border-radius: var(--main-f-8-size) !important;
        margin-right: var(--main-f-12-size) !important;
        font-size: var(--main-f-12-size) !important;
        padding-left: var(--main-f-10-size) !important;
        margin-left: var(--main-f-23-size) !important;
        padding-right: var(--main-f-30-size) !important;
        outline: none !important;
        font-family: Raleway !important;
        margin-bottom: 5px !important;
        margin-top: 5px !important;
    }

    .button-visit {
        height: var(--main-f-40-size) !important;
        font-size: var(--main-f-17-size) !important;
        padding: var(--main-f-9-size) var(--main-f-10-size) !important;
        margin-right: var(--main-f-10-size) !important;
        border-color: #d0222f !important;
    }

    .icon {
        width: var(--main-f-30-size) !important;
        background-color: transparent !important;
        border: none !important;
        position: absolute !important;
        margin-top: 8px;
        margin-left: var(--main-f-298-size) !important;
    }

    .menu-drop {
        left: 10px;
    }
    .autocomplete {
        font-size: var(--main-f-12-size) !important;
        border: 1px solid #cccccc !important;
        border-top: 0 !important;
        width: var(--main-f-325-size) !important;
        left: var(--main-f-310-size) !important;
        justify-content: left !important;
        padding-top: var(--main-f-10-size) !important;
        padding-bottom: var(--main-f-10-size) !important;
        padding-left: var(--main-f-10-size) !important;
    }

    button {
        background-color: white !important;
        color: #d0222f !important;
        height: var(--main-f-30-size) !important;
        border: 1px solid rgb(202, 202, 202) !important;
        border-radius: var(--main-f-7-size) !important;
        font-size: var(--main-f-10-size) !important;
        margin-right: var(--main-f-20-size) !important;
        padding-left: var(--main-f-15-size) !important;
        padding-right: var(--main-f-15-size) !important;
    }

    .avatar {
        border-radius: var(--main-f-27-size) !important;
        width: var(--main-f-43-size) !important;
        height: var(--main-f-43-size) !important;
        overflow: hidden !important;
        vertical-align: middle !important;
    }

    h5 {
        vertical-align: middle !important;
        margin: 0 !important;
        font-size: var(--main-f-17-size) !important;
        margin-left: var(--main-f-80-size) !important;
    }

    h6 {
        margin-right: var(--main-f-165-size) !important;
        margin-bottom: 0 !important;
        font-size: var(--main-f-9-size) !important;
        color: rgb(38, 38, 38) !important;
        margin-left: var(--main-f-5-size) !important;
    }

    p {
        margin-bottom: 0 !important;
        font-size: var(--main-f-9-size) !important;
        color: rgb(38, 38, 38) !important;
        margin-left: var(--main-f-5-size) !important;
        font-family: Raleway !important;
    }

    .back-header {
        margin-left: var(--main-f-22-size) !important;
    }
}
