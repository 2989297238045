.wrapper {
    z-index: 10;
    display: flex;
    align-items: center;
    position: fixed;
    padding: 0px 60px 0px 60px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    box-sizing: border-box;
}

.content {
    margin: auto;
    padding: 0;
    width: 90%;
    height: 100%;
    max-height: 100%;
    text-align: center;
}

.slide {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image {
    max-height: 100%;
    max-width: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.close {
    color: white;
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 40px;
    font-weight: bold;
    opacity: 0.2;
    cursor: pointer;
}

.close:hover {
    opacity: 1;
}

.navigation {
    height: 80%;
    color: white;
    cursor: pointer;
    position: absolute;
    font-size: 60px;
    line-height: 60px;
    font-weight: bold;
    display: flex;
    align-items: center;
    opacity: 0.2;
    padding: 0 15px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.react-simple-image-viewer__modal {
    &.react-carousel-image-viewer {
        .react-simple-image-viewer__close {
            z-index: 1 !important;
        }

        .carousel-root {
            max-width: 100% !important;
            width: 100% !important;
            height: 100% !important;
            display: flex !important;

            .carousel-slider {
                display: flex !important;
                align-items: center !important;
            }

            .slider-wrapper {
                display: flex !important;
                height: 100% !important;

                .row {
                    width: 100% !important;

                    .col {
                        padding: 0 !important;
                    }
                }

                .container-image {
                    margin: var(--main-f-3-size) !important;
                    display: flex !important;
                    align-items: center !important;
                }
            }
        }

        .arrow-next-image-viewer {
            position: absolute !important;
            right: var(--main-f-5-size) !important;
            cursor: pointer !important;

            svg {
                height: var(--main-f-90-size) !important;
                width: var(--main-f-45-size) !important;
                color: #ffffff !important;
            }
        }

        .arrow-prev-image-viewer {
            position: absolute !important;
            left: var(--main-f-5-size) !important;
            cursor: pointer !important;
            z-index: 1 !important;

            svg {
                height: var(--main-f-90-size) !important;
                width: var(--main-f-45-size) !important;
                color: #ffffff !important;
            }
        }
    }
}

.navigation:hover {
    opacity: 1;
}

@media (hover: none) {
    .navigation:hover {
        opacity: 0.2;
    }
}

.prev {
    left: 0;
}

.next {
    right: 0;
}

@media (max-width: 900px) {
    .wrapper {
        padding: 0;
    }
}
