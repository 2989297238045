

.container-404{
    font-family: Raleway !important;;
    margin-top: var(--main-f-100-size) !important;
    margin-left: var(--main-f-50-size) !important;
  
    .label-erro{
      font-weight: 700 !important;
      font-size: var(--main-f-15-size) !important;
      line-height: 16px !important;
    }
  
    .label-ops{
      font-weight: bold !important;
      font-size: var(--main-f-30-size) !important;
      line-height: 35px !important;
    }
  
    .label-notfound{
      font-size: var(--main-f-17-size) !important;
      margin-top: var(--main-f-15-size) !important;
      font-weight: 400 !important;
    }
  
    .label-searchtext{
      font-size: var(--main-f-17-size) !important;
      margin-top: var(--main-f-40-size) !important;
      font-weight: 400 !important;
    }
  
    .btn-salmon-seleto{
      background-color: #D0222F !important;
      color: #ffffff !important;
      min-width: var(--main-f-125-size) !important;
      // width: auto !important;
      height: var(--main-f-28-size) !important;
      border-radius: var(--main-f-8-size) !important;
      font-size: var(--main-f-12-size) !important;
      font-family: Raleway !important;  
      border-color: #D0222F !important;
    }
  }