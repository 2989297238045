.modal-body {
    padding: 0 !important;
    margin: var(--main-f-5-size) !important;
    text-align: -webkit-center !important;
    margin-top: var(--main-f-20-size) !important;

    
    .container-close {
        text-align: end !important;
        margin-bottom: var(--main-f-9-size) !important;
        
        .buttonClose {
            background-color: #ffffff !important;
            border-color: #ffffff !important;
        }
    }

    .content-modal-success {
        text-align: start !important;
        margin-top: var(--main-f-50-size);
        // margin-left: var(--main-f-20-size);
        margin-bottom: var(--main-f-120-size);
  
        .label-success{
            font-family: Raleway !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 24px !important;
            line-height: 28px !important;
        }
  
        .label-description {
            font-family: Raleway !important;
            font-style: normal !important;
            font-size: 16px !important;
            margin-right: var(--main-f-30-size) !important;
        }
  
        .button-back {
            width: 280px !important;
            height: 50px !important;
            background: #D0222F;
            border-radius: 10px !important;
            color: #ffffff !important;
            font-family: Raleway !important;
            font-weight: 700 !important;
            font-size: var(--main-f-17-size);
            margin-left: var(--main-f-40-size);
        }
    }

    .container-close {
        text-align: end !important;
        margin-bottom: var(--main-f-9-size) !important;

        .buttonClose {
            background-color: #ffffff !important;
            border-color: #ffffff !important;
        }
    }

    .modal-filter{
        margin-top: 60px;
    }
    form {
        padding: var(--main-f-7-size) !important;
        justify-content: center !important;

        img {
            margin-left: var(--main-f-20-size) !important;
        }

        label {
            font-size: var(--main-f-17-size) !important;
            font-family: Raleway-Bold !important;
            margin-top: var(--main-f-7-size) !important;
            margin-bottom: var(--main-f-7-size) !important;
            display: flex !important;
            margin-left: var(--main-f-20-size) !important;
        }

        input {
            width: var(--main-f-340-size) !important;
            height: var(--main-f-43-size) !important;
            border-radius: var(--main-f-7-size) !important;
            border-color: rgb(202, 202, 202) !important;
        }

        textarea {
            width: var(--main-f-340-size) !important;
            height: var(--main-f-85-size) !important;
            border-radius: var(--main-f-7-size) !important;
            border-color: rgb(202, 202, 202) !important;
            resize: none !important;
        }

        button {
            width: var(--main-f-340-size) !important;
            height: var(--main-f-40-size) !important;
            border-radius: var(--main-f-7-size) !important;
            font-size: var(--main-f-17-size) !important;
            font-family: Raleway-Bold !important;
            border-color: #D0222F !important;
            color: #ffffff !important;
            background-color: #D0222F !important;
            margin-top: var(--main-f-10-size) !important;
            margin-bottom: var(--main-f-7-size) !important;
        }

        .row-modal {
            display: flex !important;
            text-align: initial !important;
            padding: 0 var(--main-f-15-size) !important;
            
            p {
                width: var(--main-f-340-size) !important;
                font-size: var(--main-f-13-size) !important;
            }
            
            a {
                color: #D0222F !important;
                text-decoration: underline !important;
                font-size: var(--main-f-13-size) !important;
            }
        }

        .modal-content {
            min-width: var(--main-f-350-size) !important;
            max-width: var(--main-f-370-size) !important;
            border-radius: var(--main-f-10-size) !important;
        }

        button:disabled{
            background: #F3F3F3 !important;
            border-color: #F3F3F3 !important;
            color: #D0222F !important;
  
            .loading-red{
              margin-top: 4px !important;
            }
  
            .sendEmail{
                margin-top: 0px !important;
              color: #A2A2A2 !important;
            }
        }
    
        .content-modal-success {
            text-align: start !important;
            margin-top: var(--main-f-50-size);
            // margin-left: var(--main-f-20-size);
            margin-bottom: var(--main-f-120-size);
      
            .label-success{
                font-family: Raleway !important;
                font-style: normal !important;
                font-weight: bold !important;
                font-size: 24px !important;
                line-height: 28px !important;
            }
      
            .label-description {
                font-family: Raleway !important;
                font-style: normal !important;
                font-size: 16px !important;
                margin-right: var(--main-f-30-size) !important;
            }
      
            .button-back {
                width: 280px !important;
                height: 50px !important;
                background: #D0222F;
                border-radius: 10px !important;
                color: #ffffff !important;
                font-family: Raleway !important;
                font-weight: 700 !important;
                border: none !important;
                font-size: var(--main-f-17-size);
                margin-left: var(--main-f-20-size);
            }
        }

        .padding-modal {
            padding: var(--main-f-7-size) !important;
        }
    }

    .exit {
        width: var(--main-f-34-size) !important;
        height: var(--main-f-34-size) !important;
        padding: 0 !important;
        border-radius: var(--main-f-20-size) !important;
        background-color: #D0222F !important;
        border-color: #D0222F !important;
        color: #ffffff !important;
        font-size: var(--main-f-15-size) !important;
        bottom: var(--main-f-520-size) !important;
        left: var(--main-f-335-size) !important;
        position: absolute !important;
        z-index: 5 !important;
    }

    .exit-filter {
        width: var(--main-f-34-size) !important;
        height: var(--main-f-34-size) !important;
        padding: 0 !important;
        border-radius: var(--main-f-20-size) !important;
        background-color: #D0222F !important;
        border-color: #D0222F !important;
        color: #ffffff !important;
        font-size: var(--main-f-15-size) !important;
        bottom: var(--main-f-450-size) !important;
        left: 88% !important;
        position: absolute !important;
        z-index: 5 !important;
    }

       .form-group-seleto-filter {
        margin: 0 !important;
        height: var(--main-f-60-size) !important;     
        width: var(--main-f-340-size) !important;       
        // padding-right: 10px !important;      
        position: relative;
        display: flex;
        flex-direction: column;          
      

        .form-group-bedrooms{
            width: var(--main-f-75-size) !important;
        }

        label{
            color: #9d9d9d !important;
            font-size: var(--main-f-9-size) !important;
            margin-left: 0 !important;
            margin-bottom: 0 !important;
            margin-top: 0 !important;
            position: absolute;
            transform: translate(0, 26px) scale(1);
            padding: 0 12px !important;
            padding-left: 20px !important;
            top: -24px;
        }

        select {
            border: 1.00342px solid #8C8C8C;
            box-sizing: border-box;
            border-radius: 10.0342px;
            outline: 0 !important;
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
            height: var(--main-f-25-size) !important;
            font-size: var(--main-f-13-size) !important;
            margin-left: 0 !important;
            height: 50px !important;
            padding: 14px 16px 0 15px;
           
        }

        .input-value{
            border-radius: 0 !important;
            border-color: #cacaca !important;
            outline: 0 !important;
            box-shadow: none !important;
            width: var(--main-f-150-size) !important;
            height: var(--main-f-25-size) !important;
            margin-right: var(--main-f-5-size) !important;
            font-size: var(--main-f-13-size) !important;
            padding-left: var(--main-f-17-size) !important;
            margin-left: 0 !important;
        }
    }
}
