@media (min-width: 411px) {
    :root {
        --main-h1-size: calc(var(--calc-font-411) * var(--h1-size)) !important;
        --main-h2-size: calc(var(--calc-font-411) * var(--h2-size)) !important;
        --main-h3-size: calc(var(--calc-font-411) * var(--h3-size)) !important;
        --main-f-1-size: calc(var(--calc-font-411) * var(--f-1-size)) !important;
        --main-f-2-size: calc(var(--calc-font-411) * var(--f-2-size)) !important;
        --main-f-4-size: calc(var(--calc-font-411) * var(--f-4-size)) !important;
        --main-f-5-size: calc(var(--calc-font-411) * var(--f-5-size)) !important;
        --main-f-6-size: calc(var(--calc-font-411) * var(--f-6-size)) !important;
        --main-f-7-size: calc(var(--calc-font-411) * var(--f-7-size)) !important;
        --main-f-8-size: calc(var(--calc-font-411) * var(--f-8-size)) !important;
        --main-f-9-size: calc(var(--calc-font-411) * var(--f-9-size)) !important;
        --main-f-10-size: calc(var(--calc-font-411) * var(--f-10-size)) !important;
        --main-f-11-size: calc(var(--calc-font-411) * var(--f-11-size)) !important;
        --main-f-12-size: calc(var(--calc-font-411) * var(--f-12-size)) !important;
        --main-f-13-size: calc(var(--calc-font-411) * var(--f-13-size)) !important;
        --main-f-14-size: calc(var(--calc-font-411) * var(--f-14-size)) !important;
        --main-f-15-size: calc(var(--calc-font-411) * var(--f-15-size)) !important;
        --main-f-16-size: calc(var(--calc-font-411) * var(--f-16-size)) !important;
        --main-f-17-size: calc(var(--calc-font-411) * var(--f-17-size)) !important;
        --main-f-18-size: calc(var(--calc-font-411) * var(--f-18-size)) !important;
        --main-f-19-size: calc(var(--calc-font-411) * var(--f-19-size)) !important;
        --main-f-20-size: calc(var(--calc-font-411) * var(--f-20-size)) !important;
        --main-f-21-size: calc(var(--calc-font-411) * var(--f-21-size)) !important;
        --main-f-22-size: calc(var(--calc-font-411) * var(--f-22-size)) !important;
        --main-f-23-size: calc(var(--calc-font-411) * var(--f-23-size)) !important;
        --main-f-24-size: calc(var(--calc-font-411) * var(--f-24-size)) !important;
        --main-f-25-size: calc(var(--calc-font-411) * var(--f-25-size)) !important;
        --main-f-26-size: calc(var(--calc-font-411) * var(--f-26-size)) !important;
        --main-f-27-size: calc(var(--calc-font-411) * var(--f-27-size)) !important;
        --main-f-28-size: calc(var(--calc-font-411) * var(--f-28-size)) !important;
        --main-f-29-size: calc(var(--calc-font-411) * var(--f-29-size)) !important;
        --main-f-30-size: calc(var(--calc-font-411) * var(--f-30-size)) !important;
        --main-f-34-size: calc(var(--calc-font-411) * var(--f-34-size)) !important;
        --main-f-35-size: calc(var(--calc-font-411) * var(--f-35-size)) !important;
        --main-f-37-size: calc(var(--calc-font-411) * var(--f-37-size)) !important;
        --main-f-38-size: calc(var(--calc-font-411) * var(--f-38-size)) !important;
        --main-f-40-size: calc(var(--calc-font-411) * var(--f-40-size)) !important;
        --main-f-43-size: calc(var(--calc-font-411) * var(--f-43-size)) !important;
        --main-f-45-size: calc(var(--calc-font-411) * var(--f-45-size)) !important;
        --main-f-46-size: calc(var(--calc-font-411) * var(--f-46-size)) !important;
        --main-f-48-size: calc(var(--calc-font-411) * var(--f-48-size)) !important;
        --main-f-50-size: calc(var(--calc-font-411) * var(--f-50-size)) !important;
        --main-f-53-size: calc(var(--calc-font-411) * var(--f-53-size)) !important;
        --main-f-55-size: calc(var(--calc-font-411) * var(--f-55-size)) !important;
        --main-f-57-size: calc(var(--calc-font-411) * var(--f-57-size)) !important;
        --main-f-60-size: calc(var(--calc-font-411) * var(--f-60-size)) !important;
        --main-f-62-size: calc(var(--calc-font-411) * var(--f-62-size)) !important;
        --main-f-65-size: calc(var(--calc-font-411) * var(--f-65-size)) !important;
        --main-f-67-size: calc(var(--calc-font-411) * var(--f-67-size)) !important;
        --main-f-70-size: calc(var(--calc-font-411) * var(--f-70-size)) !important;
        --main-f-72-size: calc(var(--calc-font-411) * var(--f-72-size)) !important;
        --main-f-75-size: calc(var(--calc-font-411) * var(--f-75-size)) !important;
        --main-f-80-size: calc(var(--calc-font-411) * var(--f-80-size)) !important;
        --main-f-85-size: calc(var(--calc-font-411) * var(--f-85-size)) !important;
        --main-f-90-size: calc(var(--calc-font-411) * var(--f-90-size)) !important;
        --main-f-95-size: calc(var(--calc-font-411) * var(--f-95-size)) !important;
        --main-f-100-size: calc(var(--calc-font-411) * var(--f-100-size)) !important;
        --main-f-106-size: calc(var(--calc-font-411) * var(--f-106-size)) !important;
        --main-f-110-size: calc(var(--calc-font-411) * var(--f-110-size)) !important;
        --main-f-111-size: calc(var(--calc-font-411) * var(--f-111-size)) !important;
        --main-f-113-size: calc(var(--calc-font-411) * var(--f-113-size)) !important;
        --main-f-115-size: calc(var(--calc-font-411) * var(--f-115-size)) !important;
        --main-f-120-size: calc(var(--calc-font-411) * var(--f-120-size)) !important;
        --main-f-125-size: calc(var(--calc-font-411) * var(--f-125-size)) !important;
        --main-f-128-size: calc(var(--calc-font-411) * var(--f-128-size)) !important;
        --main-f-130-size: calc(var(--calc-font-411) * var(--f-130-size)) !important;
        --main-f-138-size: calc(var(--calc-font-411) * var(--f-138-size)) !important;
        --main-f-140-size: calc(var(--calc-font-411) * var(--f-140-size)) !important;
        --main-f-145-size: calc(var(--calc-font-411) * var(--f-145-size)) !important;
        --main-f-150-size: calc(var(--calc-font-411) * var(--f-150-size)) !important;
        --main-f-155-size: calc(var(--calc-font-411) * var(--f-155-size)) !important;
        --main-f-156-size: calc(var(--calc-font-411) * var(--f-156-size)) !important;
        --main-f-157-size: calc(var(--calc-font-411) * var(--f-157-size)) !important;
        --main-f-158-size: calc(var(--calc-font-411) * var(--f-158-size)) !important;
        --main-f-160-size: calc(var(--calc-font-411) * var(--f-160-size)) !important;
        --main-f-161-size: calc(var(--calc-font-411) * var(--f-161-size)) !important;
        --main-f-162-size: calc(var(--calc-font-411) * var(--f-162-size)) !important;
        --main-f-165-size: calc(var(--calc-font-411) * var(--f-165-size)) !important;
        --main-f-170-size: calc(var(--calc-font-411) * var(--f-170-size)) !important;
        --main-f-180-size: calc(var(--calc-font-411) * var(--f-180-size)) !important;
        --main-f-182-size: calc(var(--calc-font-411) * var(--f-182-size)) !important;
        --main-f-184-size: calc(var(--calc-font-411) * var(--f-184-size)) !important;
        --main-f-185-size: calc(var(--calc-font-411) * var(--f-185-size)) !important;
        --main-f-186-size: calc(var(--calc-font-411) * var(--f-186-size)) !important;
        --main-f-190-size: calc(var(--calc-font-411) * var(--f-190-size)) !important;
        --main-f-195-size: calc(var(--calc-font-411) * var(--f-195-size)) !important;
        --main-f-196-size: calc(var(--calc-font-411) * var(--f-196-size)) !important;
        --main-f-200-size: calc(var(--calc-font-411) * var(--f-200-size)) !important;
        --main-f-207-size: calc(var(--calc-font-411) * var(--f-207-size)) !important;
        --main-f-215-size: calc(var(--calc-font-411) * var(--f-215-size)) !important;
        --main-f-220-size: calc(var(--calc-font-411) * var(--f-220-size)) !important;
        --main-f-230-size: calc(var(--calc-font-411) * var(--f-230-size)) !important;
        --main-f-240-size: calc(var(--calc-font-411) * var(--f-240-size)) !important;
        --main-f-255-size: calc(var(--calc-font-411) * var(--f-255-size)) !important;
        --main-f-260-size: calc(var(--calc-font-411) * var(--f-260-size)) !important;
        --main-f-265-size: calc(var(--calc-font-411) * var(--f-265-size)) !important;
        --main-f-271-size: calc(var(--calc-font-411) * var(--f-271-size)) !important;
        --main-f-277-size: calc(var(--calc-font-411) * var(--f-277-size)) !important;
        --main-f-279-size: calc(var(--calc-font-411) * var(--f-279-size)) !important;
        --main-f-280-size: calc(var(--calc-font-411) * var(--f-280-size)) !important;
        --main-f-285-size: calc(var(--calc-font-411) * var(--f-285-size)) !important;
        --main-f-288-size: calc(var(--calc-font-411) * var(--f-288-size)) !important;
        --main-f-298-size: calc(var(--calc-font-411) * var(--f-298-size)) !important;
        --main-f-310-size: calc(var(--calc-font-411) * var(--f-310-size)) !important;
        --main-f-320-size: calc(var(--calc-font-411) * var(--f-320-size)) !important;
        --main-f-325-size: calc(var(--calc-font-411) * var(--f-325-size)) !important;
        --main-f-335-size: calc(var(--calc-font-411) * var(--f-335-size)) !important;
        --main-f-340-size: calc(var(--calc-font-411) * var(--f-340-size)) !important;
        --main-f-358-size: calc(var(--calc-font-411) * var(--f-358-size)) !important;
        --main-f-370-size: calc(var(--calc-font-411) * var(--f-370-size)) !important;
        --main-f-380-size: calc(var(--calc-font-411) * var(--f-380-size)) !important;
        --main-f-385-size: calc(var(--calc-font-411) * var(--f-385-size)) !important;
        --main-f-390-size: calc(var(--calc-font-411) * var(--f-390-size)) !important;
        --main-f-392-size: calc(var(--calc-font-411) * var(--f-392-size)) !important;
        --main-f-400-size: calc(var(--calc-font-411) * var(--f-400-size)) !important;
        --main-f-426-size: calc(var(--calc-font-411) * var(--f-426-size)) !important;
        --main-f-430-size: calc(var(--calc-font-411) * var(--f-430-size)) !important;
        --main-f-445-size: calc(var(--calc-font-411) * var(--f-445-size)) !important;
        --main-f-450-size: calc(var(--calc-font-411) * var(--f-450-size)) !important;
        --main-f-462-size: calc(var(--calc-font-411) * var(--f-462-size)) !important;
        --main-f-500-size: calc(var(--calc-font-411) * var(--f-500-size)) !important;
        --main-f-520-size: calc(var(--calc-font-411) * var(--f-520-size)) !important;
        --main-f-600-size: calc(var(--calc-font-411) * var(--f-600-size)) !important;
        --main-f-605-size: calc(var(--calc-font-411) * var(--f-605-size)) !important;
        --main-f-606-size: calc(var(--calc-font-411) * var(--f-606-size)) !important;
        --main-f-620-size: calc(var(--calc-font-411) * var(--f-620-size)) !important;
        --main-f-630-size: calc(var(--calc-font-411) * var(--f-630-size)) !important;
        --main-f-640-size: calc(var(--calc-font-411) * var(--f-640-size)) !important;
        --main-f-645-size: calc(var(--calc-font-411) * var(--f-645-size)) !important;
        --main-f-655-size: calc(var(--calc-font-411) * var(--f-655-size)) !important;
        --main-f-861-size: calc(var(--calc-font-411) * var(--f-861-size)) !important;
    }
}
