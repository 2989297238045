.post-details {
  font-family: Raleway !important;
  max-width: 100% !important;
  margin-top: 20px;

  h4{
    font-size: var(--main-f-24-size);
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
  }
  .details{
    max-width: 1200px !important;
  }

  &.encontra {
    .homer-encontra {
      img {
        width: 100%;
      }
    }
  }

  &.location {
    .left-side {
      padding: 0 !important;

      .left-side-content {
        background: #FFF;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 20px 50px;
        color: #262626 !important;

        .section-title {
          font-size: var(--main-f-28-size) !important;
          font-weight: bold !important;
          margin-bottom: 0px !important;
          width: var(--main-f-320-size) !important;
        }

        .section-sutitle {
          font-size: var(--main-f-16-size) !important;
          width: var(--main-f-260-size) !important;
        }
      }
    }

    .explore-container {
      background: #FFFFFF;
      border: 1px solid #CACACA;
      box-sizing: border-box;
      border-radius: 10px;
      height: var(--main-f-445-size);
      margin-bottom: var(--main-f-10-size);
    }

    .right-side {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
      background: #FFF;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      .right-side-content {
        vertical-align: middle;
        background: #FFF;
        padding: 20px 50px;
        border-radius: 10px;

        img {
          margin: auto;
          width: 100%;
          height: 100%;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }

  .TagSeletoCard {
    width: 70px !important;
    left: var(--main-f-10-size) !important;
    top: var(--main-f-15-size) !important;
    position: absolute !important;
    z-index: 20 !important;
  }

  .TagSeleto {
    width: 70px !important;
    margin-left: var(--main-f-7-size) !important;
    margin-bottom: var(--main-f-7-size) !important;
    position: initial !important;
    z-index: 20 !important;
  }

  .img {
    width: var(--main-f-360-size) !important;
    height: var(--main-f-233-size) !important;
  }

  .margin-bottom-footer {
    align-self: center !important;
  }

  .style-ios-playstore {
    margin-top: 0px;
    height: 83px;
  }

  .similar {
    min-height: 36vw !important;
  }

  .floating-button {
    width: 100% !important;
    height: var(--main-f-75-size) !important;
    justify-content: center !important;
    position: fixed !important;
    bottom: 0 !important;
    vertical-align: middle !important;
    z-index: 10 !important;
    background-color: #ffffff !important;
    left: var(--main-f-16-size) !important;

    .float-button-center {
      text-align: center !important;
    }

    button {
      width: var(--main-f-298-size) !important;
      height: var(--main-f-53-size) !important;
      margin-top: var(--main-f-11-size) !important;
      background: #D0222F !important;
      border: 1px solid #D0222F !important;
      box-sizing: border-box !important;
      // box-shadow: 0px var(--main-f-5-size) var(--main-f-5-size) rgba(0, 0, 0, 0.25) !important;
      border-radius: var(--main-f-8-size) !important;
      font-family: Raleway-Bold !important;
      color: #ffffff !important;
      font-size: var(--main-f-17-size) !important;

      &.combined {
        width: var(--main-f-220-size) !important;
        margin-right: 20px !important;
      }
    }

    .whatsapp-floating-button-container {
      padding-left: 10px;

      button {
        // min-width: 102px;
        width: var(--main-f-102-size) !important;
        background: #ffffff !important;
        border: 1px solid #D0222F !important;
        padding: 9px 24px;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .dropdown-toggle::after{
        height: 10px; width: 10px;
        border: 1px solid #D0222F;
        border-width: 2px 2px 0 0;
        transform: rotate(135deg);
        margin-left: 1.255em !important;
        vertical-align: 3px !important;

      }

      .show{
        margin-right: 10px;
      }

      .show > .btn-secondary.dropdown-toggle::after {
        color: #D0222F !important;
        transform: rotate(-45deg);
        vertical-align: -2px !important;
      }

      .dropdown-menu {
        min-width: var(--main-f-195-size);
        display: inline-table;
        text-align: center !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
        left: 10px;

        a {
          font-weight: bold;
          width: 100% !important;
          border-bottom: 1px solid #e6e6e6;

          &:hover {
            background: white !important;
            color: black !important;
          }

          &:active {
            background: white !important;
            color: black !important;
          }
        }
      }
    }
  }

  .homer-seleto-mobile {
    width: 100%;
    position: absolute;
    z-index: 2;
    margin-top: 20px !important;
    margin-left: 10px;

    .homer-seleto-tag {
      vertical-align: inherit !important;
      margin-left: 5px !important;
    }
  }

  .homer-encontra-container {
    margin-top: var(--main-f-20-size) !important;
    margin-bottom: var(--main-f-20-size) !important;
  }

  .row-footer {
    justify-content: flex-end !important;
    width: var(--main-f-165-size) !important;
  }

  .react-simple-image-viewer__modal {
    z-index: 79;
    -webkit-transform: translate3d(0, 0, 0);

    .react-simple-image-viewer__close {
      z-index: 80;
      opacity: 1 !important;
      top: 40px;
      right: 30px;
    }

    .react-simple-image-viewer__previous {
      opacity: 1 !important;
    }

    .react-simple-image-viewer__next {
      opacity: 1 !important;
    }
  }

  .carousel-root {
    &.carousel-imgs {
      .room-img {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        height: -webkit-fill-available !important;
        margin-bottom: 0 !important;
        width: 111% !important;
        object-fit: contain !important;
        background-color: #f4f4f4f4 !important;

        &.img-contains {
          object-fit: contain !important;
        }

        &.img-cover {
          object-fit: cover !important;
        }
      }
      .carousel-container {
        -webkit-box-pack: center !important;
        background-color: white !important;
        display: flex !important;
        flex-direction: row !important;
        height: auto !important;
        justify-content: center !important;
        width: 100% !important;
        // margin: 0px var(--main-f-7-size) !important;

        div {
          cursor: pointer;
          height: var(--main-f-260-size) !important;
          margin-left: -22px !important;
          margin-right: var(--main-f-23-size) !important;
        }
      }
    }
  }

  .carousel-root {
    width: 100vw !important;
    .carousel-container {
      -webkit-box-pack: center !important;
      -webkit-box-direction: normal !important;
      -webkit-box-orient: horizontal !important;
      background-color: rgb(255, 255, 255);
      display: flex !important;
      flex-direction: row !important;
      height: var(--main-f-462-size) !important;
      justify-content: center !important;
      width: 100% !important;
      margin: 0px !important;

      .card {
        max-width: var(--main-f-310-size) !important;
        max-height: var(--main-f-462-size) !important;
        margin: 0px var(--main-f-7-size) !important;
        border-radius: var(--main-f-7-size) !important;
        cursor: pointer;

        .card-body {
          padding: var(--main-f-22-size) !important;
          height: var(--main-f-310-size) !important;
        }

        .card-img-top {
          right: 0 !important;
          position: absolute !important;
          bottom: var(--main-f-265-size) !important;
          border-radius: var(--main-f-7-size) var(--main-f-7-size) 0 0 !important;
          min-height: var(--main-f-195-size) !important;
          max-height: var(--main-f-195-size) !important;

          &.img-contains {
            object-fit: contain !important;
          }

          &.img-cover {
            object-fit: cover !important;
          }
        }

        .icons {
          width: auto !important;
          margin-right: var(--main-f-4-size) !important;
          margin-top: var(--main-f-4-size) !important;
        }

        .margin-right {
          margin-right: var(--main-f-10-size) !important;
        }
      }
    }
  }

  .arrow-next {
    background-color: transparent !important;
    cursor: pointer !important;
    position: absolute !important;
    right: var(--main-f-2-size) !important;
    top: var(--main-f-95-size) !important;
    transition: all 0.25s ease-in 0s !important;
    width: 100px !important;
    height: 100px !important;
    z-index: 1 !important;
    border: none !important;
    outline: none !important;

    img {
      width: 100px !important;
      height: 100px !important;
      border: none !important;
    }
  }

  .arrow-prev {
    background-color: transparent !important;
    cursor: pointer !important;
    position: absolute !important;
    left: var(--main-f-2-size) !important;
    top: var(--main-f-95-size) !important;
    transition: all 0.25s ease-in 0s !important;
    width: 100px !important;
    height: 100px !important;
    z-index: 1 !important;
    border: none !important;
    outline: none !important;

    img {
      width: 100px !important;
      height: 100px !important;
      border: none !important;
      outline: none !important;
    }
  }

  h1 {
    font-size: var(--main-f-14-size) !important;
    font-weight: 300 !important;
    background: rgba(38, 38, 38, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
    border-radius: var(--main-f-30-size) !important;
    width: var(--main-f-126-size) !important;
    height: var(--main-f-24-size) !important;
    padding-left: var(--main-f-12-size) !important;
    padding-top: var(--main-f-4-size) !important;
    font-family: Raleway !important;

    strong {
      font: revert;
      margin-left: var(--main-f-4-size) !important;
    }

    .card-img {
      width: var(--main-f-11-size) !important;
    }
  }

  .homer-select-span-mobile {
    min-width: var(--main-f-380-size) !important;
    margin-top: var(--main-f-5-size) !important;
    font-weight: 600 !important;
    font-size: smaller !important;
    margin-left: var(--main-f-20-size) !important;
    right: var(--main-f-20-size) !important;
  }

  .homer-select-span {
    margin-bottom: 0 !important;
  }

  h2 {
    font-size: var(--main-f-22-size) !important;
    margin-top: var(--main-f-12-size) !important;
    font-family: Raleway-Bold !important;
  }

  h3 {
    font-size: var(--main-f-17-size) !important;
    margin-top: 0 !important;
  }

  .p-class {
    margin-top: var(--main-f-2-size) !important;
    margin-bottom: var(--main-f-2-size) !important;
    font-size: 14px !important;
    overflow: hidden !important;
    white-space: pre-wrap !important;
  }

  .label-next {
    font-size: var(--main-f-14-size) !important;
    color: #262626 !important;
    background: #f6f6f6 !important;
    border-radius: var(--main-f-30-size) !important;
    width: var(--main-f-150-size) !important;
    height: var(--main-f-30-size) !important;
    margin-top: var(--main-f-12-size) !important;
    padding-top: var(--main-f-4-size) !important;
    padding-left: var(--main-f-20-size) !important;
  }

  .label-tag {
    font-size: var(--main-f-14-size) !important;
    color: #262626 !important;
    background: #f6f6f6 !important;
    border-radius: var(--main-f-30-size) !important;
    padding: 5px 11px;
    margin-left: var(--main-f-8-size) !important;
    font-size: var(--main-f-11-size) !important;
    margin-top: var(--main-f-8-size) !important;
  }

  .label-sol {
    font-size: var(--main-f-14-size) !important;
    color: #262626 !important;
    background: #f6f6f6 !important;
    border-radius: var(--main-f-30-size) !important;
    width: var(--main-f-150-size) !important;
    height: var(--main-f-30-size) !important;
    margin-top: var(--main-f-12-size) !important;
    padding-top: var(--main-f-4-size) !important;
    padding-left: var(--main-f-30-size) !important;
  }

  .mb-3-text-muted {
    margin-top: var(--main-f-10-size) !important;
    font-size: var(--main-f-13-size) !important;
    line-height: var(--main-f-14-size) !important;
    width: var(--main-f-55-size) !important;
    height: var(--main-f-14-size) !important;
    color: #898989 !important;
    border-bottom: 0px !important;
  }

  .vector {
    width: var(--main-f-14-size) !important;
    height: var(--main-f-17-size) !important;
  }

  .margin-right {
    margin-right: var(--main-f-10-size) !important;
  }

  .mb-3-text-muted::before {
    content: "";
    width: 350px !important;
    height: var(--main-f-1-size) !important;
    position: absolute !important;
    border: 1px solid #cacaca !important;
    margin-top: var(--main-f-15-size) !important;
  }

  label {
    font-size: var(--main-f-18-size) !important;
    color: rgba(0, 0, 0, 1) !important;
  }
  .btn-whatsapp{
    justify-content: center !important;
    align-items: center !important;
    padding: 9px 24px !important;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    outline: none;
    width: var(--main-f-230-size) !important;

    background: #FFFFFF !important;
    border: 1px solid #D0222F !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    left: 0 !important;
    color: #D0222F;
  }

  .btn-secondary-2 {
    // position: absolute !important;
    width: var(--main-f-100-size) !important;
    height: var(--main-f-40-size) !important;
    background: white !important;
    color: #D0222F !important;
    font-size: var(--main-f-14-size) !important;
    font-weight: bold !important;
    border-radius: var(--main-f-30-size) !important;
    align-items: center !important;
    border-color: #D0222F !important;
    bottom: var(--main-f-17-size) !important;
    left: var(--main-f-320-size) !important;
    z-index: 2;

    &.btn-share-post {
      background: white !important;
      color: #D0222F !important;
      border: 1px solid #D0222F !important;
      width: var(--main-f-100-size) !important;
      margin-right: var(--main-f-10-size) !important;
      border-radius: 10px !important;

      &:hover {
        background: #fdf4f5 !important;
      }

      &:active {
        background: #fdf4f5 !important;
      }
    }

    &.btn-see-photo {
      background: white !important;
      color: #D0222F !important;
      border: 1px solid #D0222F !important;
      width: var(--main-f-100-size) !important;
      margin-right: var(--main-f-10-size) !important;
      border-radius: 10px !important;

      &:hover {
        background: #fdf4f5 !important;
      }

      &:active {
        background: #fdf4f5 !important;
      }
    }

      &.btn-see-maps {
        background: white !important;
        color: #D0222F !important;
        border: 1px solid #D0222F !important;
        width: var(--main-f-100-size) !important;
        margin-right: var(--main-f-10-size) !important;
        border-radius: 10px !important;

        &:hover {
          background: #fdf4f5 !important;
        }

        &:active {
          background: #fdf4f5 !important;
        }
      }
  }
  .map-container{
    width: 100% !important;
    height: var(--main-f-240-size) !important;
    margin-top: 5px !important;

    .pointers {
      width: 30px;
      height: 30px;
      border-radius: 50% 50% 50% 0;
      background: #D0222F;
      position: fixed;
      transform: rotate(-45deg);
      left: 50%;
      top: 50%;
      margin: -20px 0 0 -20px;
    }
  }



  .seleto-container{
    background: #FFFFFF;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: var(--main-f-10-size);
    padding: 30px 30px 30px 30px !important;

    .col-left{
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .title-seleto{
      line-height: normal !important;
      font-weight: 700 !important;
      margin-top: var(--main-f-10-size) !important;
      width: var(--main-f-240-size) !important;
      font-size: var(--main-f-20-size) !important;
    }

    .description-seleto{
      font-size: var(--main-f-14-size) !important;
    }

    .img-seleto{
      margin-top: var(--main-f-20-size) !important;
    }
  }

  .search-container{
    background: #FFFFFF;
    border: 1px solid #CACACA;
    box-sizing: border-box;
    border-radius: 10px;
    height: var(--main-f-207-size);
    align-content: center;
    margin-top: var(--main-f-43-size);

    img{
      width: 31px;
      height: 31px;
      margin-bottom: var(--main-f-5-size) !important;
    }

    .title-search{
      font-weight: 700;
      margin-bottom: 0px !important;
    }

    h6{
      margin-bottom: 0px !important;
      font-size: var(--main-f-16-size) !important;
      width: var(--main-f-280-size) !important;
    }

    .btn-forme{
      font-weight: 600;
      font-size: var(--main-f-12-size) !important;
      align-items: center;
      text-align: center;
      background: white !important;
      color: #D0222F;
      border: 1px solid #D0222F !important;
      width: var(--main-f-150-size) !important;
      border-radius: 10px;
      margin-top: var(--main-f-7-size);
    }

  }

  .btn-secondary-explore-location {
    position: relative !important;
    width: var(--main-f-240-size) !important;
    height: var(--main-f-35-size) !important;
    background: #FFF !important;
    border-color: #D0222F !important;
    color: #D0222F !important;
    font-size: var(--main-f-12-size) !important;
    font-family: Raleway-Bold !important;
    border-radius: var(--main-f-8-size) !important;
    align-items: center !important;

    &:hover {
      background: #fdf4f5 !important;
    }

    &:active {
      background: #fdf4f5 !important;
    }
  }

  .property-details{
    width: 80px;
    height: 80px;
    text-align: center !important;
    background: #F6F6F6;
    border-radius: 10px;
    margin-bottom: var(--main-f-20-size);
    padding-top: 20px;
    margin-right: 15px;
  }

  .card-label-card {

    // width: 100%;
    // padding: 0px 20px 20px 20px !important;

    .financial{
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: var(--main-11-size) !important;
      line-height: 16px;
      text-align: right;
      text-decoration-line: underline;
      color: #D0222F !important;
    }

    .horizontal-line {
      margin-top: var(--main-f-12-size) !important;
      border-top: 1px dashed #CACACA;
      width: 100% !important;
      padding-right: 10px;
      // margin-left: 2px;

    }
    label{
      margin-top: 0px !important;
    }

    input {
      height: var(--main-f-30-size) !important;
    }

    button {
      // margin-top: var(--main-f-7-size) !important;

      img {
        margin-right: var(--main-f-8-size) !important;
      }
    }

    .description-col{
      padding-left: 15px !important;

      label{
        font-size: var(--main-f-16-size);
      }
    }

    .title{
      font-family: Raleway;
      font-style: normal;
      font-weight: normal;
      font-size: var(--main-f-11-size) !important;
      line-height: 16px;
    }

    .content-align{
      place-content: end;
    }

    .price{
      font-family: Raleway;
      font-style: normal;
      font-weight: bold;
      font-size: var(--main-f-28-size) !important;
      line-height: 33px;
    }
  }

  .small-share-modal-container {
    position: absolute !important;
    width: var(--main-f-280-size) !important;
    height: var(--main-f-100-size) !important;
    background: white !important;
    color: #000000 !important;
    font-size: var(--main-f-14-size) !important;
    border-radius: var(--main-f-10-size) !important;
    align-items: left !important;
    left: 40px;
    top: 45px;
    border: 1px solid #cacaca;
    padding: 12px 24px;
    z-index: 2;
    position: relative;

    .share-header {
      margin-bottom: 13px;
      position: relative;

      .share-header-item {
        display: inline-block;

        &.share-close-button {
          position: absolute !important;
          right: 0;
          cursor: pointer;

          .close-button-image {
            width: 13.97px;
          }
        }
      }
    }
    .share-body {
      position: absolute !important;
      bottom: 7px;

      .share-options {
        .share-option {
          display: inline-block;
          padding-right: var(--main-f-20-size) !important;
          cursor: pointer;
          text-align: center;

          .share-option-image {
            width: var(--main-f-28-size) !important;
          }

          .share-option-label {
            margin-top: 5px;
            font-size: var(--main-f-10-size) !important;
          }
        }
      }
    }
  }

  .floating-button-container {
    position: fixed;
    right: 0px;
    top: 0px;
    height: 100vh;
    z-index: 99;
    display: inherit;

    .floating-button-container-content {
      position: absolute;
      bottom: 24px;
      right: 40px;
      transition: margin 220ms ease 0s;
      margin-bottom: 0px;
      cursor: pointer;

      .whatsapp-floating-button-container {
        button {
          min-width: 102px;
          background: #25d366 !important;
          padding: 9px 24px;
          border-radius: 10px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        .dropdown-menu {
          width: 100%;
          display: block;
          text-align: left !important;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border: none;

          a {
            font-weight: bold;
            width: 100% !important;
            border-bottom: 1px solid #e6e6e6;

            &:hover {
              background: white !important;
              color: black !important;
            }

            &:active {
              background: white !important;
              color: black !important;
            }
          }
        }
      }
    }
  }

  .p-span {
    margin-top: var(--main-f-20-size) !important;
    font-size: var(--main-f-13-size) !important;
  }

  .label-home {
    font-size: var(--main-f-30-size) !important;
    color: white !important;
    margin-top: var(--main-f-20-size) !important;
    margin-bottom: var(--main-f-30-size) !important;
    font-family: Raleway !important;
  }

  .home-select {
    font-size: var(--main-f-25-size) !important;
    color: white !important;
    width: var(--main-f-298-size) !important;
    margin-bottom: var(--main-f-25-size) !important;
  }
  .home-select-p {
    font-size: var(--main-f-17-size) !important;
    color: rgb(202, 202, 202) !important;
  }

  .card-homeseleto {
    position: relative !important;
    background: rgba(38, 38, 38, 1) !important;
    border-radius: 0.7rem !important;
    color: #ffffff !important;
    font-style: normal !important;
    font-weight: var(--main-f-462-size) !important;
    line-height: var(--main-f-16-size) !important;
    max-height: 70% !important;
    overflow: hidden !important;
    height: auto !important;

    .cardBody-homeseleto {
      font-size: var(--main-f-19-size) !important;
      margin-bottom: var(--main-f-26-size) !important;
      padding-left: var(--main-f-30-size) !important;
      padding-right: var(--main-f-43-size) !important;

      p {
        font-size: var(--main-f-15-size) !important;
        width: auto !important;
      }

      .h-picked::after {
        content: " ";
        width: var(--main-f-70-size) !important;
        height: 1px !important;
        position: absolute !important;
        border: 1px solid #fb756d !important;
        left: var(--main-f-4-size) !important;
        margin-top: calc(-1 * var(--f-8-size)) !important;
        margin-left: var(--main-f-10-size) !important;
      }
    }
  }

  .label-similar {
    font-size: var(--main-f-19-size) !important;
    font-weight: bold !important;
    line-height: var(--main-f-35-size) !important;
    margin-top: var(--main-f-50-size) !important;
    color: #262626 !important;
    margin-bottom: 0px !important;
    justify-content: center !important;
  }

  .text-features {
    font-size: var(--main-f-13-size) !important;
    color: #262626 !important;
    margin-bottom: var(--main-f-50-size) !important;
    line-height: var(--main-f-19-size) !important;
    justify-content: center !important;
  }

  .btn-btn-secondary1 {
    color: #D0222F !important;
    font-size: 16px !important;
    width: 200px !important;
    height: 50px !important;
    background: #ffffff !important;
    font-size: 16px !important;
    border: 1px solid #D0222F !important;
    box-sizing: border-box !important;
    border-radius: 30px !important;
  }

  .imagecard {
    width: var(--main-f-125-size) !important;

    .h-assets-imgs {
      padding-left: 0 !important;
      padding-right: 0 !important;
      align-self: center !important;
      text-align: -webkit-center !important;
      vertical-align: middle !important;
      .h-imgs {
        width: auto !important;
        height: auto !important;
        margin-top: var(--main-f-20-size) !important;
        margin-left: var(--main-f-17-size) !important;
      }
    }
    .o-assets-imgs {
      padding-left: 0 !important;
      padding-right: 0 !important;
      left: var(--main-f-2-size) !important;
      .o-imgs {
        width: var(--main-f-24-size) !important;
        margin-block-start: var(--main-f-4-size) !important;
      }
    }
    .m-assets-imgs {
      padding-left: 0 !important;
      padding-right: 0 !important;
      right: var(--main-f-4-size) !important;
      .m-imgs {
        width: var(--main-f-34-size) !important;
        margin-block-start: var(--main-f-8-size) !important;
      }
    }
    .e-assets-imgs {
      right: var(--main-f-2-size) !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .e-imgs {
        width: var(--main-f-22-size) !important;
        margin-block-start: var(--main-f-8-size) !important;
      }
    }

    .r-assets-imgs {
      padding-left: 0 !important;
      padding-right: 0 !important;
      right: var(--main-f-8-size) !important;
      .r-imgs {
        width: var(--main-f-12-size) !important;
        height: var(--main-f-22-size) !important;
        margin-block-start: var(--main-f-8-size) !important;
      }
    }
  }

  .card-text-brokers {
    margin-top: var(--main-f-5-size) !important;
    font-size: var(--main-f-13-size) !important;
    line-height: 14px !important;
  }

  .a-href {
    width: var(--main-f-200-size) !important;
    margin-left: calc(-1 * var(--f-16-size)) !important;
    color: #262626 !important;
    padding-bottom: var(--main-f-5-size) !important;
    padding-top: var(--main-f-5-size) !important;
  }

  .border-tp {
    min-height: 40vh !important;
    // margin-top: var(--main-f-85-size) !important;
    // margin-bottom: var(--main-f-25-size) !important;
    // border-top: 1px solid #cacaca !important;
  }

  .margin-bt-auto {
    margin-bottom: var(--main-f-50-size) !important;
  }

  .margin-row-image {
    margin-bottom: var(--main-f-100-size) !important;
  }

  .text {
    font-weight: 600 !important;
    color: #535353 !important;
  }

  .card-text-download-app {
    margin-top: var(--main-f-12-size) !important;
    font-size: var(--main-f-15-size) !important;
    font-weight: 600 !important;
    color: #535353 !important;
  }

  .ios {
    margin-left: 0 !important;
    width: var(--main-f-100-size) !important;
  }

  .imgs-playstore {
    margin-right: var(--main-f-70-size) !important;
    left: 0 !important;
    margin-top: 0 !important;
    padding-left: 0 !important;
  }

  .row-tittle-footer {
    margin-bottom: var(--main-f-50-size) !important;
    // border-top: 1px solid #cacaca !important;
  }

  .imgs-ios {
    width: var(--main-f-110-size) !important;
  }

  .playstore {
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: var(--main-f-110-size) !important;
  }

  .solutions {
    width: 50% !important;
  }

  .creci-section {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 12px;
    line-height: 14px;

    a {
      color: #000000 !important;
      text-decoration: none !important;
    }
  }

  .homer-networks {
    height: var(--main-f-120-size) !important;
    .form-icons-homer {
      width: var(--main-f-300-size) !important;
      height: var(--main-f-40-size) !important;
      max-width: initial !important;

      .icons-linkedin {
        background: #ffffff !important;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
        border-radius: var(--main-f-6-size) !important;
        padding-bottom: var(--main-f-5-size) !important;
        max-width: var(--main-f-24-size) !important;
        margin-left: var(--main-f-3-size) !important;
        margin-right: var(--main-f-7-size) !important;

        .icons-linkedin-imgs {
          width: var(--main-f-24-size) !important;
          margin-left: calc(-1 * var(--f-12-size)) !important;
          margin-top: var(--main-f-7-size) !important;
        }
      }
      .icons-instagram {
        background: #ffffff !important;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
        border-radius: var(--main-f-6-size) !important;
        max-width: var(--main-f-24-size) !important;
        margin-right: var(--main-f-7-size) !important;

        .icons-instagram-imgs {
          width: var(--main-f-24-size) !important;
          margin-left: calc(-1 * var(--f-12-size)) !important;
          margin-top: var(--main-f-6-size) !important;
        }
      }
      .icons-facebook {
        background: #ffffff !important;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
        border-radius: var(--main-f-6-size) !important;
        max-width: var(--main-f-24-size) !important;
        margin-right: var(--main-f-7-size) !important;

        .icons-facebook-imgs {
          width: var(--main-f-24-size) !important;
          margin-left: calc(-1 * var(--f-12-size)) !important;
          margin-top: var(--main-f-5-size) !important;
        }
      }
      .icons-youtube {
        background: #ffffff !important;
        box-shadow: 0px 2px 4px rgb(0 0 0 / 15%) !important;
        border-radius: var(--main-f-6-size) !important;
        max-width: var(--main-f-24-size) !important;
        margin-right: var(--main-f-7-size) !important;

        .icons-youtube-imgs {
          width: var(--main-f-24-size) !important;
          margin-left: calc(-1 * var(--f-12-size)) !important;
          margin-top: var(--main-f-6-size) !important;
        }
      }
    }
  }
}

.header {
  .container {
    justify-content: center !important;
  }
}

.card-text-brokers-seleto {
  margin-top: var(--main-f-5-size) !important;
  font-size: var(--main-f-10-size) !important;
  line-height: 14px !important;
}

.card-text-download-app-seleto {
  margin-top: var(--main-f-12-size) !important;
  font-size: var(--main-f-13-size) !important;
  font-weight: 600 !important;
  color: #535353 !important;
}
