.modal:has(.modal-forms) {
    overflow: hidden !important;
    display: flex !important;
    justify-content: flex-end !important;
    flex-direction: column !important;
}

.modal-forms {
    width: 100% !important;
    margin: 0 !important;
    display: flex !important;
    justify-content: center !important;

    .modal-content {
        width: 100% !important;
        padding: var(--main-f-28-size) var(--main-f-22-size) var(--main-f-70-size) !important;
        border-radius: var(--main-f-16-size) var(--main-f-16-size) 0 0 !important;

        .row-close {
            margin-bottom: var(--main-f-15-size) !important;

            .col {
                display: flex !important;
                justify-content: flex-end !important;

                .button-close {
                    padding: 0 !important;
                    opacity: 0.3 !important;
                    background-color: transparent !important;
                    border-color: transparent !important;
                }
            }
        }

        h2 {
            font-size: var(--main-f-26-size) !important;
            font-weight: 600 !important;
            color: #262626 !important;
            margin-bottom: var(--main-f-18-size) !important;
        }

        label {
            font-size: var(--main-f-13-size) !important;
            margin: 0 !important;
            color: #535353 !important;
            position: absolute !important;
            top: var(--main-f-7-size) !important;
            left: var(--main-f-25-size) !important;
        }

        input {
            width: 100% !important;
            height: var(--main-f-53-size) !important;
            border: var(--main-f-1-size) solid #262626 !important;
            margin-bottom: var(--main-f-19-size) !important;
            padding: var(--main-f-22-size) var(--main-f-19-size) var(--main-f-6-size) var(--main-f-9-size) !important;
            border-radius: var(--main-f-11-size) !important;
        }

        button {
            font-size: var(--main-f-17-size) !important;
        }
    }
}